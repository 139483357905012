import { observer } from 'mobx-react-lite'
import { store } from 'store'
import classNames from 'classnames'
import {
  FolderListObjType,
  MoveTopFolderDataType,
  NarrativeListDataType,
  NarrativeParamsType,
} from 'store/monitorVersion2/types'
import { ReactComponent as FolderIcon } from 'assets/images/icons/dashboard/folder.svg'
import { ReactComponent as FolderOpen } from 'assets/images/icons/dashboard/cheveron-down.svg'
import { ReactComponent as DraggedIcon } from 'assets/images/icons/dashboard/menu-alt-4.svg'
import { ReactComponent as DraggedOver } from 'assets/images/icon-open.svg'
import { useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffectOnce } from 'react-use'
import './SearchList.scss'

type PropsType = {
  showSidebar: boolean
  selectedSearchItem: NarrativeListDataType | undefined
  setSelectedSearchItem: (searchItem: NarrativeListDataType | undefined) => void
}

type SearchItemDictonaryObject = {
  data: { total_count: number; items: any[] }
  per_page: number
  page: number
}
const OTHER = 'OTHER'

const SearchList = observer((props: PropsType) => {
  const { selectedSearchItem, setSelectedSearchItem, showSidebar } = props
  const { vectorsStore, toasterStore } = store
  const { userFolderList, fetchNarratives, moveToFolder } = vectorsStore
  const { showToaster } = toasterStore
  const [currentOpenFolder, setCurrentOpenFolder] = useState<
    { name: string; id: string; collapsed: boolean } | undefined
  >()
  const [searchItemsDictionary, setSearchItemsDictionary] = useState<{
    [key: string]: SearchItemDictonaryObject
  }>({})

  useEffectOnce(() => {
    openFolder({
      name: 'Other searches',
      id: OTHER,
    })
  })

  const onChangeSearchItem = (searchItem: NarrativeListDataType) => setSelectedSearchItem(searchItem)

  const openFolder = (folder: { name: string; id: string }) => {
    if (currentOpenFolder?.name === folder.name) {
      setCurrentOpenFolder({ ...currentOpenFolder, collapsed: true })
      return
    }
    const params: NarrativeParamsType = {
      sort: 'name:asc',
      page: 1,
      per_page: 20,
    }
    if (folder.id !== OTHER) {
      params.folder_id = folder.id
    }
    if (searchItemsDictionary[folder.id]) {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
    } else {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
      fetchNarratives({ params: params }).then((data: any) => {
        setSearchItemsDictionary({ ...searchItemsDictionary, [folder.id]: { data: data, page: 1, per_page: 10 } })
        if (!selectedSearchItem) {
          setSelectedSearchItem(data?.items[0])
        }
      })
    }
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination) return
    const { destination, draggableId } = result

    console.log('Dragging from:', result)
    const requestBody: MoveTopFolderDataType = {
      action: 'add',
      entities: [{ entity_id: draggableId, entity_type: 'NARRATIVE' }],
    }
    moveToFolder({ id: destination.droppableId, data: requestBody }).then((res) => {
      if (res !== 'error') {
        showToaster({
          iconType: 'success',
          message: 'Successfully added items to your Folder',
        })
      }
    })
  }

  const customUserFolderList = useMemo(() => {
    const folders = {
      items: [...userFolderList.items, { name: 'Other searches', id: OTHER }],
      total_count: userFolderList.total_count,
    }
    return folders
  }, [userFolderList])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='narrativeDroppable' type='narrative'>
        {(provided: any) => (
          <div
            className={classNames('searchItemList', {
              hideSearchItemList: !showSidebar,
            })}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            <div className='fixed'>
              {customUserFolderList.items.map((userFolder: FolderListObjType | { name: string; id: string }) => (
                <Droppable key={userFolder.id} droppableId={String(userFolder.id)}>
                  {(provided: any, snapshot: any) => (
                    <div className='folder-container' ref={provided.innerRef} {...provided.droppableProps}>
                      <div
                        className={classNames('folder-item', {
                          'folder-item-droppable': snapshot.isDraggingOver,
                          'folder-item-open': currentOpenFolder?.name === userFolder.name,
                        })}
                        onClick={() => openFolder(userFolder)}>
                        {!snapshot.isDraggingOver ? (
                          currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed ? (
                            <FolderOpen />
                          ) : (
                            <FolderIcon />
                          )
                        ) : null}
                        {snapshot.isDraggingOver && <DraggedOver />}
                        <h6 className='title'>{userFolder.name}</h6>
                      </div>
                      {currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed && (
                        <div className='folder-items-list'>
                          {searchItemsDictionary[currentOpenFolder.id]?.data?.items?.map((searchItem, index) => (
                            <Draggable key={searchItem.id} draggableId={String(searchItem.id)} index={index}>
                              {(provided: any, snapshot: any) => (
                                <div
                                  className='item-container'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className='link' />
                                  <div
                                    onClick={() => onChangeSearchItem(searchItem)}
                                    className={classNames(`brand-item`, {
                                      'brand-item-selected':
                                        selectedSearchItem?.id === searchItem.id || snapshot.isDragging,
                                    })}>
                                    {snapshot.isDragging && <DraggedIcon />}
                                    <h6 className='title'>{searchItem.name}</h6>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              ))}
              {/* <Droppable droppableId='otherSearches' key='otherSearches'>
                {(provided: any) => (
                  <div className='folder-container' ref={provided.innerRef} {...provided.droppableProps}>
                    <div className='folder-item' onClick={() => openFolder({ id: 0, name: 'Other searches' })}>
                      {currentOpenFolder.name === 'Other searches' && !currentOpenFolder.collapsed ? (
                        <FolderOpen />
                      ) : (
                        <FolderIcon />
                      )}
                      <h6 className='title'>Other searches</h6>
                    </div>
                    {currentOpenFolder.name === 'Other searches' && !currentOpenFolder.collapsed && (
                      <div className='folder-items-list'>
                        {narrativesListData.items.map((searchItem, index) => (
                          <Draggable key={searchItem.id} draggableId={String(searchItem.id)} index={index}>
                            {(provided: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => onChangeSearchItem(searchItem)}
                                className={classNames(`brand-item`, {
                                  'brand-item-selected': selectedSearchItem?.id === searchItem.id,
                                })}>
                                <h6 className='title'>{searchItem.name}</h6>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </div>
                )}
              </Droppable> */}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})

export default SearchList
