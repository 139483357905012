import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as NarrativeIcon } from 'assets/images/icons/monitor/create_narrative_icon.svg'
import { ReactComponent as WatchlistIcon } from 'assets/images/icons/monitor/create_watchlist_icon.svg'
import { ReactComponent as BackIcon } from 'assets/images/icons/monitor/back_arrow_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import './CreateNarrativeAndWatchList.scss'
import { Link } from 'react-router-dom'
import { UtilService } from 'services/Util/Util'

const CancelBreadCrumb = ({ previousPath }: { previousPath: string }) => {
  return (
    <div className='cbc_container'>
      <Link className='cbc_c_link' to={previousPath} replace={true}>
        <CloseIcon className='cbc_c_l_close_icon' />
        Cancel
      </Link>
      <p className='cbc_c_create_text'>Create new</p>
    </div>
  )
}

const CreateNarrativeAndWatchList = observer(() => {
  const location = useLocation()
  const { breadcrumbStore, tenantsStore } = store
  const { setBreadcrumbData } = breadcrumbStore
  const { isCorporateCommunicationsTheme, changeLabelTextBasedOnTheme } = tenantsStore

  const navigate = useNavigate()

  const previousPath = location.pathname?.includes('vectors') ? '/vectors' : '/influencers'

  useEffect(() => {
    setBreadcrumbData([{ id: UtilService.generateUuid(), title: <CancelBreadCrumb previousPath={previousPath} /> }])
  }, [])

  return (
    <div className='cnaw_container'>
      <div className='cnaw_c_content_container'>
        <div className='cnaw_c_content'>
          {(!isCorporateCommunicationsTheme || location.pathname?.includes('vectors')) && (
            <div className='cnaw_c_c_block'>
              <NarrativeIcon className='cnaw_c_c_b_icon' />
              <p className='cnaw_c_c_b_heading'>
                {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)}
              </p>
              <p className='cnaw_c_c_b_desc'>
                Create a cross-platform collection of content that focuses on a specific topic of interest
              </p>
              <ButtonImage
                className='cnaw_c_c_b_button'
                onClick={() => {
                  const state = {
                    tabName: location.state?.tabName,
                    pageNumber: location.state?.pageNumber,
                    pageSize: location.state?.pageSize,
                  }
                  navigate(`narrative_builder`, { state: JSON.parse(JSON.stringify(state)) })
                }}>
                Create {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)}
              </ButtonImage>
            </div>
          )}
          {(!isCorporateCommunicationsTheme || location.pathname?.includes('influencers')) && (
            <div className='cnaw_c_c_block'>
              <WatchlistIcon className='cnaw_c_c_b_icon' />
              <p className='cnaw_c_c_b_heading'>
                {changeLabelTextBasedOnTheme('WatchList', isCorporateCommunicationsTheme)}
              </p>
              <p className='cnaw_c_c_b_desc'>
                Monitor channels and creators of interest on any platform to ensure nothing is missed{' '}
              </p>
              <ButtonImage
                className='cnaw_c_c_b_button'
                onClick={() => {
                  const state = {
                    tabName: location.state?.tabName,
                    pageNumber: location.state?.pageNumber,
                    pageSize: location.state?.pageSize,
                  }
                  navigate(`watchlist_builder`, { state: JSON.parse(JSON.stringify(state)) })
                }}>
                Create {changeLabelTextBasedOnTheme('WatchList', isCorporateCommunicationsTheme)}
              </ButtonImage>
            </div>
          )}
        </div>
      </div>
      <ButtonImage icon={BackIcon} className='cnaw_c_button' onClick={() => navigate(previousPath)}>
        Go back
      </ButtonImage>
    </div>
  )
})

export default CreateNarrativeAndWatchList
