import { Carousel } from 'antd'
import { observer } from 'mobx-react-lite'
import IssueCard from './IssueCard'
import './SuggestedIssues.scss'

const SuggestedIssues = () => {
  return (
    <>
      <span className='monitor-assets__graph__title'>Suggested issues</span>
      <div className='carousel-wrapper'>
        <Carousel dots>
          <div>
            <div className='issues-wrapper'>
              <IssueCard emptyState />
              <IssueCard emptyState />
              <IssueCard />
            </div>
          </div>
          <div>
            <div className='issues-wrapper'>
              <IssueCard />
              <IssueCard />
              <IssueCard />
            </div>
          </div>
        </Carousel>
      </div>
    </>
  )
}

export default observer(SuggestedIssues)
