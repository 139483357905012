import { useEffect, useState } from 'react'
import { Button, Form, Input, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import TagManager from 'react-gtm-module'
import { useLocation, useNavigate } from 'react-router-dom'
import { WatchlistLinksModal } from 'version2/components/Monitor/Watchlist/WatchlistLinksModal/WatchlistLinksModal'
import { SelectPlatformDropdown } from 'version2/components/common/SelectPlatformDropdown/SelectPlatformDropdown'
import { TokenizedInput } from 'version2/components/common/TokenizedInput/TokenizedInput'
import { ReactComponent as EnterIcon } from 'assets/images/icons/keyboard-return.svg'

import { WatchlistChannel, WatchlistProps } from 'models/models'

import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info-tooltip-icon.svg'
import SearchResultIcon from 'assets/images/search-result.svg'

import styles from './CreateWatchListForm.module.scss'

type Props = {
  formData: any
  addFormData: (newFormData: any) => void
  onEditMode?: boolean
  onSearchClick?: () => void
  onBackClick?: () => void
  watchlistID?: string
}

const CreateWatchListForm = observer((props: Props) => {
  const location = useLocation()
  const { onSearchClick, onBackClick, onEditMode, formData, addFormData, watchlistID } = props

  const isEditMode = !!watchlistID
  const [form] = Form.useForm()

  const [buttonText] = useState('Search')
  const [searchedText] = useState('')
  const [isSearchDisabled, setIsSearchDisabled] = useState(false)
  const [isLinksModalVisible, setIsLinksModalVisible] = useState(false)
  const [selectedPlatformIndex, setSelectedPlatformIndex] = useState<number>(-1)
  const [selectedPlatformForDetails, setSelectedPlatformForDetails] = useState<WatchlistChannel | null>(null)
  const [watchlistData, setWatchlistData] = useState<WatchlistProps>({
    name: '',
    description: '',
    channels: [
      {
        platform: '',
        channel_urls: [],
      },
    ],
  })

  const { defineWatchlistStore, userStore, loaderStore, tenantsStore } = store
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { isLoadingWatchlist } = loaderStore
  const { showResults, matchingResults, fetchDropdowns, setTenantId, republishWatchlist } = defineWatchlistStore
  const navigate = useNavigate()

  useEffect(() => {
    fetchDropdowns()
  }, [])

  useEffect(() => {
    if (userStore.tenantId) {
      setTenantId(userStore.tenantId)
    }
  }, [userStore.tenantId])

  useEffect(() => {
    if (isEditMode) {
      return
    }

    setIsSearchDisabled(
      watchlistData.name === '' ||
        watchlistData.channels?.some((channel) => channel?.platform === '' || channel?.channel_urls?.length === 0),
    )
  }, [watchlistData, isEditMode])

  useEffect(() => {
    if (onEditMode) {
      form.setFieldsValue({ name: formData['name'] || '', description: formData['description'] || '' })
      setWatchlistData(formData)
    }
  }, [onEditMode])

  const showSearch = () => {
    if (isEditMode) return false
    else return true
  }

  const onClickSearch = async () => {
    addFormData(watchlistData)
    setIsSearchDisabled(true)
    onSearchClick?.()
    const allChannelURLs = []
    for (let i = 0; i < watchlistData.channels.length; i++) {
      const channel = watchlistData.channels[i]
      allChannelURLs.push(...channel.channel_urls)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'search_watchlist',
        channels: allChannelURLs,
        entityDescription: watchlistData.description,
        entityName: watchlistData.name,
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
      },
    })
  }

  function numFormatter(num: number) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'
    } else if (num < 900) {
      return num
    }
  }

  const addNewPlatformHandler = () => {
    setWatchlistData((prevState) => {
      return {
        ...prevState,
        channels: [
          ...(prevState.channels || []),
          {
            platform: '',
            channel_urls: [],
          },
        ],
      }
    })
  }

  const publish = async () => {
    try {
      await form.validateFields()
      const response = await republishWatchlist(watchlistID!, watchlistData)

      if (response) {
        const previousPath = location.pathname?.includes('vectors') ? '/vectors' : '/influencers'
        navigate(previousPath)
      }
    } catch (error: any) {
      console.log({ error })
    }
  }

  const platformOptionSelectHandler = (selectedPlatform: string, platformIndex: number) => {
    const previousWatch = cloneDeep(watchlistData)
    previousWatch.channels[platformIndex] = {
      platform: selectedPlatform,
      channel_urls: [],
    }
    setWatchlistData(previousWatch)
  }

  const platformChannelsUpdateHandler = (channels: string[], platformIndex: number) => {
    const previousWatch = cloneDeep(watchlistData)
    previousWatch.channels[platformIndex].channel_urls = channels
    setWatchlistData(previousWatch)

    if (selectedPlatformForDetails) {
      setSelectedPlatformForDetails(previousWatch.channels[platformIndex])
    }
  }

  const openAddedChannelsDetails = (platform: WatchlistChannel, platformIndex: number) => {
    setSelectedPlatformIndex(platformIndex)
    setSelectedPlatformForDetails(platform)
    setIsLinksModalVisible(true)
  }

  const removePlatformHandler = (platformIndex: number) => {
    const previousWatch = cloneDeep(watchlistData)
    previousWatch.channels.splice(platformIndex, 1)
    setWatchlistData(previousWatch)
  }

  const closePlatformLinksModal = () => {
    setIsLinksModalVisible(false)
    setSelectedPlatformForDetails(null)
    setSelectedPlatformIndex(-1)
  }

  const removeChannelLinkHandler = (link: string) => {
    const updatedChannelsList = selectedPlatformForDetails?.channel_urls.filter((channel) => channel !== link)
    platformChannelsUpdateHandler(updatedChannelsList || [], selectedPlatformIndex)

    if (updatedChannelsList?.length === 0) {
      closePlatformLinksModal()
    }
  }

  const deletePlatformAllLinksHandler = () => {
    platformChannelsUpdateHandler([], selectedPlatformIndex)
    closePlatformLinksModal()
  }

  return (
    <div className={classNames(styles.createWatchlistWrapper, styles.createWatchlistWrapperContainer)}>
      <WatchlistLinksModal
        isModalVisible={isLinksModalVisible}
        closeModal={closePlatformLinksModal}
        channel={selectedPlatformForDetails}
        deleteAllLinksHandler={deletePlatformAllLinksHandler}
        onLinkRemoveHandler={removeChannelLinkHandler}
        isEditMode
      />

      <div className={styles.header}>
        <div className={styles.backBtn}>
          {onBackClick ? (
            <div
              className={styles.cww_back_btn_container}
              onClick={() => {
                setWatchlistData(formData)
                onBackClick?.()
              }}>
              <BackBtnIcon className={styles.cww_back_btn_container_icon} />
              <span className={styles.cww_back_btn_container_text}>Back</span>
            </div>
          ) : null}
          <h3 className={styles.headerTitle}>
            {isEditMode ? 'Edit' : 'Create'} {changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}
          </h3>
        </div>
      </div>

      <div className={classNames(styles.mainBody, { [styles.editMode]: isEditMode })}>
        <Form
          form={form}
          onValuesChange={(newFormData: any) => {
            addFormData(newFormData)
          }}>
          <div className={styles.floatingInputWithStaticLabel}>
            <label className={styles.inputLabel}>
              <span className={styles.labelText}>Name</span>
            </label>

            <Form.Item name='name'>
              <Input
                name='name'
                value={watchlistData.name}
                onChange={(e) =>
                  setWatchlistData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                placeholder='Kinetic Risk'
              />
            </Form.Item>
          </div>

          <div className={classNames(styles.floatingInputWithStaticLabel, styles.mt16)}>
            <label className={styles.inputLabel}>
              <span className={styles.labelText}>Description</span>
            </label>

            <Form.Item name='description'>
              <Input.TextArea
                name='description'
                autoSize={{ minRows: 1, maxRows: 6 }}
                value={watchlistData.description}
                onChange={(e) =>
                  setWatchlistData((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }))
                }
                placeholder='Kinetic Risk'
              />
            </Form.Item>
          </div>

          <div className={styles.addPlatformBtnWrapper}>
            <Button
              block
              className={styles.addPlatformBtn}
              disabled={watchlistData?.channels?.length >= 16} // update this number 16 here whenever a new platform is added into the system
              onClick={addNewPlatformHandler}>
              + Add Platform
            </Button>
          </div>

          {watchlistData?.channels
            ?.filter((channel) => typeof channel?.platform === 'string')
            ?.map((platform, platformIndex, allPLatforms) => {
              return (
                <div key={uuidv4()} className={styles.platformChannelsListWrapper}>
                  <div className={styles.selectPlatformsHeader}>
                    <span className={styles.heading}>
                      Choose Platform
                      <Tooltip
                        overlayClassName={styles.choosePlatformTooltipInner}
                        title={
                          <div className={styles.choosePlatformTooltip}>
                            <p>
                              Select any platform and add URL of channel.
                              <br />
                              <strong>YouTube:</strong>
                              <br />
                              <strong>URL:</strong> https://www.youtube.com/channel/UC0iYa0m8iDko3NT9d9kGzOQ or
                              https://www.youtube.com/@TheWhirlGirls
                              <br />
                              <strong>Twitter:</strong>
                              <br />
                              <strong>URL:</strong> https://twitter.com/adrianodobaldy
                              <br />
                              <strong>Instagram:</strong>
                              <br />
                              <strong>URL:</strong> https://instagram.com/jlo/
                              <br />
                              <strong>Tiktok:</strong>
                              <br />
                              <strong>URL:</strong> https://www.tiktok.com/@harrisonwebb97
                            </p>
                          </div>
                        }>
                        <InfoIcon className={styles.tooltipIcon} />
                      </Tooltip>
                    </span>
                  </div>

                  <div className={styles.addPlatformSection}>
                    <div className={styles.addPlatformInputWrapper}>
                      <SelectPlatformDropdown
                        channel={platform}
                        onPlatformSelect={(platform) => platformOptionSelectHandler(platform, platformIndex)}
                        selectedPlatforms={allPLatforms.map((channel) => channel.platform.toLowerCase())}
                      />

                      <div className={styles.lineSeparator}></div>

                      <TokenizedInput
                        placeholder='Use comma for multiple URLs'
                        initialTokens={platform.channel_urls}
                        onTokensChange={(channels) => platformChannelsUpdateHandler(channels, platformIndex)}
                      />
                    </div>

                    <div className={styles.suffixIcon}>
                      <EnterIcon className={styles.icon} />
                      <span className={styles.text}>Enter</span>
                    </div>
                  </div>

                  <div className={styles.platformActionButtons}>
                    {platform.channel_urls.length > 0 && (
                      <div
                        className={styles.totalLinksCount}
                        onClick={() => openAddedChannelsDetails(platform, platformIndex)}>
                        {platform.channel_urls.length} {platform.channel_urls.length === 1 ? 'Account' : 'Accounts'}{' '}
                        Added
                      </div>
                    )}

                    {allPLatforms.length > 1 && (
                      <div className={styles.removePlatform} onClick={() => removePlatformHandler(platformIndex)}>
                        Remove platform
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
        </Form>

        {showResults && (
          <div className={styles.resultsFoundContainer}>
            <div className={styles.resultDiv}>
              <strong>{numFormatter(matchingResults)}</strong>
              <p>results found for {searchedText}</p>
            </div>

            {matchingResults > 1000000 && (
              <div className={classNames(styles.resultDiv, styles.resultWarning)}>
                <img src={SearchResultIcon} alt='' />
                <p>Your search results in too many items to be useful. Consider refining your keywords.</p>
              </div>
            )}
          </div>
        )}

        {showSearch() && (
          <Button
            block
            className={styles.searchWatchlistButton}
            disabled={!watchlistData?.name?.trim()?.length || isSearchDisabled}
            onClick={() => {
              onClickSearch()
            }}>
            {buttonText}
          </Button>
        )}
      </div>
      {isEditMode && (
        <div className={styles.watchlistSidebarFooter}>
          <Button className={styles.cancelButton} onClick={onBackClick}>
            Cancel
          </Button>

          <Button
            className={styles.publishButton}
            onClick={publish}
            disabled={!isEditMode && !showResults}
            loading={isLoadingWatchlist}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
})

export default CreateWatchListForm
