import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Spin } from 'antd'
import Dropdown from 'version2/components/common/Inputs/Dropdown/Dropdown'
import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { RuleObjType } from '../CreateAssetModal'
import { AssetListParamsType, ResponseListType, ListsObjType } from 'store/asset/types'
import SearchTermSection from './SearchTermSection/SearchTermSection'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import './CreateAssetModalStep2.scss'

type CreateAssetModalStep2Props = {
  selectedRule: RuleObjType
  setSelectedRule: (rule: RuleObjType) => void
  selectedIds: string[]
  setSelectedIds: (arr: string[]) => void
  prevSelectedIds?: string[]
  setPrevSelectedIds?: (arr: string[]) => void
  booleanSearch: string
  setBooleanSearch: (val: string) => void
  setStep: (val: number) => void
  isEditMode?: boolean
  onClose: () => void
  setOpenContactModal: (state: boolean) => void
}

export const rules: RuleObjType[] = [
  { id: '0', label: 'Snippets belong to a narrative ', ruleType: 'NARRATIVES' },
  { id: '1', label: 'Snippets text match a rule', ruleType: 'SEARCH_TERM' },
]

const PER_PAGE = 50

const CreateAssetModalStep2 = observer((props: CreateAssetModalStep2Props) => {
  const { assetsStore } = store
  const {
    fetchNarrativesWithParams,
    narrativePage,
    setNarrativePage,
    narrativesList,
    assetsRelatedLoading,
    setIdsBasedList,
    idsBasedList,
    fetchIdsBaseDataForAll,
  } = assetsStore
  const {
    selectedRule,
    setSelectedRule,
    selectedIds,
    setSelectedIds,
    prevSelectedIds,
    setPrevSelectedIds,
    booleanSearch,
    setBooleanSearch,
    setStep,
    isEditMode,
    onClose,
    setOpenContactModal,
  } = props
  const [narrativeSearchValue, setNarrativeSearchValue] = useState<string>('')
  const [prevNarrativeSearchValue, setPrevNarrativeSearchValue] = useState<string>('')
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [showUI, setShowUI] = useState<boolean>(false)

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: PER_PAGE,
      sort: 'name:asc',
    }
    if (narrativePage !== 2) {
      setNarrativePage(2)
    }

    fetchNarrativesWithParams({ params: requestParams })
  }, [])

  useEffect(() => {
    if (isEditMode && prevSelectedIds?.length) {
      let endPoint: 'narrative' = 'narrative'
      fetchIdsBaseDataForAll({ endPoint, arr: prevSelectedIds })
    }
  }, [prevSelectedIds, selectedRule.ruleType, isEditMode])

  const searchHandler = (value: string, prevValue: string, callback: (val: string) => void) => {
    if (value || prevValue) {
      setSelectedIds([])
      callback(value)
      let requestParams: AssetListParamsType = {
        page: 1,
        per_page: PER_PAGE,
        sort: 'name:asc',
      }
      if (value) {
        requestParams['q'] = `name:like:${value}`
      }
      if (narrativePage !== 2) {
        setNarrativePage(2)
      }

      if (selectedRule.ruleType === 'NARRATIVES') {
        fetchNarrativesWithParams({ params: requestParams })
      }
    }
  }

  const ruleHandler = (option: RuleObjType) => {
    if (!prevNarrativeSearchValue) {
      setNarrativeSearchValue('')
    } else {
      setNarrativeSearchValue(prevNarrativeSearchValue)
    }

    setSelectedRule(option)
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight

    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (
        selectedRule.ruleType === 'NARRATIVES' &&
        narrativesList.total_count !== narrativesList.items.length &&
        !isFetching
      ) {
        setIsFetching(true)
        let requestParams: AssetListParamsType = {
          page: narrativePage,
          per_page: PER_PAGE,
          sort: 'name:asc',
        }
        if (narrativeSearchValue) {
          requestParams['q'] = `name:like:${narrativeSearchValue}`
        }
        fetchNarrativesWithParams({ params: requestParams, concatination: true }).then(
          (res: ResponseListType | 'error') => {
            setIsFetching(false)
            if (res !== 'error' && [...narrativesList.items, ...res.items].length !== res.total_count) {
              setNarrativePage(narrativePage + 1)
            }
          },
        )
      }
    }
  }

  const filterList = useMemo(() => {
    if (selectedRule.ruleType === 'NARRATIVES') {
      return narrativesList.items
    }

    return []
  }, [selectedRule.ruleType, narrativesList])

  const renderUI = useMemo(() => {
    if (selectedRule.ruleType) {
      if (selectedRule.ruleType === 'SEARCH_TERM') {
        return (
          <SearchTermSection
            setStep={setStep}
            booleanSearch={booleanSearch}
            setBooleanSearch={setBooleanSearch}
            onClose={onClose}
            setOpenContactModal={setOpenContactModal}
          />
        )
      }
      return (
        <div>
          <p className='cfms2c_cc_title'>Select a narrative</p>
          <SearchInput
            className='cfms2c_cc_input_container'
            placeholder='Search'
            value={selectedRule.ruleType === 'NARRATIVES' ? narrativeSearchValue : ''}
            onChange={(e) => {
              if (selectedRule.ruleType === 'NARRATIVES') {
                setNarrativeSearchValue(e.target.value)
              }
            }}
            onPressEnter={() => {
              if (selectedRule.ruleType === 'NARRATIVES') {
                searchHandler(narrativeSearchValue, prevNarrativeSearchValue, setPrevNarrativeSearchValue)
              }
            }}
            onClear={() => {
              if (selectedRule.ruleType === 'NARRATIVES') {
                setNarrativeSearchValue('')
                searchHandler('', prevNarrativeSearchValue, setPrevNarrativeSearchValue)
              }
            }}
          />
          <div className='cfms2c_cc_lists' onScroll={handleScroll}>
            {filterList.length
              ? filterList.map((obj) => (
                  <div className='cfms2c_cc_l_option' key={obj.id}>
                    <div
                      className={`cfms2c_cc_checkbox_container${
                        selectedIds.includes(obj.id) ? ' cfms2c_cc_checkbox_container_selected' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedIds(
                          [...selectedIds].includes(obj.id)
                            ? [...selectedIds].filter((res) => res !== obj.id)
                            : [...selectedIds, obj.id],
                        )
                        if (isEditMode) {
                          const prevIdsBasedList: ListsObjType[] = JSON.parse(JSON.stringify(idsBasedList))
                          const isIdBasedObjExist = prevIdsBasedList.some((idBasedObj) => idBasedObj.id === obj.id)
                          if (isIdBasedObjExist) {
                            const filterIdsBasedList = prevIdsBasedList.filter((filterId) => filterId.id !== obj.id)
                            setIdsBasedList(filterIdsBasedList)
                          } else {
                            setIdsBasedList([...prevIdsBasedList, obj])
                          }
                          if (prevSelectedIds?.length) {
                            const filterPrevSelectedIds = [...prevSelectedIds].includes(obj.id)
                              ? [...prevSelectedIds].filter((res) => res !== obj.id)
                              : [...prevSelectedIds, obj.id]
                            setPrevSelectedIds?.(filterPrevSelectedIds)
                          }
                        }
                        // setSelectedIds((prevState) =>
                        //   prevState.includes(obj.id) ? prevState.filter((res) => res !== obj.id) : [...prevState, obj.id],
                        // )
                      }}>
                      {selectedIds.includes(obj.id) && <CheckIcon />}
                    </div>
                    <p className='cfms2c_cc_lp_text'>{obj.name}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      )
    }
  }, [
    filterList,
    selectedIds,
    narrativeSearchValue,
    selectedRule.ruleType,
    booleanSearch,
    isEditMode,
    prevSelectedIds,
    idsBasedList,
  ])

  return (
    <div className='create_flag_modal_step2_container'>
      <Spin spinning={assetsRelatedLoading} className='cfms2c_loading'>
        <Dropdown
          data={rules}
          title='Select rule'
          placeholder='Select rule for a flag'
          value={selectedRule.label}
          onSelect={ruleHandler}
          disabled={isEditMode && !showUI}
        />
        <div className='cfms2c_content_container'>
          {isEditMode && showUI && renderUI}
          {isEditMode && !showUI && (
            <div className='cfms2c_cc_edit_content_container'>
              {selectedRule.ruleType === 'SEARCH_TERM' && booleanSearch ? (
                <p className='cfms2c_cc_ecc_item_text'>Boolean Search</p>
              ) : null}
              {selectedRule.ruleType !== 'SEARCH_TERM' && idsBasedList.length ? (
                <p className='cfms2c_cc_ecc_item_text'>Selected item</p>
              ) : null}

              <div className='cfms2c_cc_ecc_items_container'>
                {selectedRule.ruleType === 'SEARCH_TERM' && booleanSearch ? (
                  <div className='cfms2c_cc_ecc_ic_chunk'>
                    <p className='cfms2c_cc_ecc_ic_c_text'>{booleanSearch}</p>
                  </div>
                ) : (
                  idsBasedList.map((idRes) => (
                    <div key={idRes.id} className='cfms2c_cc_ecc_ic_chunk'>
                      <div
                        className={`cfms2c_cc_ecc_ic_c_checkbox_container${
                          selectedIds.includes(idRes.id) ? ' cfms2c_cc_ecc_ic_c_checkbox_container_selected' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedIds(
                            [...selectedIds].includes(idRes.id)
                              ? [...selectedIds].filter((res) => res !== idRes.id)
                              : [...selectedIds, idRes.id],
                          )
                          const prevIdsBasedList: ListsObjType[] = JSON.parse(JSON.stringify(idsBasedList))
                          const isIdBasedObjExist = [...selectedIds].includes(idRes.id)
                          if (isIdBasedObjExist) {
                            const filterIdsBasedList = prevIdsBasedList.filter((filterId) => filterId.id !== idRes.id)
                            setIdsBasedList(filterIdsBasedList)
                          }
                        }}>
                        {selectedIds.includes(idRes.id) && <CheckIcon height={12} width={12} />}
                      </div>
                      <p className='cfms2c_cc_ecc_ic_c_text'>{idRes.name}</p>
                    </div>
                  ))
                )}
              </div>
              <ButtonImage className='cfms2c_cc_ecc_button' onClick={() => setShowUI(true)}>
                Change item
              </ButtonImage>
            </div>
          )}
          {!isEditMode && renderUI}
        </div>
      </Spin>
    </div>
  )
})

export default CreateAssetModalStep2
