import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import issueImage from 'assets/images/icons/dashboard/issue-bg.png'
import { ReactComponent as CardParagraphSkeleton } from 'assets/images/icons/dashboard/card-paragraph-skeleton.svg'
import { Skeleton } from 'antd'
import './SuggestedIssues.scss'

export default function IssueCard({ emptyState = false }: { emptyState?: boolean }) {
  if (emptyState) {
    return (
      <div className='issue-card'>
        <Skeleton.Image className='skeleton-image' />
        <h6 className='skeleton-title'>
          <Skeleton paragraph />
        </h6>
        <p className='description'>
          <CardParagraphSkeleton />
        </p>
        <ButtonImage onClick={() => {}} className='fhc_sc_button'>
          Follow Issue
        </ButtonImage>
      </div>
    )
  }

  return (
    <div className='issue-card'>
      <div className='img-wrapper'>
        <img src={issueImage} alt='Issue' className='img' />
        <div className='stats'>
          <div className='item'>
            <h6>Mentions</h6>
            <p>439.1K</p>
          </div>
          <div className='item'>
            <h6>Impressions</h6>
            <p>249.1K</p>
          </div>
          <div className='item'>
            <h6>Engagment</h6>
            <p>439.1K</p>
          </div>
        </div>
      </div>
      <h6 className='title'>E-Waste and Product Lifespan</h6>
      <p className='description'>
        Apple has been criticized for its products' relatively short lifespan, contributing to electronic waste. This
        includes the difficulty in repairing or upgrading devices. to electronic waste. This includes the difficulty in
        repairing or upgrading devices. to electronic waste. This includes the difficulty in repairing or upgrading
        devices.
      </p>

      <ButtonImage onClick={() => {}} className='fhc_sc_button'>
        Follow Issue
      </ButtonImage>
    </div>
  )
}
