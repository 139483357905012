import { uniqBy } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { ReportRoutesType } from 'store/report/valueTypes'
import {
  ConditionsPayloadParamsForAssetType,
  CopilotBodyType,
  CreateAssetBodyType,
  AssetCategoryType,
  AssetListParamsType,
  AssetsListResponseType,
  KeyValue,
  ResponseListType,
  AssetListObjType,
  AssetDataGraphType,
  ListsObjType,
  GetAndSearchUsersParamsTypes,
  GetAndSearchUsersAPIResponseTypes,
  GetFlagListForAlertsAPIResponseTypes,
  AlertsFlagListParamsType,
  GetAssetsListForAlertsAPIResponseTypes,
  AlertsAssetsListParamsType,
  GetAlertsDataAPIResponseTypes,
  BrandView,
} from './types'
import {
  AssetTabType,
  ConditionsPayloadType,
  NarrativeParamsType,
  PaginationValuesType,
} from 'store/monitorVersion2/types'

import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'version2/utils/helper'
import { alertAPI, flagsAPI, monitorVersion2API, reportsAPI } from 'api/api'

import { ROUTES } from 'settings/settings'

import { MainStore } from '../main/main.store'

export class AssetsStore extends MainStore {
  assetsCategories: AssetCategoryType[] = []
  assetsList: AssetsListResponseType = {
    total_count: 0,
    items: [],
  }
  competitorsList: AssetsListResponseType = {
    total_count: 0,
    items: [],
  }
  assetsLoading: boolean = false
  competitorsLoading: boolean = false
  getAlertsLoading: boolean = false
  assetsRelatedLoading: boolean = true
  getUserAndSearchLoading: boolean = false
  createAssetsAlertsLoading: boolean = false
  getFlagListForAlertsLoading: boolean = true
  getAssetsListForAlertsLoading: boolean = true

  corporateCommunicationBrandsView: BrandView = 'dashboard'

  booleanSearchLoading: boolean = false

  paginationValues: PaginationValuesType = {
    page: 1,
    pageSize: 10,
  }

  assetSelectedTab: AssetTabType = { category_id: '0', name: 'All Assets' }

  narrativePage: number = 2

  narrativesList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  usersLIstWithSearch: GetAndSearchUsersAPIResponseTypes = {
    total_count: 0,
    params: { page: 1, per_page: 10, sort: 'email:asc', tenant_id: '' },
    items: [],
  }

  flagsListForAlerts: GetFlagListForAlertsAPIResponseTypes = {
    total_count: 0,
    params: { page: 1, per_page: 10, sort: 'name:asc' },
    items: [],
  }

  assetsListForAlerts: GetAssetsListForAlertsAPIResponseTypes = {
    total_count: 0,
    params: { page: 1, per_page: 10, sort: 'name:asc', is_asset: true },
    items: [],
  }

  alertsData: GetAlertsDataAPIResponseTypes = {
    data: [],
  }

  idsBasedList: ListsObjType[] = []
  isFlagCreated: boolean = false
  assetCategoriesGraphDataLoading: boolean = false

  assetCategoriesGraphData: { [x: string]: AssetDataGraphType } = {}
  graphColorPalette: { [x: string]: string[] } = {
    Executives: ['#2E125E', '#5720B7', '#875BF7', '#C3B5FD', '#ECE9FE'],
    Suppliers: ['#053321', '#085D3A', '#17B26A', '#75E0A7', '#DCFAE6'],
    Brands: ['#7A6336', '#A38448', '#CCA55A', '#E0C99C', '#F0E4CE'],
    Competitors: ['#932F19', '#E62E05', '#FF692E', '#FFE6D5', '#FF9C66'],
    Sentiment: ['#7A6336', '#A38448', '#E0C99C'],
  }
  hasCategoriesConditionsFetched: boolean = false

  constructor() {
    super()

    makeObservable(this, {
      assetsCategories: observable,
      assetsList: observable,
      competitorsList: observable,
      assetsLoading: observable,
      assetsRelatedLoading: observable,
      booleanSearchLoading: observable,
      getUserAndSearchLoading: observable,
      paginationValues: observable,
      narrativePage: observable,
      narrativesList: observable,
      assetSelectedTab: observable,
      idsBasedList: observable,
      isFlagCreated: observable,
      usersLIstWithSearch: observable,
      getFlagListForAlertsLoading: observable,
      assetsListForAlerts: observable,
      flagsListForAlerts: observable,
      assetCategoriesGraphData: observable,
      assetCategoriesGraphDataLoading: observable,
      getAssetsListForAlertsLoading: observable,
      hasCategoriesConditionsFetched: observable,
      createAssetsAlertsLoading: observable,
      corporateCommunicationBrandsView: observable,
      setCreateAssetsAlertsLoading: action.bound,
      setAssetsCategorties: action.bound,
      setAssetsList: action.bound,
      setAssetsLoading: action.bound,
      setAssetsRelatedLoading: action.bound,
      setBooleanSearchLoading: action.bound,
      setPaginationValues: action.bound,
      setNarrativePage: action.bound,
      setGetUserAndSearchLoading: action.bound,
      setLists: action.bound,
      setIdsBasedList: action.bound,
      setListsWithParams: action.bound,
      setIsFlagCreated: action.bound,
      setUsersListingWithSearch: action.bound,
      setAssetCategoriesForGraph: action.bound,
      setAssetCategoriesForGraphLoading: action.bound,
      setAssetTypeGraphData: action.bound,
      setAssetCategoriesGraphData: action.bound,
      setHasCategoriesConditionsFetched: action.bound,
      setFlagsListForAlertsLoading: action.bound,
      setAssetsListForAlertsLoading: action.bound,
      setAssetsListForAlerts: action.bound,
      setFlagsListForAlerts: action.bound,
      setAssetSelectedTab: action.bound,
      updateCorporateCommunicationView: action.bound,
    })
  }

  setAssetSelectedTab = (tab: AssetTabType) => {
    this.assetSelectedTab = tab
  }

  setAssetsCategorties = (categories: AssetCategoryType[]) => {
    this.assetsCategories = categories
  }

  setAssetsList = (assetsRes: AssetsListResponseType) => {
    this.assetsList = assetsRes
  }

  setCompetitorssList = (assetsRes: AssetsListResponseType) => {
    this.competitorsList = assetsRes
  }

  setUsersListingWithSearch = (users: GetAndSearchUsersAPIResponseTypes) => {
    this.usersLIstWithSearch = users
  }

  setFlagsListForAlerts = (users: GetFlagListForAlertsAPIResponseTypes) => {
    this.flagsListForAlerts = users
  }

  setAssetsListForAlerts = (users: GetAssetsListForAlertsAPIResponseTypes) => {
    this.assetsListForAlerts = users
  }

  setAlertsData = (data: GetAlertsDataAPIResponseTypes) => {
    this.alertsData = data
  }

  setAssetsLoading = (value: boolean) => {
    this.assetsLoading = value
  }

  setCompetitorsLoading = (value: boolean) => {
    this.competitorsLoading = value
  }

  setGetUserAndSearchLoading = (value: boolean) => {
    this.getUserAndSearchLoading = value
  }

  setFlagsListForAlertsLoading = (value: boolean) => {
    this.getFlagListForAlertsLoading = value
  }

  setAssetsListForAlertsLoading = (value: boolean) => {
    this.getAssetsListForAlertsLoading = value
  }

  setCreateAssetsAlertsLoading = (value: boolean) => {
    this.createAssetsAlertsLoading = value
  }

  setGetAlertsLoading = (value: boolean) => {
    this.getAlertsLoading = value
  }

  setAssetsRelatedLoading = (value: boolean) => {
    this.assetsRelatedLoading = value
  }

  setBooleanSearchLoading = (value: boolean) => {
    this.booleanSearchLoading = value
  }

  setPaginationValues = ({ page, pageSize }: PaginationValuesType) => {
    this.paginationValues = { ...this.paginationValues, page, pageSize }
  }

  setNarrativePage = (val: number) => {
    this.narrativePage = val
  }

  setLists = (key: KeyValue, lists: ResponseListType) => {
    this[key] = lists
  }

  setIdsBasedList = (lists: ListsObjType[]) => {
    this.idsBasedList = lists
  }

  setListsWithParams = (key: KeyValue, lists: ResponseListType) => {
    this[key] = { total_count: lists.total_count, items: [...this[key].items, ...lists.items] }
  }

  setIsFlagCreated = (val: boolean) => {
    this.isFlagCreated = val
  }

  setAssetCategoriesForGraphLoading = (data: boolean) => {
    this.assetCategoriesGraphDataLoading = data
  }

  updateCorporateCommunicationView = (view: BrandView) => {
    this.corporateCommunicationBrandsView = view
  }

  setAssetCategoriesForGraph = (categories: { name: string; category_id: string }[]) => {
    const updatedObj: any = {}
    categories.forEach((category) => {
      updatedObj[category.name] = { name: category.name, category_id: category.category_id }
    })
    this.assetCategoriesGraphData = updatedObj
  }

  setAssetTypeGraphData = (category: string, data: any) => {
    if (this.assetCategoriesGraphData) {
      this.assetCategoriesGraphData[category] = {
        ...this.assetCategoriesGraphData[category],
        subCategories: data,
      }
    } else {
      this.assetCategoriesGraphData = {
        [category]: { name: category, category_id: '', total_value: 0, subCategories: data },
      }
    }
  }

  setAssetCategoriesGraphData = (category: string, data: any) => {
    if (this.assetCategoriesGraphData) {
      this.assetCategoriesGraphData[category] = {
        ...this.assetCategoriesGraphData[category],
        ...data,
      }
    } else {
      this.assetCategoriesGraphData = {
        [category]: { name: category, category_id: '', subCategories: [], ...data },
      }
    }
  }

  setHasCategoriesConditionsFetched = (state: boolean) => {
    this.hasCategoriesConditionsFetched = state
  }

  fetchCategories = async (params?: AssetListParamsType) => {
    try {
      const response = await flagsAPI.getCategories(params)
      this.setAssetsCategorties(response.data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchDataForObject = async (object: AssetListObjType, type: 'flag' | 'category'): Promise<AssetListObjType> => {
    // Assuming you have an API endpoint to fetch data based on object.id
    const requestParams = {
      q: `${type}_id:eq:${object.id}`,
      is_asset: true,
    }
    const conditionObj: ConditionsPayloadType | 'error' = await this.fetchConditionsPayload(requestParams)

    if (conditionObj !== 'error') {
      object.conditions = conditionObj
    } else {
      object.conditions = {}
    }
    object.mode = 'asset'
    return object
  }

  fetchAssetsList = async ({ params }: { params: AssetListParamsType }) => {
    this.setAssetsLoading(true)
    try {
      const response = await flagsAPI.getFlagsList({ params })
      const updatedItems = await Promise.all(
        response.data.items?.map(async (obj: AssetListObjType) => {
          return await this.fetchDataForObject(obj, 'flag')
        }),
      )
      const assetData = { total_count: response.data.total_count, items: updatedItems }
      this.setAssetsList(assetData)
      return assetData
    } catch (error: any) {
      displayApiError(error)
      return 'error'
    } finally {
      this.setAssetsLoading(false)
    }
  }

  fetchCompetitorsList = async ({ params }: { params: AssetListParamsType }) => {
    this.setCompetitorsLoading(true)
    try {
      const response = await flagsAPI.getFlagsList({ params })
      const updatedItems = await Promise.all(
        response.data.items.map(async (obj: AssetListObjType) => {
          return await this.fetchDataForObject(obj, 'flag')
        }),
      )
      const assetData = { total_count: response.data.total_count, items: updatedItems }
      this.setCompetitorssList(assetData)
      return assetData
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setCompetitorsLoading(false)
    }
  }

  fetchAndSearchUsers = async ({ params }: { params: GetAndSearchUsersParamsTypes }) => {
    this.setGetUserAndSearchLoading(true)
    try {
      const response = await alertAPI.getAndSearchUsers({ params })
      const mergedData = [...this.usersLIstWithSearch.items, ...response.data?.items]
      const uniqueData = uniqBy(mergedData, (item) => item.id)

      const firstPage = params?.page === 1 || !!params.q

      this.setUsersListingWithSearch({
        items: firstPage ? response.data?.items : uniqueData,
        params,
        total_count: response.data?.total_count || 0,
      })
    } catch (error: any) {
      displayApiError(error)
    } finally {
      this.setGetUserAndSearchLoading(false)
    }
  }

  fetchFlagsListForAlerts = async ({ params }: { params: AlertsFlagListParamsType }) => {
    this.setFlagsListForAlertsLoading(true)
    try {
      const response = await flagsAPI.getInsightsListForAlert({ params })
      const mergedData = [...this.flagsListForAlerts.items, ...response.data?.items]
      const uniqueData = uniqBy(mergedData, (item) => item.id)

      this.setFlagsListForAlerts({
        items: params?.page > 1 ? uniqueData : response.data?.items,
        params,
        total_count: response.data?.total_count || 0,
      })
    } catch (error: any) {
      displayApiError(error)
    } finally {
      this.setFlagsListForAlertsLoading(false)
    }
  }

  fetchAssetsListForAlerts = async ({ params }: { params: AlertsAssetsListParamsType }) => {
    this.setAssetsListForAlertsLoading(true)
    try {
      const response = await flagsAPI.getInsightsListForAlert({ params })
      const mergedData = [...this.assetsListForAlerts.items, ...response.data?.items]
      const uniqueData = uniqBy(mergedData, (item) => item.id)

      this.setAssetsListForAlerts({
        items: params?.page > 1 ? uniqueData : response.data?.items,
        params,
        total_count: response.data?.total_count || 0,
      })
    } catch (error: any) {
      displayApiError(error)
    } finally {
      this.setAssetsListForAlertsLoading(false)
    }
  }

  createAsset = async ({ data, method, id }: { data: CreateAssetBodyType; method?: 'post' | 'put'; id?: string }) => {
    this.setAssetsRelatedLoading(true)
    try {
      const response = await flagsAPI.postFlag({ data, method, id })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setAssetsRelatedLoading(false)
    }
  }

  deleteAsset = async ({ id }: { id: string }) => {
    try {
      const response = await flagsAPI.removeFlag({ id })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchNarrativesWithParams = async ({
    params,
    concatination,
  }: {
    params: NarrativeParamsType
    concatination?: boolean
  }) => {
    if (!concatination) {
      this.setAssetsRelatedLoading(true)
    }
    try {
      const narrativesData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.reportNarrative as ReportRoutesType,
        params,
      })
      if (concatination) {
        this.setListsWithParams('narrativesList', narrativesData.data)
      } else {
        this.setLists('narrativesList', narrativesData.data)
      }

      return narrativesData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      if (!concatination) {
        this.setAssetsRelatedLoading(false)
      }
    }
  }

  fetchBooleanSearchText = async ({ data }: { data: CopilotBodyType }) => {
    this.setBooleanSearchLoading(true)
    try {
      const response = await flagsAPI.getBooleanSearchText({ data })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setBooleanSearchLoading(false)
    }
  }

  fetchConditionsPayload = async (
    params?: ConditionsPayloadParamsForAssetType | undefined,
    dontStore: boolean = true,
  ) => {
    try {
      const response = await monitorVersion2API.getSearchPayload(params)
      if (!dontStore) {
        this.setConditionsPayload(response.data)
      }
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchCategoriesForGraph = async (params?: AssetListParamsType) => {
    try {
      this.setAssetCategoriesForGraphLoading(true)
      const response = await flagsAPI.getCategories(params)
      this.setAssetCategoriesForGraph(response.data)

      const promises = response.data?.map(async (obj: any) => {
        const requestParams = {
          q: `category_id:eq:${obj.category_id}`,
          is_asset: true,
        }
        const conditionObj: ConditionsPayloadType | 'error' = await this.fetchConditionsPayload(requestParams)

        if (conditionObj !== 'error') {
          this.setAssetCategoriesGraphData(obj.name, { conditions: conditionObj })
        } else {
          this.setAssetCategoriesGraphData(obj.name, { conditions: {} })
        }
      })
      await Promise.all(promises)
      this.setHasCategoriesConditionsFetched(true)
      this.setAssetCategoriesForGraphLoading(false)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      this.setAssetCategoriesForGraphLoading(false)
      return 'error'
    }
  }

  fetchIdsBasedData = async ({ endPoint, id }: { endPoint: 'narrative' | 'community'; id: string }) => {
    try {
      const response = await flagsAPI.getIdsBasedData({ endPoint, id })

      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchIdsBaseDataForAll = async ({ endPoint, arr }: { endPoint: 'narrative' | 'community'; arr: string[] }) => {
    try {
      // Use Promise.all to await all API calls concurrently
      const response = await Promise.all(
        arr.map(async (item) => {
          const data = await this.fetchIdsBasedData({ endPoint, id: item })
          if (data !== 'error') {
            return data
          }
        }),
      )

      this.setIdsBasedList(response.filter((obj) => Object.keys(obj).length !== 0))
      return response
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }
}
