import { makeAutoObservable } from 'mobx'
import { ROLES } from '../settings/roles'
import { IAttentionFlagInfoMenu, IRole, IUsers, IUserToReset, IUserToSave } from 'models/models'
import { API, tenantsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'version2/utils/helper'

export class UserStore {
  userName: string | null = null
  userEmail: string | null = null
  given_name: string | null = null
  family_name: string | null = null
  userTitle: string | null = null
  tenantId: string | null = null
  userId: string | null = null
  subID: string | null = null
  roleId: string | null = null
  currentRole:
    | 'USER'
    | 'ADMIN'
    | 'PENDULUM_ADMIN'
    | 'VIEWER'
    | 'SCORECARD_USER'
    | 'DEPLOYMENT_STRATEGIST'
    | 'BUILDER'
    | 'ANALYST' = 'USER'
  users: IUsers[] = []
  roles: IRole[] = []
  isDeleteModalVisible: boolean = false
  isAddUserModalVisible: boolean = false
  preDeleteUsername: string = ''
  authToken: string | null = null
  userInfo: {
    avatar_url: string
    created: string
    email: string
    family_name: string
    given_name: string
    last_updated: string
    role: string
    tenant_id: string
    user_id: string
  } = {
    avatar_url: '',
    created: '',
    email: '',
    family_name: '',
    given_name: '',
    last_updated: '',
    role: '',
    tenant_id: '',
    user_id: '',
  }
  tenantInfo: IAttentionFlagInfoMenu = {
    created: '',
    domain: '',
    id: '',
    industry: '',
    last_updated: '',
    logo_url: '',
    name: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  get fullName() {
    return this.given_name?.length && this.family_name?.length ? `${this.given_name} ${this.family_name}` : ''
  }

  getUsers = async () => {
    const { data, error } = await API.getUsers()
    if (error) {
      displayApiError(error)
    }
    this.setUsers(data.items)
  }

  getRoles = async () => {
    this.setRoles(ROLES)
  }

  checkAndSetCurrentRole = (
    role:
      | 'USER'
      | 'VIEWER'
      | 'ADMIN'
      | 'PENDULUM_ADMIN'
      | 'SCORECARD_USER'
      | 'DEPLOYMENT_STRATEGIST'
      | 'BUILDER'
      | 'ANALYST',
  ) => {
    this.setCurrentRole(role)
  }

  saveUser = async (user: IUserToSave) => {
    try {
      const { data } = await API.saveUser(user)

      if (data) {
        UtilService.openNotification({ type: 'info', message: 'Success', description: 'Changes saved successfully' })
        this.getUsers()
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  resetUser = async (user: IUserToReset) => {
    try {
      const { data } = await API.resetUser(user)
      if (data) {
        UtilService.openNotification({
          type: 'info',
          message: 'Success',
          description: 'Password reset sent successfully',
        })
        this.getUsers()
      }
    } catch (error: any) {
      displayApiError(error)
    }
  }

  deleteUser = async (userName: string) => {
    try {
      await API.deleteUser(userName)
      const index = this.users.findIndex((user) => user.username === userName)
      this.users.splice(index, 1)
      this.setDeleteModal(false)
      UtilService.openNotification({ type: 'info', message: 'Success', description: '' })
    } catch (error: any) {
      displayApiError(error)
    }
  }

  get usersTableData() {
    return this.users?.map((user: IUsers | any) => {
      return {
        ...user,
      }
    })
  }

  getUserInfo = async () => {
    try {
      const { data } = await API.get({ route: 'user', id: this.userEmail || '', getError: true })
      if (data) {
        this.setUserInfo(data)
      }
    } catch (error: any) {
      displayApiError(error)
    }
  }

  updateUserRole = async ({
    userId,
    username,
    newRoleId,
    oldRoleId,
  }: {
    userId: string
    username: string
    newRoleId: string
    oldRoleId: string
  }) => {
    try {
      const newRoleString = this.roles.find((item) => item.id === newRoleId)?.role

      if (!newRoleString) return
      await Promise.all([API.updateUserRole({ username, newRoleId })])

      // aparently this doesn update the select value because the value prop of the select should be updated
      //TODO: update the code so this works properly
      this.editUser({ username, newRoleId })
    } catch (error: any) {
      await API.updateUserRole({ username, newRoleId: oldRoleId }) // if update unsucessful revert the change on the other api
      displayApiError(error)
    }
  }

  setUser = ({
    userName,
    userEmail,
    given_name,
    family_name,
    tenantId,
    userId,
    roleId,
    subId,
    authToken,
  }: {
    userName: string | null
    userEmail: string | null
    given_name: string | null
    family_name: string | null
    tenantId: string | null
    userId: string | null
    roleId: string | null
    subId: string | null
    authToken?: string | null
  }) => {
    this.userName = userName
    this.userEmail = userEmail
    this.given_name = given_name
    this.family_name = family_name
    this.tenantId = tenantId
    this.userId = userId
    this.roleId = roleId
    this.subID = subId
    this.authToken = authToken || null
  }

  editUser = ({ username, newRoleId }: { username: string; newRoleId: string }) => {
    this.users.map((user) => {
      if (user.username === username) user.role = newRoleId
      return user
    })
  }

  setUserMeta = ({ userTitle }: { userTitle: string }) => (this.userTitle = userTitle)

  setUsers = (users: IUsers[]) => (this.users = users)

  addUser = (newUser: IUsers) => this.users.push(newUser)

  setRoles = (roles: IRole[]) => (this.roles = roles)

  setUserModal = (visible: boolean) => (this.isAddUserModalVisible = visible)

  setDeleteModal = (visible: boolean) => (this.isDeleteModalVisible = visible)

  setPredeleteUser = (username: string) => (this.preDeleteUsername = username)

  setCurrentRole = (
    role:
      | 'USER'
      | 'ADMIN'
      | 'VIEWER'
      | 'PENDULUM_ADMIN'
      | 'SCORECARD_USER'
      | 'DEPLOYMENT_STRATEGIST'
      | 'BUILDER'
      | 'ANALYST',
  ) => (this.currentRole = role)

  setUserInfo = (info: any) => (this.userInfo = info)

  setTenantInfo = (info: IAttentionFlagInfoMenu) => {
    this.tenantInfo = info
  }

  fetchTenantInfo = async () => {
    if (this.userInfo?.tenant_id) {
      const data = await tenantsAPI.getTenantDetails(this.userInfo.tenant_id)
      this.setTenantInfo(data)
      return data
    }
  }
}
