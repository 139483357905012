import { useEffect, useState } from 'react'
import { Modal, Skeleton, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import issueImage from 'assets/images/icons/dashboard/snippet-discovery-bg.png'

import { SnippetFilterVersion2 } from 'version2/components/MonitorVersion2/SnippetFilterVersion2/SnippetFilterVersion2'
import { DataTranslating } from 'components/common/DataFetching/DataTranslating'
import { SnippetListVersion2 } from 'version2/components/MonitorVersion2/SnippetListVersion2/SnippetListVersion2'
import { useEffectOnce } from 'react-use'
import Stats from './Stats'
import { AssetListObjType } from 'store/asset/types'
import { FlagListObjType } from 'store/flags/types'
import { powerInsightsAPI } from 'api/api'
import { SnippetFilterPayload } from 'models/models'
import { NarrativeListDataType } from 'store/monitorVersion2/types'
import { Mode } from 'types/types'
import './SnippetsDiscovery.scss'

type PropsType = {
  onClose: () => void
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  flagToView?: FlagListObjType | AssetListObjType | undefined
  subStore?: 'assets' | 'vectors'
}
const SnippetsDiscovery = (props: PropsType) => {
  const [summary, setSummary] = useState<string>('')
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)
  const [summaryFetchingError, setSummaryFetchingError] = useState(false)
  const { onClose, selectedItem, flagToView, subStore = 'assets' } = props

  const {
    getSnippetFilterPayload,
    activeItem,
    snippetsTotal,
    snippetsFilter,
    isTranslatingFeed,
    setIsTranslatingFeed,
    fetchSnippets,
    setSnippetsFilter,
  } = store[`${subStore}Store`]
  const { isLoadingFeed } = store.loaderStore

  const getExecutiveSummary = async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    if (!payload) return
    try {
      const shortSummary = true
      const { data } = await powerInsightsAPI.summary.getSummaryV2(payload, shortSummary)
      if (!data.response_code) {
        setSummary(data.sections[0].summary)
      } else if (data.response_code === 'NO_DATA') {
        setSummary('No summary to display')
      } else {
        setSummaryFetchingError(true)
      }
    } catch (error) {
      setSummaryFetchingError(true)
    } finally {
      setIsLoadingSummary(false)
    }
  }

  useEffect(() => {
    getExecutiveSummary()
  }, [snippetsFilter])

  useEffectOnce(() => {
    fetchSnippets()
  })

  useEffect(() => {
    if (!isLoadingFeed && isTranslatingFeed) setIsTranslatingFeed(false)
  }, [isLoadingFeed])

  const onCloseModal = () => {
    if (flagToView) {
      if (snippetsFilter?.flags?.name === flagToView.name) {
        const { flags, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      }
      if (snippetsFilter?.assets?.name === flagToView.name) {
        const { assets, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      }
    }
    onClose()
  }

  const retryFetchingSummary = () => {
    setIsLoadingSummary(true)
    setSummaryFetchingError(false)
    getExecutiveSummary()
  }

  return (
    <Modal
      open={true}
      className='snippets-discovery'
      footer={null}
      closable={false}
      style={{ top: 0, bottom: 0, margin: 0, padding: 0, right: 0 }}
      width='calc(100% - 70px)'>
      <div className='content'>
        <div className='header'>
          <h4>Overview</h4>
          <div className='collapse-option' onClick={onCloseModal}>
            <CloseIcon />
          </div>
        </div>
        <div className='body'>
          <div className='summary-section'>
            <div className='summary-image'>
              <img src={issueImage} alt='display' />
              <h4>{flagToView ? flagToView?.name : activeItem?.name}</h4>
            </div>
            <Stats selectedItem={selectedItem} subStore={subStore} />
            {!isLoadingSummary && !summaryFetchingError && <div className='summary'>{summary}</div>}
            {isLoadingSummary && <Skeleton paragraph active className='summary-empty-state' />}
            {!isLoadingSummary && summaryFetchingError && (
              <div className='summary-error'>
                <span className='summary-error-text'>Failed to load the summary. Please try again now or later.</span>
                <button className='summary-retry-error' onClick={retryFetchingSummary}>
                  Retry
                </button>
              </div>
            )}
          </div>
          <div className='snippets-section'>
            <div className='snippets-section-header'>
              <h4>Snippets feed</h4>
              <SnippetFilterVersion2
                mode={selectedItem?.mode as Mode}
                // to avoid calling fetch power insights inside snippet filter component
                activeTab='channel'
                page={subStore}
                pageType={'details'}
                subStore={subStore}
              />
              {isTranslatingFeed && isLoadingFeed && <DataTranslating />}
              {!isTranslatingFeed ? (
                <>
                  <Spin
                    className='cdvc_rc_sc_loader'
                    wrapperClassName='cdvc_rc_sc_spin_wrapper'
                    spinning={isLoadingFeed}
                    size='large'>
                    <SnippetListVersion2
                      mode={selectedItem?.mode as Mode}
                      showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                      searchedDataResults={snippetsTotal}
                      subStore={subStore}
                    />
                  </Spin>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default observer(SnippetsDiscovery)
