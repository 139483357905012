import { observer } from 'mobx-react-lite'
import { AssetListObjType } from 'store/asset/types'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { SnippetFilterPayload } from 'models/models'
import { ConditionsDataType, ConditionsPayloadType, NarrativeListDataType } from 'store/monitorVersion2/types'
import millify from 'millify'
import { Skeleton } from 'antd'
import './SnippetsDiscovery.scss'

type PropTypes = {
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  subStore: 'assets' | 'vectors'
}

const Stats = (props: PropTypes) => {
  const { selectedItem, subStore } = props
  const { fetchInsightsMetrics, getDate, snippetsFilter, getSnippetFilterPayload } = store[`${subStore}Store`]
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [statsCount, setStatsCount] = useState({
    impressions: 0,
    engagement: 0,
    mentions: 0,
  })

  const insightsMetricsHandler = async () => {
    setIsLoadingData(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const startDate = getDate.startDate
    const endDate = getDate.endDate

    //use community,platform,sentiment and flags filter,
    const requestParams: { q: string; measure?: string } = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    const requestParamsImpression = { ...requestParams, measure: 'impression' }
    if (payload?.conditions) {
      const requestData: ConditionsDataType = {
        conditions: payload.conditions as ConditionsPayloadType[],
      }

      const [mentions, impressions] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
        fetchInsightsMetrics({ params: requestParamsImpression, data: requestData, fullResponse: true }),
      ])

      setStatsCount({
        mentions: mentions === 'error' ? 0 : mentions.total_value,
        impressions: impressions === 'error' ? 0 : impressions.total_value,
        engagement: 0,
      })
    }
    setIsLoadingData(false)
  }

  useEffect(() => {
    if (selectedItem) {
      insightsMetricsHandler()
    }
  }, [snippetsFilter, selectedItem])

  if (isLoadingData) {
    return (
      <div className='stats'>
        <div className='item'>
          <h6>Mentions</h6>
          <p className='skeleton-stats-item'>
            <Skeleton paragraph active />
          </p>
        </div>
        <div className='item'>
          <h6>Impressions</h6>
          <p className='skeleton-stats-item'>
            <Skeleton paragraph active />
          </p>
        </div>
        <div className='item'>
          <h6>Engagment</h6>
          <p className='skeleton-stats-item'>
            <Skeleton paragraph active />
          </p>
        </div>
      </div>
    )
  }
  return (
    <div className='stats'>
      <div className='item'>
        <h6>Mentions</h6>
        <p>{millify(statsCount.mentions)}</p>
      </div>
      <div className='item'>
        <h6>Impressions</h6>
        <p>{millify(statsCount.impressions)}</p>
      </div>
      <div className='item'>
        <h6>Engagment</h6>
        <p>{millify(statsCount.engagement)}</p>
      </div>
    </div>
  )
}

export default observer(Stats)
