import { Col, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import MentionViewCard from 'version2/components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { AssetListObjType } from 'store/asset/types'
import { useNavigate } from 'react-router-dom'
// import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { DateFilter } from 'version2/components/common/DateFilter/DateFilter'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { FlagListObjType } from 'store/flags/types'
import DailyExecutiveSummary from 'version2/components/Asset/BrandsDashboard/components/DailyExecutiveSummary/DailyExecutiveSummary'
import SnippetsDiscovery from 'version2/components/Asset/BrandsDashboard/components/SnippetsDiscovery/SnippetsDiscovery'
import Filters from 'version2/components/Asset/BrandsDashboard/components/Filters/Filters'
import SuggestedIssues from 'version2/components/Asset/BrandsDashboard/components/SuggestedIssues/SuggestedIssues'
import FollowedIssues from 'version2/components/Asset/BrandsDashboard/components/FollowedIssues/FollowedIssues'
import InfluencerTable from 'version2/components/Asset/BrandsDashboard/components/InfluencerTable/InfluencerTable'
import PortfolioShareChart from 'version2/components/Asset/BrandsDashboard/components/PortfolioShareChart/PortfolioShareChart'
import BrandSentiment from 'version2/components/Asset/BrandsDashboard/components/BrandSentiment/BrandSentiment'
import { DownloadOption } from 'version2/components/Asset/BrandsDashboard/components/DownloadOption'
import PlatformDistributionCard from 'version2/components/Asset/BrandsDashboard/components/PlatformDistributionCard/PlatformDistributionCard'
import { MonitorGraph } from 'version2/components/Asset/BrandsDashboard/components/MonitorGraph/MonitorGraph'
import SearchList from 'version2/components/Vectors/Dashboard/SearchList/SearchList'
import { NarrativeListDataType } from 'store/monitorVersion2/types'
import BrandDiscussions from 'version2/components/Vectors/Dashboard/BrandDiscussions/BrandDiscussions'
import './VectorsDashboard.scss'

const customGraphColors = {
  shadeColorUp: '#eeeff4',
  lineColorUp: '#A84BD3',
  shadeColorDown: '#eeeff4',
  lineColorDown: '#A84BD3',
  theme: 'purple',
}

const VectorsDashboard = () => {
  const navigate = useNavigate()
  const [showSidebar, setShowSidebar] = useState(false)
  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [flagToView, setFlagToView] = useState<FlagListObjType | undefined>()
  const [assetToView, setAssetToView] = useState<AssetListObjType | undefined>()

  const { tenantsStore, loaderStore, vectorsStore, assetsStore } = store
  const { fetchFolderList, narrativeLoading, snippetsFilter, setSnippetsFilter, fetchPowerInsightsForDashboardPage } =
    vectorsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { fetchCategories } = assetsStore
  const { statsLineChartLoading } = loaderStore

  const [selectedSearchItem, setSelectedSearchItem] = useState<NarrativeListDataType>()

  useEffectOnce(() => {
    fetchFolderList({ params: {} })
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      navigate('/')
    }
  }, [isCorporateCommunicationsTheme, customThemeLoading])

  useEffect(() => {
    if (selectedSearchItem) {
      fetchPowerInsightsForDashboardPage({
        name: selectedSearchItem.name,
        conditions: selectedSearchItem.conditions,
        mode: 'narrative',
        id: selectedSearchItem.id,
      })
    }
  }, [selectedSearchItem, snippetsFilter])

  if (customThemeLoading) {
    return (
      <div className='spinner-container'>
        <Spin />
      </div>
    )
  }

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)
  const openSnippetsDiscovery = () => setIsOpenSnippetsDiscovery(true)
  const closeSnippetsDiscovery = () => {
    setIsOpenSnippetsDiscovery(false)
    setFlagToView(undefined)
    setAssetToView(undefined)
  }

  const onSelectFlag = (flag: FlagListObjType) => {
    setSnippetsFilter({ ...snippetsFilter, flags: { name: flag.name, conditions: flag?.conditions || {} } })
    setFlagToView(flag)
    openSnippetsDiscovery()
  }

  const onSelectBrandDiscussionItem = (asset: AssetListObjType) => {
    setSnippetsFilter({ ...snippetsFilter, assets: { name: asset.name, conditions: asset?.conditions || {} } })
    setAssetToView(asset)
    openSnippetsDiscovery()
  }

  return (
    <div className='brand-dashboard'>
      <SearchList
        selectedSearchItem={selectedSearchItem}
        setSelectedSearchItem={setSelectedSearchItem}
        showSidebar={showSidebar}
      />

      <div className='brand-dashboard-stats'>
        <Spin spinning={narrativeLoading && !selectedSearchItem}>
          <div className='filters-section'>
            <div onClick={() => setShowSidebar(!showSidebar)} className='brand-selected'>
              <h6 className='title'>{selectedSearchItem?.name}</h6>
            </div>
            <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
              <ExecutiveSummaryIcon />
              Executive summary
              <ExpandIcon />
            </div>
            <div className='date-filter'>
              <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'vectors'} addCustomDateOption={false} />
              <ButtonImage onClick={openSnippetsDiscovery} className='fhc_sc_button'>
                Show all snippets
              </ButtonImage>
            </div>
            {isOpenExecutiveSummaryModal && (
              <DailyExecutiveSummary
                onClose={closeExecutiveSummaryModal}
                showSubscriptionOption={false}
                subStore='vectors'
              />
            )}
            {isOpenSnippetsDiscovery && (
              <SnippetsDiscovery
                onClose={closeSnippetsDiscovery}
                selectedItem={selectedSearchItem}
                flagToView={flagToView || assetToView}
                subStore='vectors'
              />
            )}
          </div>
          {selectedSearchItem && (
            <div>
              <Filters />
              <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
                <Col span={showSidebar ? 17 : 18}>
                  <Row gutter={[0, 24]}>
                    <Col span={24}>
                      <Row className='dashboard-widgets__card__sub-row'>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Mentions'
                            key='Mentions'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Views'
                            key='Views'
                            measure='impression'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                          <MentionViewCard
                            selectedItem={selectedSearchItem}
                            type='Engagement'
                            key='Views'
                            measure='impression'
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                            subStore='vectors'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <SuggestedIssues />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <BrandDiscussions
                        selectedItem={selectedSearchItem}
                        onSelectItem={onSelectBrandDiscussionItem}
                        subStore='vectors'
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <FollowedIssues
                        selectedBrand={selectedSearchItem}
                        onSelectFlag={onSelectFlag}
                        subStore='vectors'
                      />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <InfluencerTable subStore='vectors' />
                    </Col>
                  </Row>
                </Col>
                <Col span={showSidebar ? 7 : 6}>
                  <Row gutter={[0, 24]}>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PortfolioShareChart additionalConditions={selectedSearchItem.conditions} />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <BrandSentiment subStore='vectors' />
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <div id='trendChart'>
                        <div className='chart-heading'>
                          <span className='monitor-assets__graph__title'>Current Trend</span>
                          <DownloadOption elementToCapture='#trendChart' />
                        </div>
                        <MonitorGraph
                          subStore='vectors'
                          dataLoading={statsLineChartLoading}
                          resizeChartTrigger={showSidebar}
                          selectedItem={selectedSearchItem}
                        />
                      </div>
                    </Col>
                    <Col span={24} className='dashboard-widgets__card'>
                      <PlatformDistributionCard isVerticalDisplay subStore='vectors' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Spin>
      </div>
    </div>
  )
}

export default observer(VectorsDashboard)
