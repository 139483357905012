/* eslint-disable prettier/prettier */
import millify from 'millify'
import { minBy } from 'remeda'
import { action, autorun, makeObservable, observable, set } from 'mobx'

import {
  IListItem,
  ISnippet,
  SurpriseData,
  SourceData,
  ILightTag,
  ICreator,
  ICommunityLight,
  ShareUser,
  SnippetsFilterInfo,
  ISingleExecutiveData,
  ISnippetParams,
  IPowerInsightsData,
  IVideoBubble,
  ExecutiveCategoryType,
  SnippetsFilter,
  monitorMode,
  IPowerInsightSortFilter,
  IFilterChipsV1,
  ILiteWatchlist,
  IBarChartWatchlistData,
  LanguageRisk,
  SnippetFilterPayload,
  ShareOwner,
} from 'models/models'
import {
  MONITOR_SNIPPET_MODE_LABEL,
  MONITOR_SNIPPET_SORT_LABEL,
  LANGUAGE_METRICS_DIMENSIONS,
  SurpriseSnippet,
  MONITOR_POWER_INSIGHT_SORT_LABEL,
  MONITOR_POWER_INSIGHT_MODE_LABEL,
  MONITOR_SNIPPET_SORT_VALUE_BY_LABEL,
  MONITOR_SNIPPET_MODE_VALYE_BY_LABEL,
  MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL,
  MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL,
} from 'settings/settings'
import {
  API,
  tagAPI,
  monitorAPI,
  powerInsightsAPI,
  executiveIntelligenceAPI,
  anticipatoryIntelligence,
  bookmarksAPI,
  exportAPI,
} from 'api/api'

import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'
import dayjs from 'dayjs'
import TagManager from 'react-gtm-module'

export class MonitorStore {
  userID: string | null = null

  storeType: monitorMode = 'tag'

  recentSearch: string = ''

  itemDetailsError: number | null = null
  listItems: IListItem[] = []
  listItemsCreatedByUser: IListItem[] = []
  listItemsSharedWithUser: IListItem[] = []
  listItemsNarrativeLibrary: IListItem[] = []
  paginationNarLib: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  growthData: { [key: string]: boolean | 'fetch' } = {}

  listItemsPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  listItemsByUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  listItemsSharedWithUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }

  activeItem: IListItem | null = null

  isPowerInsightsOpen: boolean = false
  isFeedEmpty: boolean = true
  isTranslatingFeed: boolean = false

  snippets: ISnippet[] = []
  totalSnippets: number = 0
  snippetsCurrentPage: number = 1
  userBookmarkedSnippets: string[] = []

  lightTags: ILightTag[] = []
  liteWatchlists: ILiteWatchlist[] = []

  surpriseModalData: SurpriseData | null = null
  sourceModalData: SourceData | null = null
  surpriseVideos: SurpriseData[] = []

  snippetsFilterInfo: SnippetsFilterInfo = {
    risk: 0,
    sentiment: 0,
    platform: 0,
    source: 0,
    sort: '',
    mode: '',
    language: false,
  }
  avableFilters = {}
  listFilter: { [key: string]: string } = {}
  availableSnippetsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_SNIPPET_SORT_LABEL),
    sort_mode: Object.values(MONITOR_SNIPPET_MODE_LABEL),
  })
  snippetsFilter: SnippetsFilter = {
    days: {
      custom: {
        isApplied: false,
        dateRange: [],
      },
      noOfDays: 90,
    },
    sort: 'Upload Date',
    sort_mode: 'Descending',
    filter_risk: [0, 100],
  }
  availablePowerInsightsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_POWER_INSIGHT_SORT_LABEL),
    sort_mode: Object.values(MONITOR_POWER_INSIGHT_MODE_LABEL),
  })
  powerInsightSortFilter: IPowerInsightSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
  powerInsightsFilterChips: IFilterChipsV1 = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    language: { label: 'Language', value: '' },
    assets: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
  }
  idNumberList: { [x: string]: number } = {}

  loadedCreatorIds: string[] = []
  loadedCreators: ICreator[] = []

  liteCommunities: ICommunityLight[] = []

  snippetsInfo: { narratives: number[]; communities: number[] } = { narratives: [], communities: [] }

  selectedCommunityInfo: { name: string; id: string; index: number } | undefined = undefined

  shareUsers: ShareUser[] = []
  shareOwner: ShareOwner = {}
  currenCategory: ExecutiveCategoryType = 'All'
  powerInsightsData: IPowerInsightsData = {
    summary: {
      executiveSummary: '',
      statistics: [
        {
          label: 'Snippets',
          value: '0',
        },
        {
          label: 'Unique Creators',
          value: '0',
        },
        {
          label: 'Companies',
          value: '0',
        },
        {
          label: 'People',
          value: '0',
        },
        {
          label: 'Organizations',
          value: '0',
        },
        {
          label: 'Views',
          value: '0',
        },
      ],
      platformsDistribution: [],
    },
    lineChartData: {
      posts: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
      impressions: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
    },
    heatMapData: { data_points: [] },
    beeSwarmNodesData: [],
    languageRisks: {},
    watchlistData: { completeData: [], data: [] },
  }
  topicRequestId: string = ''
  defaultPlatforms: string = ''
  constructor() {
    makeObservable(this, {
      recentSearch: observable,
      listItemsPagination: observable,
      listItemsByUserPagination: observable,
      listItemsSharedWithUser: observable,
      listItemsSharedWithUserPagination: observable,
      isFeedEmpty: observable,
      isTranslatingFeed: observable,
      activeItem: observable,
      itemDetailsError: observable,
      listItems: observable,
      listItemsCreatedByUser: observable,
      growthData: observable,
      isPowerInsightsOpen: observable,
      snippetsFilterInfo: observable,
      listFilter: observable,
      snippetsFilter: observable,
      defaultPlatforms: observable,
      powerInsightsFilterChips: observable,
      idNumberList: observable,
      snippets: observable,
      totalSnippets: observable,
      userBookmarkedSnippets: observable,
      surpriseModalData: observable,
      sourceModalData: observable,
      liteCommunities: observable,
      snippetsCurrentPage: observable,
      shareUsers: observable,
      shareOwner: observable,
      currenCategory: observable,
      powerInsightsData: observable,
      selectedCommunityInfo: observable,
      liteWatchlists: observable,
      setRecentSearch: action.bound,
      setListItems: action.bound,
      setSnippets: action.bound,
      setSnippetsTotal: action.bound,
      setUserBookmarkedSnippets: action.bound,
      setSurpriseModalData: action.bound,
      setSnippetsFilter: action.bound,
      setPowerInsightsSortFilter: action.bound,
      setPowerInsightsFilterChips: action.bound,
      setIdNumberList: action.bound,
      setCommunities: action.bound,
      updateSnippets: action.bound,
      updateListItem: action.bound,
      updateListItems: action.bound,
      updateListItemsSharedWithUser: action.bound,
      updateListItemsByUser: action.bound,
      setActiveItem: action.bound,
      setIsFeedEmpty: action.bound,
      setIsTranslatingFeed: action.bound,
      setPaginationItemsByUser: action.bound,
      setSetCategory: action.bound,
      setSnippetsFilterInfo: action.bound,
      setGrowthData: action.bound,
      setIsPowerInsightsOpen: action.bound,
      setListItemsSharedWithUser: action.bound,
      setListItemsSharedWithUserPagination: action.bound,
      setShareUsers: action.bound,
      setShareOwner: action.bound,
      setSelectedCommunityInfo: action.bound,
      filterPowerInsightBarChartData: action.bound,
      setPowerInsightsData: action.bound,
      setLiteWatchlists: action.bound,
      topicRequestId: observable,
      fetchTopicsRequestId: action.bound,
      snippetsInfo: observable,
      setSnippetsInfo: action.bound,
      setDefaultPlatforms: action.bound,
      listItemsNarrativeLibrary: observable,
      paginationNarLib: observable,
    })
    autoSave(this, 'snippetDateFilter')
  }

  get filterFollowed() {
    return `is_followed:eq:true`
  }

  get filterUser() {
    if (this.storeType !== 'tag') return `owner_id:eq:${this.userID}`
    return ''
  }

  get filterSuggestions() {
    return 'is_pendulum_narrative:eq:true'
  }

  get filterSharedWithUser() {
    return `owner_id:ne:${this.userID}`
  }

  get snippetsWithSurprises() {
    const snippetsWithSurprisesArr: ISnippet[] = []
    this.snippets.forEach((el, index) => {
      snippetsWithSurprisesArr.push(el)
      if (index % 49 === 0 && index > 0) snippetsWithSurprisesArr.push(SurpriseSnippet)
    })

    return snippetsWithSurprisesArr
  }

  get getModeConditions(): any {
    return []
  }

  setRecentSearch = (text: string) => {
    this.recentSearch = text
  }

  setLightTags = (lightTags: ILightTag[]) => {
    this.lightTags = lightTags
  }

  setSnippetsInfo = (narratives: number[], communities: number[]) => {
    this.snippetsInfo = { narratives, communities }
  }

  setDefaultPlatforms = (platforms: string) => {
    this.defaultPlatforms = platforms
  }

  setPaginationItems = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsPagination = pagination
  }

  setPaginationItemsByUser = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsByUserPagination = pagination
  }

  setListItemsSharedWithUserPagination = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsSharedWithUserPagination = pagination
  }

  setUserID = (id: string | null) => {
    this.userID = id
  }

  setCommunities = (communities: ICommunityLight[]) => {
    this.liteCommunities = communities
  }

  setCreators = (creators: any[]) => {
    this.loadedCreators = creators
  }

  setCreatorIds = (creatorIds: string[]) => {
    this.loadedCreatorIds = creatorIds
  }

  setSnippetsTotal = (number: number) => {
    this.totalSnippets = number
  }

  setSurpriseVideos = (surpriseVideos: SurpriseData[]) => {
    this.surpriseVideos = surpriseVideos
  }

  setItemDetailsError = (error: number | null) => {
    this.itemDetailsError = error
  }

  setListItems = (items: IListItem[]) => {
    this.listItems = items
  }

  setListItemsCreatedByUser = (items: IListItem[]) => {
    this.listItemsCreatedByUser = items
  }
  setListItemsNarrativeLibrary = (items: IListItem[]) => {
    this.listItemsNarrativeLibrary = items
  }
  updateListItemsNarrativeLibrary = (items: IListItem[]) => {
    this.listItemsNarrativeLibrary = [...this.listItemsNarrativeLibrary, ...items]
  }
  setPaginationNarLib = (pagination: { total: number; locallyLoaded: number }) => {
    this.paginationNarLib = pagination
  }

  updateListItemsByUser = (items: IListItem[]) => {
    this.listItemsCreatedByUser = [...this.listItemsCreatedByUser, ...items]
  }

  setGrowthData = (id: number, growthForecasted: 'fetch' | boolean) => (this.growthData[id] = growthForecasted)

  setActiveItem = (item: any, listname: string) => {
    if (item) {
      let trend = 'positive' as 'positive' | 'negative'
      if (this.storeType === 'tag') {
        let data = {
          id: item?.id,
          name: item?.label || item?.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: trend,
          is_followed: item?.is_followed,
          listName: listname,
          alertId: item?.alert_id,
        }
        this.activeItem = data
      } else if (this.storeType === 'watchlist') {
        const date = new Date(item?.last_update_time?.replace(/\s/, 'T'))
        const lastModifiedMonth = (date?.getMonth() < 9 ? '0' : '') + (date?.getMonth() + 1)
        const lastModifiedDate = (date?.getDate() < 10 ? '0' : '') + date?.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const lastUpdateDate = new Date(item.last_update_time.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()
        const lastUpdatedMonth = (lastUpdateDate.getMonth() < 9 ? '0' : '') + (lastUpdateDate.getMonth() + 1)
        const lastUpdatedDate = (lastUpdateDate.getDate() < 10 ? '0' : '') + lastUpdateDate.getDate()

        const data = {
          id: item?.id,
          name: item?.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: trend,
          ownerId: item?.owner_id,
          lastModifiedBy: item?.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date?.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          last_updated: `${lastUpdatedMonth}/${lastUpdatedDate}/${lastUpdatedDate}`,
          description: item?.description,
          tags: item?.tags,
          listName: listname,
          alertId: item?.alert_id,
          tenant_id: item?.tenant_id,
          channels: this.getChannels(item?.channels),
        }
        this.activeItem = data
      } else if (this.storeType === 'bookmark') {
        const date = new Date(item?.last_update_time?.replace(/\s/, 'T'))
        const lastModifiedMonth = (date?.getMonth() < 9 ? '0' : '') + (date?.getMonth() + 1)
        const lastModifiedDate = (date?.getDate() < 10 ? '0' : '') + date?.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        const data = {
          id: item?.id,
          name: item?.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: trend,
          ownerId: item?.owner_id,
          lastModifiedBy: item?.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date?.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item?.description,
          listName: listname,
          documentIds: item?.entity_info?.find((entity: any) => entity?.entity_type === 'SNIPPET')?.entities ?? [],
        }
        this.activeItem = data
      } else {
        const date = new Date(item?.last_update_time?.replace(/\s/, 'T'))
        const lastModifiedMonth = (date?.getMonth() < 9 ? '0' : '') + (date?.getMonth() + 1)
        const lastModifiedDate = (date?.getDate() < 10 ? '0' : '') + date?.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        const data: any = {
          id: item?.id,
          name: item?.name,
          idNumber: item?.narrative_number ?? item.community_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: trend,
          narrativeType: item?.narrative_type,
          ownerId: item?.owner_id,
          lastModifiedBy: item?.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date?.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item?.description,
          tags: item?.tags,
          listName: listname,
          alertId: item?.alert_id,
          permissionLevel: item?.permission_level,
        }

        if (this.storeType === 'narrative') data['translatedNarrativeLanguages'] = item.multi_lang_kwd_expression

        this.activeItem = data
      }
    } else this.activeItem = null
  }

  setSnippets = (items: ISnippet[]) => {
    this.snippets = items
  }

  setUserBookmarkedSnippets = (items: string[]) => {
    this.userBookmarkedSnippets = items
  }

  setSurpriseModalData = (data: SurpriseData | null) => {
    this.surpriseModalData = data
  }

  setListFilter = (filter: any, pageSize?: number) => {
    this.listFilter = filter
    this.setIsFeedEmpty(true)
    //HACK:this is just to refresh the list items so the stats are deleted on every filter change
    if (this.storeType === 'narrative' || this.storeType === 'bookmark') {
      this.setPaginationItemsByUser({ total: 0, locallyLoaded: 0 })
      this.setListItemsSharedWithUserPagination({ total: 0, locallyLoaded: 0 })
      this.setListItemsCreatedByUser([])
      this.setListItemsSharedWithUser([])
      this.fetchListItemsSharedWithUser({ pageSize })
      this.fetchListItemsByUser({ pageSize })
    } else {
      this.setPaginationItems({ total: 0, locallyLoaded: 0 })
      this.setListItems([])
      this.fetchListItems({ pageSize })
    }
    this.setActiveItem(null, '')
    this.setSnippets([])
    this.setSnippetsTotal(0)
    this.setSnippetsInfo([], [])
  }

  setSnippetsFilter = (filter: SnippetsFilter) => {
    this.setSnippetsTotal(0)
    this.setSnippets([])
    this.setSnippetsCurrentPage(1)
    this.snippetsFilter = filter
  }

  setPowerInsightsSortFilter = (filter: any) => {
    this.powerInsightSortFilter = filter
  }

  setPowerInsightsFilterChips = (filter: any) => {
    this.powerInsightsFilterChips = filter
  }

  setIdNumberList = (obj: { [x: string]: number }) => {
    this.idNumberList = { ...this.idNumberList, ...obj }
  }

  setSetCategory = (category: typeof this.currenCategory) => {
    this.currenCategory = category
  }

  setSnippetsFilterInfo = (info: SnippetsFilterInfo) => {
    this.snippetsFilterInfo = info
  }

  setSelectedCommunityInfo = (info: { name: string; id: string; index: number } | undefined) => {
    this.selectedCommunityInfo = info
  }

  setSnippetsCurrentPage = (page: number) => (this.snippetsCurrentPage = page)

  setIsFeedEmpty = (state: boolean) => {
    this.isFeedEmpty = state
  }

  setIsTranslatingFeed = (state: boolean) => {
    this.isTranslatingFeed = state
  }

  setListItemsSharedWithUser = (items: IListItem[]) => {
    this.listItemsSharedWithUser = items
  }

  setIsPowerInsightsOpen = (state: boolean) => {
    this.isPowerInsightsOpen = state
  }

  setShareUsers = (users: ShareUser[]) => {
    this.shareUsers = users
  }
  setShareOwner = (users: ShareOwner) => {
    this.shareOwner = users
  }

  setPowerInsightsData = (data: IPowerInsightsData) => {
    this.powerInsightsData = data
  }

  setLiteWatchlists = (data: ILiteWatchlist[]) => {
    this.liteWatchlists = data
  }

  updateListItems = (items: IListItem[]) => {
    this.listItems.push(...items)
  }

  updateListItemsSharedWithUser = (items: IListItem[]) => {
    this.listItemsSharedWithUser = [...this.listItemsSharedWithUser, ...items]
  }

  updateSnippets = (newSnippets: ISnippet[]) => {
    this.snippets.push(...newSnippets)
  }

  updateListItem = (
    id: string,
    listName: string,
    data: { impresions: number; trend: 'positive' | 'negative'; totalChangeImpressionCountLastDay: number },
  ) => {
    let index = 0
    if (this.storeType !== 'narrative') {
      this.listItems.forEach((item, i) => {
        if (item.id === id) index = i
      })

      if (!index && index !== 0) return

      this.listItems[index] = {
        ...this.listItems[index],
        impresions: data.impresions,
        impresionsMilified: millify(data.impresions || 0),
        trend: data.trend,
        totalChangeImpressionCountLastDay: millify(data.totalChangeImpressionCountLastDay || 0),
        listName: listName,
      }
    } else {
      if (listName === 'User' && this.listItemsCreatedByUser?.length > 0) {
        this.listItemsCreatedByUser?.forEach((item, i) => {
          if (item.id === id) index = i
        })

        if (!index && index !== 0) return

        this.listItemsCreatedByUser[index] = {
          ...this.listItemsCreatedByUser[index],
          impresions: data.impresions,
          impresionsMilified: millify(data.impresions || 0),
          trend: data.trend,
          totalChangeImpressionCountLastDay: millify(data.totalChangeImpressionCountLastDay || 0),
          listName: listName,
        }
      } else if (listName === 'Suggestions' && this.listItemsSharedWithUser?.length > 0) {
        this.listItemsSharedWithUser?.forEach((item, i) => {
          if (item.id === id) index = i
        })

        if (!index && index !== 0) return

        this.listItemsSharedWithUser[index] = {
          ...this.listItemsSharedWithUser[index],
          impresions: data.impresions,
          impresionsMilified: millify(data.impresions || 0),
          trend: data.trend,
          totalChangeImpressionCountLastDay: millify(data.totalChangeImpressionCountLastDay || 0),
          listName: listName,
        }
      }
    }
  }

  getChannels = (channels: any) => {
    let parsedChannels: any = []

    channels.forEach((channel: any, i: number) => {
      parsedChannels.push({
        platform: channel.platform,
        channel_urls: channel.channel_by_platform.map((plt: any) => plt.channel_url),
      })
    })
    return parsedChannels
  }

  getFormattedBeeSwarmVideoNodes = (items: IVideoBubble[]) => {
    const minViews = minBy(items, (post) => post.post_views)?.post_views ?? 1 // if none of the items have any views then assume that minimum value of `post_views` is 1
    const defaultBubbleValue = minViews / 2 // default value for video bubbles where `post_views` is NULL is half the value of minimum views of any post. This will improve their visibility in chart no matter how big the dataset is

    return items.map((video, index) => {
      const color = UtilService.getPlatformColor(video.platform)
      const date = new Date(video.post_upload_date)

      return {
        date,
        color,
        id: index.toString(),
        value: video.post_views ?? defaultBubbleValue,
        source: video.platform,
        data: {
          ...video,
          postViewsMillify: millify(video.post_views || 0),
          date: dayjs(date).format('MMM DD, YYYY'),
        },
        selected: true,
      }
    })
  }

  getCommunities = async (communitiesRequest: number[]) => {
    try {
      const checkedCommunities = communitiesRequest.filter((community, index) => {
        return communitiesRequest.indexOf(community) === index
      })

      const alreadyHave = this.liteCommunities
        .filter((community) => checkedCommunities.includes(community.id))
        .map((community) => community.id)

      const filteredCommunities = checkedCommunities.filter((community) => !alreadyHave.includes(community))

      if (filteredCommunities?.length === 0) return

      const { data } = await API.get({
        route: 'community',
        filter: `community_number:in:${JSON.stringify(filteredCommunities)}`,
      })

      const communitiesData = data?.items?.map((community: any) => {
        return { id: community.community_number, name: community?.name }
      })

      if (communitiesData) {
        this.setCommunities([...this.liteCommunities, ...communitiesData])
      }
    } catch (error) {}
  }

  getCreators = async (idArray: string[]) => {
    const ids = idArray.filter((id) => !this.loadedCreatorIds.includes(id))
    this.setCreatorIds([...this.loadedCreatorIds, ...ids])
    const idString = ids.join(',')

    let data

    if (idString?.length)
      ({ data } = await API.get({ route: 'channel', customAPI: process.env.REACT_APP_SEARCH_API, ids: idString }))

    if (data?.items) this.setCreators([...this.loadedCreators, ...data.items])

    this.setSnippets(
      this.snippets.map((snippet) => {
        if (snippet?.author?.channel_title) return snippet
        const creator = this.loadedCreators.filter((creator: any) => {
          return creator.channel_id === snippet.channelId
        })
        return { ...snippet, author: creator.length ? creator[0] : {} } as ISnippet
      }),
    )

    const snippetCommunities = this.snippets.map((snippet) => {
      return snippet?.author?.communities
    })

    const communitiesRequest: number[] = []

    snippetCommunities?.forEach((communityArray) => {
      communityArray?.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
    })

    this.getCommunities(communitiesRequest)
  }

  getTimeSpan = (
    { rangeInDays = 1, shiftByDays = 0 }: { rangeInDays?: number; shiftByDays?: number } = {
      rangeInDays: 1,
      shiftByDays: 0,
    },
  ) => {
    const currentDate = dayjs().subtract(shiftByDays, 'days').format('YYYY-MM-DD')
    const pastDate = dayjs()
      .subtract(rangeInDays + shiftByDays, 'days')
      .format('YYYY-MM-DD')

    return {
      startDate: pastDate,
      endDate: currentDate,
    }
  }

  deleteItem = async () => {
    if (this.storeType === 'watchlist' && this.activeItem && this.activeItem.id.length > 0) {
      try {
        await monitorAPI.deleteWatchlist(this.activeItem.id)
        // google analytics delete watchlist
        TagManager.dataLayer({
          dataLayer: {
            event: 'delete_item',
            entityId: this.activeItem.id,
            entityName: this.activeItem.name,
            entityType: 'watchlist',
            tenantId: '',
            entityOwner: this.activeItem.ownerId,
          },
        })
      } catch (error: any) {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })

        throw error
      }
    }
  }

  shareItem = async ({
    users,
    permission,
    action,
  }: {
    users: string[]
    permission: 'viewer' | 'editor'
    action: 'share' | 'unshare'
  }) => {
    try {
      if (this.activeItem?.id) {
        await API.genericShare(
          this.activeItem.id,
          users,
          this.storeType,
          action,
          action === 'unshare' ? 'viewer' : permission,
        )

        if (action === 'unshare') return this.setShareUsers(this.shareUsers.filter((user) => user.user_id !== users[0]))

        const changedRole = !!this.shareUsers.filter((user) => user.user_id === users[0]).length

        if (changedRole) {
          const usersWithUpdatedRole = this.shareUsers.map((user) => {
            if (user.user_id !== users[0]) return user
            return { ...user, permission_level: permission }
          })

          this.setShareUsers(usersWithUpdatedRole)
        }
        return true
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  createTag = async (tagLabel: string, id: string, type?: monitorMode | 'channel') => {
    try {
      const { data } = await tagAPI.createTag(tagLabel)
      this.lightTags.push({ label: data.label, id: data.id })

      await this.actionTag({ action: 'apply', tagId: data.id, id, type })
      return data.id
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  actionTag = async ({
    action,
    tagId,
    id,
    type,
    bulkAdd = false,
  }: {
    action: 'apply' | 'remove'
    tagId: string
    id: string
    type?: monitorMode | 'channel'
    bulkAdd?: boolean
  }) => {
    try {
      const tagInfo = await tagAPI.addTag({ tagId, action, type: type ? type : this.storeType, id })
      return tagInfo.data
    } catch (e: any) {
      if (!bulkAdd) {
        const response = e.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      } else console.log(e)
      return 'error'
    }
  }

  filterPowerInsightBarChartData = (indexName: 'peopleData' | 'companyData' | 'watchlistData', key: string) => {
    if (key.length > 0) {
      const filteredData =
        this.powerInsightsData[indexName]?.completeData?.filter((item) => item.entity_name?.includes(key)) || []
      const data = {
        [indexName]: {
          ...this.powerInsightsData[indexName],
          data: filteredData,
        },
      }
      this.setPowerInsightsData({ ...this.powerInsightsData, ...data })
    } else {
      const data = {
        [indexName]: {
          ...this.powerInsightsData[indexName],
          data: this.powerInsightsData[indexName]?.completeData.slice(0, 8),
        },
      }
      this.setPowerInsightsData({ ...this.powerInsightsData, ...data })
    }
  }

  showSurprise = async (state: boolean) => {
    if (state) {
      const rand = UtilService.getRandomInt({ max: this.surpriseVideos.length })
      this.setSurpriseModalData(this.surpriseVideos[rand])
      return
    }
    this.setSurpriseModalData({ title: '', url: '' })
    await UtilService.delay(100)
    this.setSurpriseModalData(null)
  }

  snippetFilterPayload = async (skip?: string) => {
    const payload: SnippetFilterPayload = {
      page: 1,
      per_page: 1,
      query: {
        date: '',
        platform: '',
        sentiment: '',
        source: '',
        risk: '',
        translationLanguage: '',
        people: '',
        politician: '',
        company: '',
        country: '',
        language: '',
        community: '',
        languageRisk: '',
      },
      sort: '',
      powerInsightSort: '',
      conditions: [],
    }

    const TIME_FORMAT = 'YYYY-MM-DD'
    const categoryLookup = {
      All: 'a',
      Negative: 'a',
      Obscene: 'obscene_language',
      'Identity Hate': 'hate_language',
      Toxic: 'toxic_language',
      Threat: 'threat_language',
      Source: 'a',
      /** A graph cannot be one of the following categories */
      NarrativeAll: '',
      NarrativeMainstream: '',
      NarrativeRisk: '',
      NarrativeNegative: '',
    }
    const days = this.snippetsFilter.days.noOfDays
    const dateRange = this.snippetsFilter.days.custom.isApplied ? this.snippetsFilter.days.custom.dateRange : []

    if (dateRange.length) {
      payload.query.date = `start_date:gte:${dateRange[0]},end_date:lte:${dateRange[1]}`
    } else {
      const endDate = dayjs().format(TIME_FORMAT)
      const yesterday = dayjs(endDate).subtract(1, 'days').format(TIME_FORMAT)
      const startDate = days ? dayjs(endDate).subtract(days, 'days').format(TIME_FORMAT) : yesterday
      payload.query.date = `start_date:gte:${startDate},end_date:lte:${endDate}`
    }

    let languageRisk: LanguageRisk = categoryLookup[this.currenCategory] as LanguageRisk

    if (languageRisk === 'a') {
      languageRisk = null
    }

    payload.query.languageRisk = languageRisk ? `,lang_risk:in:[${languageRisk}]` : ''

    payload.query.platform = this.snippetsFilter?.filter_platform
      ? `,platform:in:${JSON.stringify(this.snippetsFilter?.filter_platform)}`.replaceAll('"', '')
      : ''
    payload.query.sentiment = this.snippetsFilter?.filter_sentiment?.length
      ? this.snippetsFilter?.filter_sentiment
        ? `,sentiment:in:${JSON.stringify(this.snippetsFilter?.filter_sentiment)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.source = this.snippetsFilter?.snippet_source?.length
      ? this.snippetsFilter?.snippet_source
        ? `,snippet_source:in:${JSON.stringify(this.snippetsFilter?.snippet_source)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.risk =
      this.snippetsFilter?.filter_risk?.length === 2
        ? `,risk_score:gte:${this.snippetsFilter?.filter_risk[0]},risk_score:lte:${this.snippetsFilter?.filter_risk[1]}`
        : ''
    payload.query.people = this.snippetsFilter?.people ? `,people:in:[${this.snippetsFilter?.people}]` : ''
    payload.query.politician = this.snippetsFilter?.politician
      ? `,politician:in:[${this.snippetsFilter?.politician}]`
      : ''
    payload.query.company = this.snippetsFilter?.company ? `,company:in:[${this.snippetsFilter?.company}]` : ''
    payload.query.country = this.snippetsFilter?.country ? `,country:in:[${this.snippetsFilter?.country}]` : ''
    payload.query.language = this.snippetsFilter?.filter_language?.length
      ? `,lang:in:[${this.snippetsFilter.filter_language}]`
      : ''
    payload.query.community = this.snippetsFilter?.community
      ? `,community_number:eq:${this.snippetsFilter?.community}`
      : ''
    payload.query.translationLanguage = this.snippetsFilter?.lang?.length ? `&lang=${this.snippetsFilter?.lang}` : ''

    payload.sort = this.snippetsFilter
      ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[this.snippetsFilter.sort]}:${
          MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[this.snippetsFilter.sort_mode]
        }`
      : ''

    payload.powerInsightSort = this.powerInsightSortFilter
      ? `${MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL[this.powerInsightSortFilter.sort]}:${
          MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL[this.powerInsightSortFilter.sort_mode]
        }`
      : ''

    payload.conditions = [...payload.conditions, ...this.getModeConditions]

    if (this.snippetsFilter?.filter_keyword && this.snippetsFilter?.filter_keyword?.length > 0) {
      payload.conditions.push({
        keywords_expression: this.snippetsFilter?.filter_keyword,
      })
    }

    if (this.snippetsFilter?.watchlist && this.snippetsFilter?.watchlist?.length > 0) {
      payload.conditions.push({
        channel_urls: this.snippetsFilter?.watchlist,
      })
    }

    if (skip !== 'flags') {
      const filterData = this.snippetsFilter.attentionFlags
      if (filterData && Object.keys(filterData).length > 0) {
        const allFlags = Object.keys(filterData)
        const promises = []
        for (const element in allFlags) {
          const key = allFlags[element]
          const nameKey = allFlags[element].split(',')[1]
          const ids = filterData[key]

          if (nameKey !== 'keywords_expression' && Array.isArray(ids)) {
            const modeData: any =
              nameKey === 'narratives'
                ? { mode: 'narrative', key: 'narrative_number' }
                : { mode: 'community', key: 'community_number' }

            for (const idKey in ids) {
              const id = ids[idKey]
              const obj: any = {}
              obj[id] = ''
              this.setIdNumberList(obj)
              promises.push(API.get({ route: modeData.mode, id }))
            }

            let responses = await Promise.all(promises)
            responses = responses.map(({ data: item }) => {
              if (item && item[modeData.key]) {
                const obj: any = {}
                const id = item.id
                obj[id] = item[modeData.key]
                this.setIdNumberList(obj)
                return item[modeData.key]
              } else return ''
            })

            let obj: any = {}
            responses = responses.filter((item) => item && item !== '')
            obj[nameKey] = [...responses]
            if (responses.length > 0) payload.conditions.push(obj)
          } else {
            payload.conditions.push({
              keywords_expression: filterData[allFlags[element]] as string,
            })
          }
        }
      }
    }

    return payload
  }

  fetchLightTags = async () => {
    try {
      const data = await tagAPI.getSummaryTags()
      this.setLightTags(data.tags)
    } catch (e) {}
  }

  fetchLiteWatchlists = async () => {
    try {
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })
      const watchlistsData = response?.data.items?.map((watchlist: any) => {
        let channels = []
        if (watchlist?.channels) {
          channels = watchlist?.channels.map((channel: any) => {
            const urls = channel?.channel_by_platform.map((item: any) => item.channel_url)
            return {
              platform: channel.platform,
              channel_urls: urls,
            }
          })
        }
        return { id: watchlist.id, name: watchlist?.name, channels: channels }
      })
      this.setLiteWatchlists(watchlistsData)
    } catch (error) {}
  }

  fetchSharedUsers = async (id: string) => {
    const { data } = await API.get({
      route: this.storeType,
      extraPath: 'share',
      id: id,
    })
    this.setShareOwner(data.owner)

    if (data?.shared_users) this.setShareUsers(data.shared_users)
  }

  fetchListItemsSharedWithUser = async ({
    id,
    querry,
    // setActiveId = '',
    pageSize = 20,
    pageName = 'list',
    isRefreshing = false,
  }: {
    id?: string
    querry?: string
    setActiveId?: string
    pageSize?: number
    pageName?: string
    loadNew?: boolean
    isRefreshing?: boolean
  }) => {
    try {
      let items
      if (id) {
        const { data: item } = await API.get({
          route: this.storeType,
          getError: true,
          id,
        })
        items = [item]
        this.setListItemsSharedWithUserPagination({ total: 1, locallyLoaded: 1 })
      } else {
        if (querry && this.recentSearch !== querry) {
          this.setListItemsSharedWithUserPagination({
            total: 0,
            locallyLoaded: 0,
          })
          this.setRecentSearch(querry)
          this.setListItemsSharedWithUser([])
        }

        if (
          !isRefreshing &&
          this.listItemsSharedWithUserPagination.total <= this.listItemsSharedWithUserPagination.locallyLoaded &&
          this.listItemsSharedWithUserPagination.total !== 0
        )
          return

        let page = Math.ceil(this.listItemsSharedWithUserPagination.locallyLoaded / pageSize) + 1
        if (isRefreshing) {
          page = 1
        }

        const sortLookup = { 'From A to Z': 'name:asc', 'From Z to A': 'name:desc' }

        let { data, total } = await API.get({
          route: this.storeType,
          page: page,
          pageSize: pageSize,
          getError: true,
          sort: querry
            ? `${this.storeType === 'tag' ? 'label' : 'name'}`
            : this.listFilter.name
            ? //@ts-ignore
              sortLookup[this.listFilter.name]
            : '',
          filter: querry
            ? `${this.storeType === 'tag' ? 'label' : 'name'}:like:${querry}`
            : this.storeType === 'narrative' || this.storeType === 'bookmark'
            ? this[`filterSharedWithUser`]
            : '',
        })
        this.setListItemsSharedWithUserPagination({
          total,
          locallyLoaded: this.listItemsSharedWithUserPagination.locallyLoaded + data.items.length,
        })
        items = data.items
      }

      const data = items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        // if (setActiveId && item.id === setActiveId) {
        //   this.setActiveItem(item, 'Suggestions')
        // }
        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          tags: item.tags,
          permissionLevel: item?.permission_level,
        }
      })

      if (pageName !== 'details' && this.storeType === 'narrative') {
        this.fetchGrowth(data)
      }

      if (isRefreshing) {
        this.setListItemsSharedWithUser(data)
      } else {
        this.updateListItemsSharedWithUser(data)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchListItems = async ({
    id,
    querry,
    // setActiveId = '',
    pageSize = 20,
    pageName = 'list',
    isRefreshing = false,
  }: {
    id?: string
    querry?: string
    setActiveId?: string
    pageSize?: number
    pageName?: string
    loadNew?: boolean
    isRefreshing?: boolean
  }) => {
    //because of the filter/sort differences, this method is implemented in every store that extends this store

    try {
      let items
      if (id) {
        const { data: item } = await API.get({
          route: this.storeType,
          getError: true,
          id,
        })
        items = [item]
        this.setPaginationItems({ total: 1, locallyLoaded: 1 })
      } else {
        if (querry && this.recentSearch !== querry) {
          this.setPaginationItems({
            total: 0,
            locallyLoaded: 0,
          })
          this.setRecentSearch(querry)
          this.setListItems([])
        }

        if (
          !isRefreshing &&
          this.listItemsPagination.total <= this.listItemsPagination.locallyLoaded &&
          this.listItemsPagination.total !== 0
        ) {
          return
        }

        let page = Math.ceil(this.listItemsPagination.locallyLoaded / pageSize) + 1
        if (isRefreshing) {
          page = 1
        }

        const sortLookup = { 'From A to Z': 'name:asc', 'From Z to A': 'name:desc' }

        let { data, total } = await API.get({
          route: this.storeType,
          page: page,
          pageSize: pageSize,
          getError: true,
          sort: querry
            ? `${this.storeType === 'tag' ? 'label' : 'name'}`
            : this.listFilter.name
            ? //@ts-ignore
              sortLookup[this.listFilter.name]
            : '',
          filter: querry
            ? `${this.storeType === 'tag' ? 'label' : 'name'}:like:${querry}`
            : this.storeType === 'narrative'
            ? this[`filterSuggestions`]
            : '',
        })
        this.setPaginationItems({
          total,
          locallyLoaded: this.listItemsPagination.locallyLoaded + data.items.length,
        })
        items = Array.isArray(data) ? [...data] : data.items
      }

      const data = items?.map((item: any) => {
        const date =
          new Date(item.last_updated?.replace(/\s/, 'T')) || new Date(item.last_update_time?.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        // if (setActiveId && item.id === setActiveId) {
        //   this.setActiveItem(item, 'Suggestions')
        // }
        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive' as const,
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
          channels: item?.channels,
          permissionLevel: item?.permission_level,
        }
      })

      if (pageName !== 'details' && this.storeType === 'narrative') {
        this.fetchGrowth(data)
      }

      if (isRefreshing) {
        this.setListItems(data)
      } else {
        this.updateListItems(data)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchListItemsByUser = async ({
    pageSize = 20,
    // activeId = '',
    pageName = 'list',
    isRefreshing = false,
  }: {
    pageSize?: number
    activeId?: string
    pageName?: string
    isRefreshing?: boolean
  }) => {
    //because of the filter/sort differences, this method is implemented in every store that extends this store
    try {
      if (
        !isRefreshing &&
        this.listItemsByUserPagination.total <= this.listItemsByUserPagination.locallyLoaded &&
        this.listItemsByUserPagination.total !== 0
      )
        return

      let page = Math.ceil(this.listItemsByUserPagination.locallyLoaded / pageSize) + 1
      if (isRefreshing) {
        page = 1
      }

      const sortLookup = { 'From A to Z': 'name:asc', 'From Z to A': 'name:desc' }
      let { data, total } = await API.get({
        route: this.storeType,
        page: page,
        pageSize: pageSize,
        getError: true,
        sort: this.listFilter.name
          ? //@ts-ignore
            sortLookup[this.listFilter.name]
          : '',
        filter: this[`filterUser`],
      })

      this.setPaginationItemsByUser({
        total,
        locallyLoaded: this.listItemsByUserPagination.locallyLoaded + data.items.length,
      })

      const items = data.items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        // if (activeId && item.id === activeId) {
        //   this.setActiveItem(item, 'User')
        // }
        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
          permissionLevel: item?.permission_level,
        }
      })

      if (pageName !== 'details' && this.storeType === 'narrative') {
        this.fetchGrowth(items)
      }

      if (isRefreshing) {
        this.setListItemsCreatedByUser(items)
      } else {
        this.updateListItemsByUser(items)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchListItemsByNarrativeLibrary = async ({
    pageSize = 20,
    pageName = 'list',
    isRefreshing = false,
  }: {
    pageSize?: number
    pageName?: string
    isRefreshing?: boolean
  }) => {
    //because of the filter/sort differences, this method is implemented in every store that extends this store
    try {
      if (
        !isRefreshing &&
        this.paginationNarLib.total <= this.paginationNarLib.locallyLoaded &&
        this.paginationNarLib.total !== 0
      )
        return

      let page = Math.ceil(this.paginationNarLib.locallyLoaded / pageSize) + 1
      if (isRefreshing) {
        page = 1
      }

      const sortLookup = { 'From A to Z': 'name:asc', 'From Z to A': 'name:desc' }
      let { data, total } = await API.get({
        route: this.storeType,
        page: page,
        pageSize: pageSize,
        getError: true,
        sort: this.listFilter.name
          ? //@ts-ignore
            sortLookup[this.listFilter.name]
          : '',
        filter: this[`filterUser`],
        in_library: true,
      })

      this.setPaginationNarLib({
        total,
        locallyLoaded: this.paginationNarLib.locallyLoaded + data.items.length,
      })

      const items = data.items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
          permissionLevel: item?.permission_level,
        }
      })

      if (pageName !== 'details' && this.storeType === 'narrative') {
        this.fetchGrowth(items)
      }

      if (isRefreshing) {
        this.setListItemsNarrativeLibrary(items)
      } else {
        this.updateListItemsNarrativeLibrary(items)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchGrowth = (list: IListItem[]) => {}

  fetchSnippetsInfo = async (id: string, listName: string) => {}

  fetchPowerInsights = async (id: string, listName: string) => {
    try {
      this.setActiveItem(null, '')
      this.setSnippets([])
      this.setSnippetsTotal(0)
      this.setSnippetsInfo([], [])

      const { data } = await API.get({ route: this.storeType, id })
      this.setActiveItem(data, listName)

      const communities = data.entity_details.COMMUNITY.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })
      const narratives = data.entity_details.NARRATIVE.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })
      this.setSnippetsInfo(narratives, communities)

      const payload = await this.snippetFilterPayload()

      const languageMetricsPromises: Promise<any>[] = []
      LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
        languageMetricsPromises.push(executiveIntelligenceAPI.getExecutiveMetrics({ ...payload, dimension }))
      })
      const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)
      const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}
      languageMetricsResolved.forEach((metric, index) => {
        if (metric.status === 'rejected') return

        const metricData = metric as PromiseFulfilledResult<any>

        let metricName = LANGUAGE_METRICS_DIMENSIONS[index]
        languageMetricsProcessed[metricName] = metricData.value
      })

      const [
        { data: postsLineChart },
        { data: postSentimentsLineChart },
        { data: postPlatformsLineChart },
        { data: impressionsLineChart },
        { data: impressionSentimentsLineChart },
        { data: impressionPlatformsLineChart },
        { data: dataHeatMap },
        { data: dataLineChart2 },
        peopleData,
        companiesData,
        countriesMapData,
        summaryStatisticsData,
        languagesData,
      ] = await Promise.all([
        powerInsightsAPI.lineChart.getLineChartData(payload),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'platform',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'platform',
        }),
        // Get the heat map communities data for last 24 hours only
        powerInsightsAPI.treeMap.getTreeMapData(payload),
        // Get the total_change_impression_count for last 24 hours only
        powerInsightsAPI.lineChart.getLineChartData(payload),
        this.fetchPeopleData(payload),
        this.fetchCompaniesData(payload),
        this.fetchCountriesData(payload),
        this.fetchSummaryData(payload),
        this.fetchLanguagesData(payload),
      ])

      this.updateListItem(id, '', {
        impresions: postsLineChart.total_change_count,
        trend: postsLineChart.change_direction,
        totalChangeImpressionCountLastDay: dataLineChart2.total_change_count,
      })

      // set the new Power Insights Data
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        summary: {
          executiveSummary: summaryStatisticsData.executiveSummary,
          statistics: [
            {
              label: 'Snippets',
              value: millify(summaryStatisticsData.statistics?.total_snippets),
            },
            {
              label: 'Unique Creators',
              value: millify(summaryStatisticsData.statistics?.creator_count),
            },
            {
              label: 'Companies',
              value: millify(summaryStatisticsData.statistics?.company_count),
            },
            {
              label: 'People',
              value: millify(summaryStatisticsData.statistics?.people_count),
            },
            {
              label: 'Organizations',
              value: millify(summaryStatisticsData.statistics?.country_count),
            },
            {
              label: 'Views',
              value: millify(summaryStatisticsData.statistics?.total_views),
            },
          ],
          platformsDistribution: summaryStatisticsData.platformsDistribution,
        },
        lineChartData: {
          posts: {
            all: { data_points: postsLineChart.data_points },
            sentiment: postSentimentsLineChart,
            platform: postPlatformsLineChart,
          },
          impressions: {
            all: { data_points: impressionsLineChart.data_points },
            sentiment: impressionSentimentsLineChart,
            platform: impressionPlatformsLineChart,
          },
        },
        heatMapData: {
          data_points: dataHeatMap.data_points,
        },
        beeSwarmNodesData: [],
        languageRisks: languageMetricsProcessed,
        peopleData: {
          completeData: peopleData.data_points,
          data: peopleData.total_count > 8 ? peopleData.data_points.slice(0, 8) : peopleData.data_points,
        },
        companyData: {
          completeData: companiesData.data_points,
          data: companiesData.total_count > 8 ? companiesData.data_points.slice(0, 8) : companiesData.data_points,
        },
        countriesMapData: countriesMapData.data_points,
        languagesData: { data: languagesData.data_points },
      })

      const itemCommunities: number[] = dataHeatMap.data_points.map(
        (point: { community_id: number }) => point.community_id,
      )
      const communitiesRequest: number[] = []
      itemCommunities.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
      this.getCommunities(communitiesRequest)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSnippets = async (
    { isSorting }: ISnippetParams = {
      isSorting: false,
    },
    isBack: boolean = false,
  ) => {
    try {
      const isThereAnyData = !!this.snippetsInfo?.narratives.length || !!this.snippetsInfo?.communities.length
      const isTheDataValid = !(this.snippetsInfo?.narratives[0] === -1 || this?.snippetsInfo?.communities[0] === -1)

      if (!isTheDataValid || !isThereAnyData) {
        UtilService.openNotification({
          type: 'info',
          message: `No content for your ${this.storeType}`,
          description: `There are no communities or narratives attached to your ${this.storeType}.`,
          duration: 20,
        })
        this.setSnippets([])
        this.setSnippetsTotal(0)
        return
      }

      if (this.snippets.length >= this.totalSnippets && this.snippets.length !== 0 && !isSorting) return

      const page = isSorting ? this.snippetsCurrentPage : Math.floor(this.snippets.length / 50) + 1
      const payload = await this.snippetFilterPayload()
      this.setSnippetsCurrentPage(page)
      payload.page = page
      payload.per_page = 50

      const { data } = await monitorAPI.loadFeed(payload)

      if (!data?.items.length) this.setIsFeedEmpty(false)
      this.setSnippetsTotal(data.total_count)

      const { snippets, creators: creatorsList } = UtilService.snippetTranslator({
        rawSnippets: data.items,
        isFilterApplied: this.snippetsFilterInfo.language,
      })
      snippets['feedback'] = 'none'
      if (isSorting) this.setSnippets(snippets)
      else this.updateSnippets(snippets)

      let creators: string[] = creatorsList
      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      await this.getCreators(creators)
    } catch (e) {
      LogService.error({ message: 'Error in monitor store', error: e })
      LogService.error({ error: e, message: 'Error in fetch snippets method.' })
    }
  }

  exportFeed = async ({
    sort,
    sort_mode,
    time,
    total_snippets,
  }: {
    sort: string
    sort_mode: string
    time: string[]
    total_snippets: number
  }) => {
    try {
      const page = this.snippetsCurrentPage
      const payload = await this.snippetFilterPayload()
      payload.page = page
      payload.per_page = 50
      payload.sort =
        sort?.length && sort_mode?.length
          ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[sort]}:${MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[sort_mode]}`
          : ''
      const filters: {
        platform: string[]
        sentiment: string[]
        risk_score: string
      } = {
        platform: [],
        sentiment: [],
        risk_score:
          this.snippetsFilter?.filter_risk && this.snippetsFilter?.filter_risk?.length > 1
            ? `${this.snippetsFilter?.filter_risk[0]} to ${this.snippetsFilter?.filter_risk[1]}`
            : '',
      }

      if (Array.isArray(this.snippetsFilter.filter_platform)) {
        filters.platform = this.snippetsFilter.filter_platform
      } else if (this.snippetsFilter?.filter_platform && (this.snippetsFilter?.filter_platform?.length || 0) > 0) {
        filters.platform = this.snippetsFilter.filter_platform?.split(',')
      }

      if (Array.isArray(this.snippetsFilter.filter_sentiment)) {
        filters.sentiment = this.snippetsFilter.filter_sentiment
      } else if (this.snippetsFilter?.filter_sentiment && (this.snippetsFilter.filter_sentiment?.length || 0) > 0) {
        filters.sentiment = this.snippetsFilter?.filter_sentiment?.split(',')
      }

      payload.query.date = `start_date:gte:${time[0]},end_date:lte:${time[1]}`

      const date = { start_date: time[0], end_date: time[1] }

      const info = {
        name: this.activeItem?.name || '',
        entity_id: this.activeItem?.id || '',
        entity_type: this.storeType,
        sort_by: sort,
        order_by: sort_mode,
        filters,
        total_snippets,
        ...date,
      }
      await exportAPI.exportFeed({ ...payload, ...info })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTopicsRequestId = async () => {
    try {
      const payload = await this.snippetFilterPayload()
      const { data } = await monitorAPI.getTopicsRequestId(payload)
      this.topicRequestId = data.topic_request_id
      return data.topic_request_id
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })

      return 'error'
    }
  }

  fetchUserBookmarkedSnippets = async () => {
    try {
      const response = (await bookmarksAPI.getUserBookmarkedSnippets()) as string[]
      this.setUserBookmarkedSnippets(response)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSurpriseVideos = async () => {
    try {
      const { data } = await API.get({ route: 'takeABreak', customAPI: process.env.REACT_APP_SEARCH_API })
      this.setSurpriseVideos(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSummaryData = async (payload: SnippetFilterPayload) => {
    const [executiveSummary, statistics, platformsDistribution] = await Promise.all([
      anticipatoryIntelligence.getSummary(payload),
      powerInsightsAPI.summary.getPowerInsightsSummaryStatistics(payload),
      anticipatoryIntelligence.getPlatformChartData(payload),
    ])

    return {
      executiveSummary: executiveSummary?.summary_text ?? '',
      statistics,
      platformsDistribution: platformsDistribution?.data_points,
    }
  }

  fetchPeopleData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.people.getPeopleData(payload)
  }

  fetchCompaniesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.companies.getCompaniesData(payload)
  }

  fetchCountriesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.countries.getCountriesMapData(payload)
  }

  fetchLanguagesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.languages.getLanguagesData(payload)
  }

  fetchWatchlistData = async () => {
    try {
      const payload = await this.snippetFilterPayload()
      this.setPowerInsightsData({ ...this.powerInsightsData, watchlistData: { completeData: [], data: [] } })
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })
      const watchlistData: IBarChartWatchlistData[] = []
      await Promise.all(
        response?.data.items?.map(async (watchlist: any) => {
          let channels: string[] = []
          if (watchlist?.channels) {
            watchlist?.channels.forEach((channel: any) =>
              channel?.channel_by_platform.forEach((item: any) => {
                channels.push(item.channel_url)
              }),
            )
          }
          const conditions = []
          conditions.push({
            channel_urls: Array.from(new Set(channels)),
          })

          const { data } = await monitorAPI.loadFeed({
            ...payload,
            conditions: [...(payload.conditions || []), ...conditions],
          })
          watchlistData.push({
            entity_name: watchlist?.name,
            metric_value: data.total_count,
            urls: Array.from(new Set(channels)),
          })
        }),
      )

      const watchlistPostCount = {
        ...this.powerInsightsData,
        watchlistData: {
          completeData: watchlistData,
          data: watchlistData.length > 8 ? watchlistData.slice(0, 8) : watchlistData,
        },
      }

      this.setPowerInsightsData(watchlistPostCount)
    } catch (error) {}
  }

  fetchBarChartData = async (tab: 'company' | 'people' | 'language' | 'watchlist') => {
    const payload = await this.snippetFilterPayload()
    if (tab === 'company') return this.fetchCompaniesData(payload)
    else if (tab === 'people') return this.fetchPeopleData(payload)
    else if (tab === 'watchlist') return this.fetchWatchlistData()
    else if (tab === 'language') return this.fetchLanguagesData(payload)
  }

  // reset the snippetsFilterInfo and snippetsFilter to their default values
  resetSnippetFilters = (customDate: { startDate?: string; endDate?: string; numberOfDays?: number } | null = null) => {
    const isDefaultCustomDateApplied = customDate?.startDate && customDate?.endDate
    const isCustomDateApplied = isDefaultCustomDateApplied ? true : false
    const defaultCustomDate = isDefaultCustomDateApplied ? [customDate?.startDate, customDate?.endDate] : []
    const days = customDate ? customDate?.numberOfDays : 90
    this.setSnippetsFilterInfo({
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    })
    if (this.defaultPlatforms.length) {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: isCustomDateApplied,
            dateRange: defaultCustomDate as string[],
          },
          noOfDays: days as number,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
        filter_platform: this.defaultPlatforms,
      })
    } else {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: isCustomDateApplied,
            dateRange: defaultCustomDate as string[],
          },
          noOfDays: days as number,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
      })
    }
  }

  resetStore = () => {
    this.itemDetailsError = null
    this.listItems = []
    this.listItemsCreatedByUser = []
    this.listItemsSharedWithUser = []
    this.isPowerInsightsOpen = false
    this.snippets = []
    this.totalSnippets = 0
    this.surpriseModalData = null
    this.sourceModalData = null
    this.surpriseVideos = []
    this.listItemsPagination = { total: 0, locallyLoaded: 0 }
    this.listItemsByUserPagination = { total: 0, locallyLoaded: 0 }
    this.listItemsSharedWithUserPagination = { total: 0, locallyLoaded: 0 }
    this.activeItem = null
    this.lightTags = []
    this.isFeedEmpty = true
    this.snippetsFilter = {
      days: this.snippetsFilter.days,
      sort: 'Upload Date',
      sort_mode: 'Descending',
      filter_risk: [0, 100],
    }
    this.snippetsFilterInfo = {
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    }
    this.powerInsightSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
    autoSave(this, 'snippetDateFilter')
    this.listItemsNarrativeLibrary = []
    this.paginationNarLib = { total: 0, locallyLoaded: 0 }
  }
}

function autoSave(_this: any, name: string) {
  const snippetDateFilter = sessionStorage.getItem(name)

  if (snippetDateFilter) {
    let platforms = { filter_platform: '' }
    if (_this.defaultPlatforms.length) {
      platforms = {
        filter_platform: _this.defaultPlatforms,
      }
    }
    set(_this, 'snippetsFilter', {
      days: JSON.parse(snippetDateFilter),
      sort: 'Upload Date',
      sort_mode: 'Descending',
      filter_risk: [0, 100],
      ...platforms,
    })
  }
  autorun(() => {
    sessionStorage.setItem(name, JSON.stringify(_this.snippetsFilter.days))
  })
}
