import { observer } from 'mobx-react-lite'
import { Menu } from 'antd'
import millify from 'millify'
import classNames from 'classnames'
import { store } from 'store'

import { AnticipatorySideDetails, INarrativeInfoCategory } from 'models/models'
import { ExecutiveIntelligenceGraph } from 'version2/components/common/ExecutiveIntelligence/ExecutiveIntelligenceGraph/ExecutiveIntelligenceGraph'
import { RequiresAttention } from 'version2/components/Monitor/ExecutiveIntelligence/Listing/ExecutiveIntelligenceTable/ExecutiveIntelligenceRequiresAttention/ExecutiveIntelligenceRequiresAttention'

import { ReactComponent as DetailsIcon } from 'assets/images/details-icon.svg'

import './NarrativeInfo.scss'

interface Props {
  category: INarrativeInfoCategory
  tab: string
  isListView?: boolean
  isChordView?: boolean
  sideDetailsInfo?: AnticipatorySideDetails
  setNarrativeInfoDropdown?: (element: JSX.Element) => void
}

export const NarrativeInfo = observer(
  ({ category, isListView, isChordView, sideDetailsInfo, setNarrativeInfoDropdown }: Props) => {
    const { anticipatoryIntelligenceStore } = store
    const { setIsSideDetailsOpen, setSideDetails, setActiveThreatNarrative, setActiveThreatNarrativeConditions } =
      anticipatoryIntelligenceStore

    const onClickView = () => {
      let details = sideDetailsInfo
        ? `${sideDetailsInfo.topic}_${sideDetailsInfo.category}_${sideDetailsInfo.riskOrFlagName}`
        : ''
      details = details.endsWith('_') ? details.slice(0, -1) : details
      setIsSideDetailsOpen(details)
      setSideDetails(
        sideDetailsInfo || {
          topic: '',
          category: '',
          riskOrFlagName: '',
          tab: '',
          title: '',
        },
      )
      setActiveThreatNarrative(category)
      if (category.conditions) {
        setActiveThreatNarrativeConditions(category?.conditions)
      }
      if (isChordView) setNarrativeInfoDropdown && setNarrativeInfoDropdown(<></>)
    }

    return (
      <Menu className='narrative-info'>
        <Menu.Item>
          <div>
            <div className='narrative-info__header'>
              <div className='narrative-info__header__container'>
                <div>
                  <span className='narrative-info__header__heading'>{category.label}</span>
                  <span className='narrative-info__header__heading__large-font'>
                    {(category?.subItemName?.length || 0) > 0 && ` ∞ `}
                  </span>
                  <span className='narrative-info__header__heading__margin'>
                    {(category?.subItemName?.length || 0) > 0 && category.subItemName}
                  </span>
                </div>
                <span className='narrative-info__stream-text'>High stream impact </span>
              </div>
              <button className='narrative-info__header__details-btn' onClick={onClickView}>
                View details
                <DetailsIcon />
              </button>
            </div>
            <ExecutiveIntelligenceGraph mode='4' data={category.metricValues || []} color={'#D6B77B'} />
            {!isListView && (
              <>
                <div className='narrative-info__summary'>
                  <span className='narrative-info__summary__label'>{'Risk:'}</span>
                  <span className='narrative-info__summary__description'>{category.description || 'N/A'}</span>
                </div>

                <div className='narrative-info__details'>
                  <span className='narrative-info__details__label'>{'Content:'}</span>
                  <span className='narrative-info__details__description--blue'>
                    {millify(category.noOfPosts || 0) || 'N/A'}
                  </span>
                </div>
              </>
            )}

            <div className={classNames('narrative-info__details', { 'narrative-info__details__margin': isListView })}>
              <span className='narrative-info__details__label'>{'Attention Flags:'}</span>
              <RequiresAttention mode={'narrative'} warnings={category.flags} />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    )
  },
)
