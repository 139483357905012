import React, { useEffect, useState } from 'react'
import { store } from 'store'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as ReportButtonIcon } from 'assets/images/icons/report/report-button-stars-icon.svg'
import { ReactComponent as BackBtnIcon } from 'assets/images/icons/arrow-left.svg'
import styles from './DetailViewHeader.module.scss'
import ActionButtons from '../ActionButtons/ActionButtons'
import ReportModal from 'version2/components/common/Modal/ReportModal'
import SuccessBodyModal from 'version2/components/common/Modal/SuccessBodyModal/SuccessBodyModal'
import { ReactComponent as EdittIcon } from 'assets/images/icons/editreport.svg'
import { ReactComponent as SendEmailIcon } from 'assets/images/send-email-icon.svg'
import successGif from 'assets/gifs/reportLoader.gif'
import deleteGif from 'assets/gifs/sectiondeleted.gif'
import ShareReportModal from '../ShareReportModal/ShareReportModal'
import DeleteReportModal from '../DeleteReportModal/DeleteReportModal'
import { PDF } from '../PDFView/PDFView'
import { usePDF } from '@react-pdf/renderer'
import usePermissions from 'version2/hooks/usePermissions'

type DetailViewHeaderProps = {
  isDetailPanel?: boolean
  className?: string
  backHandler?: () => void
  onClick: () => void
  title: string
  buttonText: string
  reportId: string
  readingTime: string
  instanceId: string
}

function DetailViewHeader(props: DetailViewHeaderProps) {
  const navigate = useNavigate()
  const { isDetailPanel, className, backHandler, onClick, title, buttonText, reportId, readingTime, instanceId } = props
  const { reportMainViewStore } = store
  const { reportDetailsEntities, getOrDeleteReportHandler, updateReportHandler } = reportMainViewStore
  const [instance, updateInstance] = usePDF({ document: <PDF data={reportDetailsEntities} /> })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSuccessModalVisible, setIsSuccesModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteSuccessVisible, setIsDeleteSuccessVisible] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [emailModalInfo, setEmailModalInfo] = useState<{
    title: string
    icon: React.ElementType
    desc: string
    type: 'share' | 'email'
    secondButtonText: string
  } | null>(null)

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const closeCreateAndOpenSuccessModal = (data: any) => {
    updateReportHandler(reportId, data).then((res) => {
      if (res === 'error') {
        return
      }

      if ('id' in res && isDetailPanel) {
        navigate(`/reports/details/${res.id}?instance_id=${res.instance_id}`, { replace: true })
      } else if ('id' in res) {
        navigate(`/reports/${res.id}?instance_id=${res.instance_id}`, { replace: true })
      }
      setIsSuccesModalVisible(true)
      setIsModalVisible(false)
      setTimeout(closeSuccesModal, 5000)
    })
  }
  useEffect(() => {
    if (reportDetailsEntities.id) {
      updateInstance(<PDF data={reportDetailsEntities} />)
    }
  }, [reportDetailsEntities.id, JSON.stringify(reportDetailsEntities)])

  const closeSuccesModal = () => {
    setIsSuccesModalVisible(false)
  }
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const closeDeleteAndOpenSuccessModal = () => {
    setIsDeleteSuccessVisible(true)
    setIsDeleteModalVisible(false)
  }
  const closeDeleteSuccess = () => {
    setIsDeleteSuccessVisible(false)
    navigate('/reports', { replace: true })
  }

  return (
    <div className={classNames(styles.detail_view_header_container, className)}>
      <div className={styles.dvhc_left_side}>
        {backHandler && <BackBtnIcon className={styles.ls_back_icon} onClick={backHandler} />}
        <p className={styles.ls_report_heading}>{title}</p>
        <ReportButtonIcon className={styles.ls_report_icon} />
        <p className={styles.ls_report_read_text}>{readingTime} read</p>
      </div>
      <div className={styles.dvhc_right_side}>
        {!canReviewAndReceive && (
          <>
            <ActionButtons
              showTooltip={true}
              deleteHandler={() => setIsDeleteModalVisible(true)}
              shareHandler={() => {
                setIsShareModalOpen(true)
              }}
              editHandler={() => {
                setIsModalVisible(true)
              }}
              downloadHandler={() => {
                if (!instance.loading && instance.error === null) {
                  const link = document.createElement('a')
                  link.download = `${title.replaceAll(' ', '_')}.pdf`

                  link.href = instance.url!

                  link.click()
                }
              }}
              emailHandler={() => {
                setIsShareModalOpen(true)
                setEmailModalInfo({
                  title: 'Email Report',
                  icon: SendEmailIcon,
                  desc: `Select users to send the report to via email. Emailing the report also shares the report within Pendulum.`,
                  type: 'email',
                  secondButtonText: 'Send Report',
                })
              }}
            />
            <ButtonImage onClick={onClick} className={styles.rs_button}>
              {buttonText}
            </ButtonImage>
          </>
        )}
      </div>

      {/* ...........the edit report button in detail ................... */}
      {isModalVisible && (
        <ReportModal
          reportId={reportId}
          editMode={true}
          isModalVisible
          closeModal={closeModal}
          closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
          icon={<EdittIcon />}
          title='Edit Report'
          description='Create and schedule your report by adding multiple items.'
          footerButtonText='Update'
        />
      )}
      {isSuccessModalVisible && (
        <SuccessBodyModal
          visibilityController={isSuccessModalVisible}
          gif={successGif}
          heading='Report is updating ...'
          subheading='it can take up to 2 minutes for updating a report'
        />
      )}

      {isDeleteModalVisible && (
        <DeleteReportModal reportId={reportId} onClose={closeDeleteModal} onDelete={closeDeleteAndOpenSuccessModal} />
      )}

      {isDeleteSuccessVisible && (
        <SuccessBodyModal
          isDelete={true}
          visibilityController={isDeleteSuccessVisible}
          gif={deleteGif}
          heading='Report Deleted'
          subheading=''
          closeDeleteSuccess={closeDeleteSuccess}
          buttonText='Close'
        />
      )}

      {isShareModalOpen && (
        <ShareReportModal
          {...emailModalInfo}
          endpoint='reports'
          reportId={reportId}
          instanceId={instanceId}
          callback={() => getOrDeleteReportHandler('get', reportId)}
          onClose={() => {
            setIsShareModalOpen(false)
            setEmailModalInfo(null)
          }}
        />
      )}
    </div>
  )
}

export default DetailViewHeader
