/* eslint-disable prettier/prettier */
import { ExecutiveIntelligenceStore } from './monitorV1/executiveIntelligence.store'
import { MonitorWatchlistStore } from './monitorV1/monitorWatchlist.store'
import { BookmarkStore } from './monitorVersion2/bookmark.store'
import { ResetPasswordStore } from './resetPassword.store'
import { SubnavSearchStore } from './subnavSearch.store'
import { FeedbackStore } from './manage/feedback.store'
import { NavViewStore } from './navView.store'
import { LoaderStore } from './loader.store'
import { UsersStore } from './users.store'
import { UserStore } from './user.store'
import { WhiteboardStore } from './whiteboard.store'
import { AttentionFlagsStore } from './attentionFlags/attentionFlags.store'
import { ReportMainViewStore } from './report/reportMainView.store'
import { MonitorStore as MonitorStoreV1 } from './monitorV1/monitor.store'
import { MonitorExportDownloadsStore } from './monitorV1/monitorDownloads.store'
import { WhiteboardV2Store } from './whiteboardV2.store'
import { TenantsStore } from './tenants/tenants.store'
import { FlagsStore } from './flags/flags.store'
import { AssetsStore } from './asset/asset.store'
import { CombineMonitorViewStore } from './monitorVersion2/combineMonitorView.store'
import { VectorsStore } from './vectors/vectors.store'
import { ToasterStore } from './toaster/toaster.store'
import { BreadcrumbStore } from './breadcrumb/breadcrumb.store'
import { MainStore } from './main/main.store'
import { AnticipatoryIntelligenceStore } from './anticipatoryIntelligence/anticipatoryIntelligence.store'
import { DefineWatchlistStore } from './define/defineWatchlist.store'
import { DefineNarrativeStore } from './define/defineNarrative.store'

class RootStore {
  userStore
  loaderStore

  resetPasswordStore

  feedbackStore

  monitorWatchlist
  monitorExportDownloadsStore
  executiveIntelligence

  usersStore

  subnavSearch

  navViewStore

  whiteboardStore

  attentionFlagsStore
  tenantsStore

  reportMainViewStore
  monitorStore1

  whiteboardV2Store
  flagsStore
  assetsStore
  combineMonitorViewStore
  vectorsStore
  toasterStore
  breadcrumbStore
  bookmarkStore
  mainStore
  anticipatoryIntelligenceStore
  defineNarrativeStore
  defineWatchlistStore

  constructor() {
    this.userStore = new UserStore()
    this.loaderStore = new LoaderStore()
    this.resetPasswordStore = new ResetPasswordStore()

    this.feedbackStore = new FeedbackStore()

    this.monitorWatchlist = new MonitorWatchlistStore()
    this.executiveIntelligence = new ExecutiveIntelligenceStore()

    this.usersStore = new UsersStore()

    this.subnavSearch = new SubnavSearchStore()

    this.navViewStore = new NavViewStore()

    this.whiteboardStore = new WhiteboardStore()

    this.attentionFlagsStore = new AttentionFlagsStore()
    this.tenantsStore = new TenantsStore()

    this.reportMainViewStore = new ReportMainViewStore()
    this.monitorStore1 = new MonitorStoreV1()
    this.monitorExportDownloadsStore = new MonitorExportDownloadsStore()
    this.whiteboardV2Store = new WhiteboardV2Store()
    this.bookmarkStore = new BookmarkStore()
    this.flagsStore = new FlagsStore()
    this.assetsStore = new AssetsStore()
    this.combineMonitorViewStore = new CombineMonitorViewStore()
    this.vectorsStore = new VectorsStore()
    this.toasterStore = new ToasterStore()
    this.breadcrumbStore = new BreadcrumbStore()

    this.mainStore = new MainStore()
    this.anticipatoryIntelligenceStore = new AnticipatoryIntelligenceStore()
    this.defineNarrativeStore = new DefineNarrativeStore()
    this.defineWatchlistStore = new DefineWatchlistStore()
  }
}

export const store = new RootStore()
