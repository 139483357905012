import { Radio, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import AssetCategories from '../AssetCategories/AssetCategories'
import { DateFilter } from 'version2/components/common/DateFilter/DateFilter'
import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import DeleteConfirmationModal from 'version2/components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'

import { ReactComponent as AddIcon } from 'assets/images/icons/flag/add_icon_20.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/monitor/close_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'
import { ReactComponent as DashboardIcon } from 'assets/images/icons/assets/dashboard.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/assets/list.svg'
import { ReactComponent as ExecutiveSummary } from 'assets/images/icons/assets/executive-summary.svg'

import { store } from 'store'

import { flagsAPI } from 'api/api'
import { displayApiError } from 'version2/utils/helper'
import { AssetCategoryType, AssetListParamsType } from 'store/asset/types'

import './AssetHeader.scss'
import usePermissions from 'version2/hooks/usePermissions'
import ExecutiveSummaryModal from '../BrandsDashboard/components/ExecutiveSummaryModal/ExecutiveSummaryModal'
import TagManager from 'react-gtm-module'

type AssetHeaderProps = {
  selectedRows: string[]
  setSelectedRows: (value: string[]) => void
  addHandler: () => void
  selectedCategory: AssetCategoryType
  setSelectedCategory: (category: AssetCategoryType) => void
  isCorporateBrandsDashboard: boolean
  isCorporateCommunicationBrandsCategory: boolean
}

const AssetHeader = observer((props: AssetHeaderProps) => {
  const { assetsStore, userStore } = store
  const { userId, userName, roleId, tenantId } = userStore
  const {
    assetsList,
    isFlagCreated,
    fetchAssetsList,
    deleteAsset,
    setPaginationValues,
    setAssetsList,
    setAssetsLoading,
    corporateCommunicationBrandsView,
    updateCorporateCommunicationView,
    activeItem,
  } = assetsStore
  const {
    selectedRows,
    setSelectedRows,
    addHandler,
    selectedCategory,
    setSelectedCategory,
    isCorporateBrandsDashboard = false,
    isCorporateCommunicationBrandsCategory = false,
  } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openExecutiveSummaryModal, setOpenExecutiveSummaryModal] = useState<boolean>(false)

  const canCreateAssets = usePermissions('assets') === 'create'

  const activeHandler = async (checked: boolean) => {
    try {
      setAssetsLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: selectedRows, status: checked ? 'activate' : 'deactivate' })
      const requestParams: AssetListParamsType = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
        is_asset: true,
      }
      if (selectedCategory.category_id !== '0') requestParams.q = `category_id:eq:${selectedCategory.category_id}`

      setPaginationValues({ page: 1, pageSize: 10 })
      setAssetsList({ total_count: 0, items: [] })
      setSelectedRows([])
      fetchAssetsList({ params: requestParams })
      setAssetsLoading(false)
    } catch (error: any) {
      setAssetsLoading(false)
      displayApiError(error)
    }
    // toggle asset Google Analytics event
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggle_asset',
        //@ts-ignore
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
        entityId: selectedRows,
        status: checked ? 'activate' : 'deactivate',
      },
    })
  }

  useEffect(() => {
    if (isFlagCreated) {
      setSearchValue('')
      setPrevSearchValue('')
    }
  }, [isFlagCreated])

  const searchHandler = (value: string) => {
    if (value || prevSearchValue) {
      setPrevSearchValue(value)
      let requestParams: AssetListParamsType = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
        is_asset: true,
      }
      if (value) {
        requestParams['q'] = requestParams['q'] ? `${requestParams['q']},name:like:${value}` : `name:like:${value}`
      }
      if (selectedCategory?.category_id !== '0') {
        requestParams['q'] = requestParams['q']
          ? `${requestParams['q']},category_id:eq:${selectedCategory?.category_id}`
          : `category_id:eq:${selectedCategory?.category_id}`
      }
      setPaginationValues({ page: 1, pageSize: 10 })
      fetchAssetsList({ params: requestParams })
    }
  }

  const deleteHandler = () => {
    deleteAsset({ id: selectedRows[0] }).then((res: any | 'error') => {
      if (res !== 'error') {
        setSelectedRows([])
        setOpenDeleteModal(false)
        const requestParams: AssetListParamsType = {
          page: 1,
          per_page: 10,
          sort: 'name:asc',
          is_asset: true,
        }
        if (selectedCategory.category_id !== '0') requestParams.q = `category_id:eq:${selectedCategory.category_id}`

        setPaginationValues({ page: 1, pageSize: 10 })
        fetchAssetsList({ params: requestParams })
      }
    })
  }

  const selectedItemStatus = () => {
    return assetsList.items.find((item) => item.id === selectedRows[0])?.is_active || false
  }
  return (
    <div className='assets_header_container'>
      <AssetCategories
        searchValue={searchValue}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className='ahc_inner_container'>
        {isCorporateBrandsDashboard && (
          <div className='ahc_search_container'>
            <h4 className='ahc_corportate_comm_title'>Brands Dashboard</h4>
            <ButtonImage
              icon={ExecutiveSummary}
              onClick={() => setOpenExecutiveSummaryModal(true)}
              className='ahc_sc_button ahc_sc_exec_button'>
              Executive summary
            </ButtonImage>
          </div>
        )}
        {!isCorporateBrandsDashboard && (
          <div className='ahc_search_container'>
            <SearchInput
              className='ahc_sc_input_container'
              placeholder='Search'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={() => {
                searchHandler(searchValue)
              }}
              onClear={() => {
                setSearchValue('')
                searchHandler('')
              }}
            />
            {canCreateAssets && (
              <ButtonImage icon={AddIcon} onClick={addHandler} className='ahc_sc_button'>
                Add new {selectedCategory?.name === 'All Assets' ? 'asset' : selectedCategory?.name?.toLowerCase()}
              </ButtonImage>
            )}
          </div>
        )}
        {selectedRows.length ? (
          <div className='ahc_ic_action_buttons'>
            <ButtonImage icon={DeleteIcon} onClick={() => setOpenDeleteModal(true)} className='ahc_ic_ab_button'>
              Delete
            </ButtonImage>
            <ButtonImage icon={ShareIcon} onClick={() => {}} className='ahc_ic_ab_button' disabled={true}>
              Share
            </ButtonImage>
            <ButtonImage onClick={() => {}} className='ahc_ic_ab_button'>
              Turn on/off{' '}
              <Switch className='ahc_ic_ab_b_switch' onChange={activeHandler} checked={selectedItemStatus()} />
            </ButtonImage>
            <ButtonImage
              icon={CloseIcon}
              onClick={() => {
                setSelectedRows([])
              }}
              className='ahc_ic_ab_button'>
              Cancel
            </ButtonImage>
          </div>
        ) : null}
        <div className='ahc_filter_container'>
          <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'assets'} />
          {isCorporateCommunicationBrandsCategory && (
            <Radio.Group
              value={corporateCommunicationBrandsView}
              className='radio_group_view'
              onChange={(e) => updateCorporateCommunicationView(e.target.value)}>
              <Radio.Button value='list'>
                <div className='radio_group_icon_wrapper'>
                  <ListIcon />
                </div>
              </Radio.Button>
              <Radio.Button value='dashboard'>
                <div className='radio_group_icon_wrapper'>
                  <DashboardIcon />
                </div>
              </Radio.Button>
            </Radio.Group>
          )}
        </div>
      </div>
      {openDeleteModal && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete selected Assets?'
          onClose={() => setOpenDeleteModal(false)}
          onContinue={deleteHandler}
        />
      )}
      {openExecutiveSummaryModal && (
        <ExecutiveSummaryModal
          setOpenExecutiveSummaryModal={setOpenExecutiveSummaryModal}
          // @ts-ignore
          selectedBrand={{ ...activeItem, ...selectedCategory }}
        />
      )}
    </div>
  )
})

export default AssetHeader
