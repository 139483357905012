import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Layout } from '@pendulumfn/pendulum-landscape'
import styles from './LandingPage.module.scss'
import './LandingPage.scss'
import classNames from 'classnames'
import {
  getImpressionsPlatforms,
  getImpressionsSentiment,
  getPlatformDistribution,
  getPostPlatforms,
  getPostSentiment,
} from 'api/pendulum3DAPI/3dLandscapeData'
import { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { store } from 'store'
import { Unknown } from 'version2/utils/commonTypes'

// Define the Props type
interface Props {
  landscapeData: Unknown
  resetMapFilters: Unknown
  isLoadingSummary: Boolean
  getExecutiveSummary: Unknown
  getTrendsPosts: Unknown
  getTrendsImpression: Unknown
  getPostSentiment: Unknown
  getImpressionsSentiment: Unknown
  getPostPlatforms: Unknown
  getImpressionsPlatforms: Unknown
  getPlatformDistribution: Unknown
  searchData: Unknown
  getAllMaps: Unknown
  getCreateAllMaps: Unknown
  getNarrativeCrud: Unknown
  getNarrativeSearchCrud: Unknown
  getFlagsCrud: Unknown
  getFlagsSearchCrud: Unknown
  getAssetsCrud: Unknown
  getAssetsSearchCrud: Unknown
  getDeleteCrud: Unknown
  mapCrudView: Unknown
  setMapCrudView: Unknown
  mapValues: Unknown
  getShareUserCrud: Unknown
  startDate: Unknown
  endDate: Unknown
  getShareUserData: Unknown
  getShareUserSearch: Unknown
  getSharePostUserData: Unknown
  mapValue: Unknown
  getLandscapeEditMaps: Unknown
  getWatchListCrud: Unknown
  getWatchListSearchCrud: Unknown
  getUpdateEditMap: Unknown
}

export const LandingPage = observer(
  ({
    landscapeData,
    getExecutiveSummary,
    getTrendsPosts,
    resetMapFilters,
    getTrendsImpression,
    getAllMaps,
    searchData,
    getCreateAllMaps,
    getNarrativeCrud,
    getNarrativeSearchCrud,
    getFlagsCrud,
    getFlagsSearchCrud,
    getAssetsCrud,
    getAssetsSearchCrud,
    getDeleteCrud,
    getShareUserCrud,
    getShareUserData,
    getSharePostUserData,
    getLandscapeEditMaps,
    getShareUserSearch,
    setMapCrudView,
    mapCrudView,
    mapValues,
    startDate,
    endDate,
    mapValue,
    getWatchListCrud,
    getWatchListSearchCrud,
    getUpdateEditMap,
  }: Props) => {
    const navigate = useNavigate()
    const [showTooltip, setShowTooltip] = useState(false)

    const { anticipatoryIntelligenceStore } = store
    const { setActiveThreatNarrative, activeThreatNarrative } = anticipatoryIntelligenceStore

    const onClickFullPage = (data: Unknown, CombinedSKU: Unknown, isFocusedClicked: Unknown) => {
      const modifiedConditions = data?.query_condition?.conditions?.map((item: Unknown) => {
        if (item.hasOwnProperty('narratives')) return { narratives: [Number(item?.narratives)] }
        return item
      })

      const conditions = isFocusedClicked ? [...modifiedConditions, CombinedSKU?.CombinedSKU] : modifiedConditions

      const name = isFocusedClicked ? `${CombinedSKU?.CombinedPeaks} ∞ ${data?.name}` : data?.name

      const state = {
        name: name,
        conditions: conditions,
        mode: '',
      }
      setActiveThreatNarrative({ ...activeThreatNarrative, conditions: state.conditions })
      navigate('details', { state: JSON.parse(JSON.stringify(state)) })
    }

    useEffect(() => {
      const main3DChart = document.querySelector('.MuiGrid-root')
      const lastChild = main3DChart?.lastChild

      if (lastChild instanceof Element) {
        lastChild.addEventListener('mouseenter', () => setShowTooltip(true))
        lastChild.addEventListener('mouseleave', () => setShowTooltip(false))

        return () => {
          lastChild.removeEventListener('mouseenter', () => setShowTooltip(true))
          lastChild.removeEventListener('mouseleave', () => setShowTooltip(false))
        }
      }
    }, [])

    return (
      <div className={styles.three_js_landing_page}>
        {showTooltip && (
          <Tooltip title='See Details' placement='right' defaultOpen>
            <div style={{ position: 'absolute', bottom: '60px', left: '227px', width: '1px', height: '1px' }} />
          </Tooltip>
        )}
        <div className={classNames(styles.map_container)}>
          <Layout
            resetMapFilters={resetMapFilters}
            landscapeData={landscapeData}
            getExecutiveSummary={getExecutiveSummary}
            getAllMaps={getAllMaps}
            getCreateAllMaps={getCreateAllMaps}
            getTrendsPosts={getTrendsPosts}
            getTrendsImpression={getTrendsImpression}
            getPostSentiment={getPostSentiment}
            getImpressionsSentiment={getImpressionsSentiment}
            getPostPlatforms={getPostPlatforms}
            getImpressionsPlatforms={getImpressionsPlatforms}
            getPlatformDistribution={getPlatformDistribution}
            searchData={searchData}
            onClickFullPage={onClickFullPage}
            getNarrativeCrud={getNarrativeCrud}
            getNarrativeSearchCrud={getNarrativeSearchCrud}
            getFlagsCrud={getFlagsCrud}
            getFlagsSearchCrud={getFlagsSearchCrud}
            getAssetsCrud={getAssetsCrud}
            getAssetsSearchCrud={getAssetsSearchCrud}
            getDeleteCrud={getDeleteCrud}
            getShareUserCrud={getShareUserCrud}
            getShareUserData={getShareUserData}
            getSharePostUserData={getSharePostUserData}
            getLandscapeEditMaps={getLandscapeEditMaps}
            getShareUserSearch={getShareUserSearch}
            setMapCrudView={setMapCrudView}
            mapCrudView={mapCrudView}
            mapValues={mapValues}
            startDate={startDate}
            endDate={endDate}
            mapValue={mapValue}
            getWatchListCrud={getWatchListCrud}
            getWatchListSearchCrud={getWatchListSearchCrud}
            getUpdateEditMap={getUpdateEditMap}
          />
        </div>
      </div>
    )
  },
)
