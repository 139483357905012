import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import InfluencerCard from 'version2/components/Asset/BrandsDashboard/components/InfluencerCard/InfluencerCard'
import './InfluencersList.scss'

const InfluencersList = () => {
  const {
    powerInsightsData: { watchlistData },
  } = store.assetsStore
  const { isLoadingWatchlist, isLoadingFeed } = store.loaderStore

  const maxMetricValue = watchlistData?.completeData
    ? watchlistData.completeData?.reduce((max, obj) => {
        return obj?.metric_value > max ? obj?.metric_value : max
      }, 0)
    : 0

  return (
    <Spin spinning={isLoadingWatchlist || isLoadingFeed}>
      <span className='monitor-assets__graph__title'>Influencers</span>
      <div className='influencer-list'>
        {!isLoadingWatchlist && watchlistData?.completeData.length === 0 && <span>No data available</span>}
        {watchlistData?.completeData?.map((influencer) => (
          <InfluencerCard
            maxValue={maxMetricValue}
            value={influencer.metric_value}
            name={influencer.entity_name}
            creatorCount={influencer.total_channels}
            key={influencer.entity_name}
          />
        ))}
      </div>
    </Spin>
  )
}

export default observer(InfluencersList)
