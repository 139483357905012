import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import Table, { IColumnType } from 'version2/components/common/Table/Table'
import FlagPosts from 'version2/components/MonitorVersion2/FlagPosts/FlagPosts'
import AllPostSection from 'version2/components/MonitorVersion2/AllPostSection/AllPostSection'
import ExpandListChunk from 'version2/components/MonitorVersion2/ExpandListChunk/ExpandListChunk'

import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'

import { SubStore } from 'types/types'
import { GenericDispatch } from 'version2/utils/commonTypes'
import { IListItem, ListSelectedRowData } from 'models/models'
import { convertMobXProxiesToJSObject } from 'version2/utils/helper'
import { PaginationValuesType, SelectedTabType } from 'store/monitorVersion2/types'

import { store } from 'store'

import './BookmarkListViewSection.scss'

type BookmarkListViewSectionPropsTypes = {
  subStore: SubStore
  listData: IListItem[]
  selectedRows?: string[]
  page: PaginationValuesType
  selectedTab: SelectedTabType
  setSelectedRows?: GenericDispatch<string[]>
  setSelectedRowsData?: GenericDispatch<ListSelectedRowData[]>
}

const BookmarkListViewSection = observer((props: BookmarkListViewSectionPropsTypes) => {
  const navigate = useNavigate()
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([])

  const { selectedRows, setSelectedRows, listData, selectedTab, page, subStore, setSelectedRowsData } = props

  const { tenantsStore, bookmarkStore, mainStore } = store
  const { snippetsFilter } = bookmarkStore
  const { setSnippetsFilter } = mainStore
  const { isCorporateCommunicationsTheme } = tenantsStore

  const expandedRowsHandler = (item: IListItem) => {
    setExpandedRows((prevState) =>
      prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [...prevState, item.id],
    )
  }

  const expandedRowsCondition = (item: IListItem) => {
    return expandedRows.includes(item.id)
  }

  useEffect(() => {
    setExpandedRows([])
    setSelectedRows?.([])
    setSelectedRowsData?.([])
  }, [selectedTab, page.page])

  const columns: IColumnType<IListItem>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: () => null,
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <Tooltip placement='bottom' title='Select'>
              <div
                className={`lvsc_th_checkbox_container${
                  selectedRows?.includes(id) ? ' lvsc_th_checkbox_container_selected' : ''
                }`}
                aria-label='Select'
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRows?.([id])
                  setSelectedRowsData?.([
                    {
                      name: item.name,
                      description: item.description,
                      conditions: item.conditions,
                      mode: item?.mode || 'bookmark',
                      id: item.id,
                      permission_level: item.permissionLevel || '',
                      alert_id: item.alertId || '',
                      ownerId: item.ownerId || '',
                    },
                  ])
                }}>
                {selectedRows?.includes(id) && <CheckIcon aria-label='Select' />}
              </div>
            </Tooltip>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        width: 485,
        render: (column, item) => {
          const { name } = item

          return (
            <div className='lvsc_name_container'>
              <span className='lvsc_nc_text'>{name}</span>
            </div>
          )
        },
      },
      {
        key: 'all_posts',
        title: 'All Posts',
        width: 150,
        render: (column, item) => {
          return <AllPostSection itemData={item} subStore={subStore} isBookMarksListing />
        },
      },
      {
        key: 'flag_posts',
        title: 'Flagged Posts',
        width: 150,
        render: (column, item) => {
          return <FlagPosts itemData={item} subStore={subStore} isBookMarksListing />
        },
      },
      {
        key: 'assets_posts',
        title: 'Assets Posts',
        width: 150,
        render: (column, item) => {
          return <FlagPosts itemData={item} isAssetsFlags={true} subStore={subStore} isBookMarksListing />
        },
      },
    ]
  }, [listData, selectedRows, selectedTab, page.page, isCorporateCommunicationsTheme])

  const renderRowSubComponent = useCallback((records: IListItem) => {
    console.log('records', convertMobXProxiesToJSObject(records))
    return <ExpandListChunk data={records as IListItem} subStore={subStore} isBookMarksListing={true} />
  }, [])

  const rowClickHandler = (item: IListItem) => {
    const state = {
      id: item?.id,
      name: item.name,
      mode: item.mode,
      tabName: selectedTab,
      ownerId: item.ownerId,
      pageNumber: page.page,
      alert_id: item.alertId,
      pageSize: page.pageSize,
      conditions: item.conditions,
      permission_level: item.permissionLevel,
    }
    setSnippetsFilter(snippetsFilter)
    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  return (
    <div className='list_view_section_container'>
      <Table
        columns={columns}
        data={listData || []}
        className='lvsc_table'
        onRowClick={rowClickHandler}
        expandable={{
          expandedRowRender: renderRowSubComponent,
          setExpandedRows: expandedRowsHandler,
          expandedRowsCondition: expandedRowsCondition,
          rowExpandable: (records) => records.id !== 'a32a42df-888e-4b30-af03-a8b0851a8f59',
        }}
      />
    </div>
  )
})
export default memo(BookmarkListViewSection)
