import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { useEffect, useState } from 'react'
import { Spin } from 'antd'

import { ReactComponent as BackIcon } from 'assets/images/icons/monitor/arrow-left-icon.svg'
import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'

import { DetailsObjType } from 'store/monitorVersion2/types'
import { SubStore } from 'types/types'
import { CreatorsList } from '../CreatorsList/CreatorsList'
import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'
import { ICreatorData } from 'models/models'
import { GenericDispatch } from 'version2/utils/commonTypes'
import { API } from 'api/api'
import { PLATFORM_ICONS_URLS } from 'settings/settings'

import './CreatorDetailsPage.scss'

type CreatorInfo = {
  channel_id: string
  channel_title: string
  channel_url: string
  channel_thumb_url: string
  channel_type: string
  platform: string
  communities: any
  followers_count: number
  following_count: number
  view_count: number
  updated: any
  channel_name: string
  user_name: string
  channel_about: string
  related_profiles: { url: string; platform: string | null }[]
}

type SimilarCreator = {
  channel_id: string
  channel_title: string
  channel_name: string
  channel_url: string
  channel_thumb_url: string
  channel_type: string
  view_count: number
  platform: string
  followers_count: number
  following_count: number
  score: string
}

type WatchlistInfo = {
  id: string
  name: string
  description: string
  tenant_id: string
  owner_id: string
  channels: {
    platform: string
    channel_id: string
    channel_url: string
    input_channel_url: string
    user_input_url: string
    channel_thumb_url: string
  }[]
  total_channels: number
  alert_id: string
  created: string
  last_update_time: string | null
  last_updated_by: string | null
}

interface Props {
  subStore: SubStore
  parentDetailObj: DetailsObjType | null
  setActivePowerInsightTab: GenericDispatch<string>
}
export const CreatorDetailsPage = observer((props: Props) => {
  const { subStore, parentDetailObj, setActivePowerInsightTab } = props

  const [searchInput, setSearchInput] = useState('')
  const [creatorInfo, setCreatorInfo] = useState<CreatorInfo | null>(null)
  const [similarCreators, setSimilarCreators] = useState<{
    total_count: number
    items: ICreatorData[]
  }>({ total_count: 0, items: [] })
  const [searchedCreators, setSearchedCreators] = useState<ICreatorData[]>([])
  const [showSearchedData, setShowSearchedData] = useState<boolean>(false)
  const [creatorWatchlistData, setCreatorWatchlistData] = useState<WatchlistInfo[]>([])

  const { openedCreatorId, setOpenedCreatorId, fetchSnippetsInfo, resetSnippetFilters } = store[`${subStore}Store`]

  const { isLoadingCreators } = store['loaderStore']

  useEffect(() => {
    fetchCreator()
    fetchCreatorWatchlistsInfo()
    fetchSimilarCreators()
    resetSnippetFilters()
  }, [openedCreatorId])

  const fetchCreator = async () => {
    try {
      let { data } = await API.get({
        route: 'channel',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: openedCreatorId,
      })
      if (data?.items?.length > 0) {
        const creator = data.items[0]
        setCreatorInfo(creator)
        fetchSnippetsInfo({
          id: creator.channel_id,
          name: creator.channel_title || creator.channel_name,
          conditions: { channel_ids: [creator.channel_id] },
          mode: 'channel',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCreatorWatchlistsInfo = async () => {
    try {
      const { data } = await API.get({
        route: 'watchlist',
        customAPI: process.env.REACT_APP_API,
        customProps: { channel_id: openedCreatorId },
        isPromise: true,
      })
      if (data?.items?.length > 0) {
        const updatedData = data?.items?.map((item: any) => {
          return {
            ...item,
            channels: item.channels?.flatMap((channel: any) => channel.channel_by_platform),
          }
        })
        setCreatorWatchlistData(updatedData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSimilarCreators = async () => {
    try {
      let { data } = await API.get({
        route: 'similarChannels',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: openedCreatorId,
      })

      if (data?.items?.length > 0) {
        const creatorsData: SimilarCreator[] = data.items?.[0].similar_channels || []

        const updatedData = creatorsData.map((item) => {
          return {
            channel_id: item.channel_id,
            channel_title: item.channel_title || item.channel_name,
            channel_name: item.channel_name,
            channel_url: item.channel_url,
            channel_thumb_url: item.channel_thumb_url,
            channel_type: item.channel_type,
            platform: item.platform,
            followers_count: item.followers_count,
            view_count: item.view_count,
            snippet_count: 0,
            impression_sum: 0,
          }
        })

        setSimilarCreators({
          items: updatedData,
          total_count: updatedData.length,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const searchCreators = () => {
    if (searchInput.length > 0) {
      setShowSearchedData(true)
      const creators =
        similarCreators?.items?.filter((creator) =>
          (creator.channel_title || creator.channel_name).toLowerCase().includes(searchInput.toLowerCase()),
        ) || []
      setSearchedCreators(creators)
    } else {
      setShowSearchedData(false)
      setSearchedCreators([])
    }
  }

  const updateSimilarCreatorWatchlistInfo = (channelInfo: { channel_id: string; status: boolean }[]) => {
    const data = similarCreators
    if (data && channelInfo.length > 0) {
      channelInfo.forEach((info) => {
        let index = data?.items.findIndex((item) => item.channel_id === info.channel_id)
        if (index >= 0) {
          data.items[index].hasWatchlist = info.status
        }
      })
      setSimilarCreators(data)
    }
  }

  return (
    <Spin spinning={isLoadingCreators}>
      <div className='creator-details'>
        <button
          className='creator-details__back'
          onClick={() => {
            setActivePowerInsightTab('creators')
            setOpenedCreatorId('')
            fetchSnippetsInfo(parentDetailObj!)
          }}>
          <BackIcon />
          Back
        </button>
        <div className='creator-details__info-container'>
          <img
            className='creator-details__info-container__avatar'
            src={creatorInfo?.channel_thumb_url || Avatar}
            alt='user'
            onError={(e: any) => {
              e.target.src = Avatar
            }}
          />
          <div className='creator-details__info-container__container-0'>
            <div className='creator-details__info-container__container-1'>
              <div className='creator-details__info-container__container-2'>
                <span className='creator-details__info-container__title'>
                  {creatorInfo?.channel_title || creatorInfo?.channel_name}
                </span>
                <span className='creator-details__info-container__url'>{creatorInfo?.channel_url}</span>
              </div>

              <div className='creator-details__info-container__container-3'>
                <div className='creator-details__info-container__container-4'>
                  <span className='creator-details__info-container__following-container__count'>
                    {Number.isSafeInteger(creatorInfo?.followers_count) && Number(creatorInfo?.followers_count) >= 0
                      ? millify(Number(creatorInfo?.followers_count))
                      : 'N/A'}
                  </span>
                  <span className='creator-details__info-container__following-container__title'>Followers</span>
                </div>
                <div className='creator-details__info-container__container-4'>
                  <span className='creator-details__info-container__following-container__count'>
                    {Number.isSafeInteger(creatorInfo?.following_count) && Number(creatorInfo?.following_count) >= 0
                      ? millify(Number(creatorInfo?.following_count))
                      : 'N/A'}
                  </span>
                  <span className='creator-details__info-container__following-container__title'>Following</span>
                </div>
              </div>
            </div>

            <div className='creator-details__info-container__social_ids'>
              {creatorInfo?.related_profiles?.map((item) => (
                <div className='creator-details__info-container__social_ids__container'>
                  {item?.platform && (
                    <img
                      className='creator-details__info-container__social_ids__platform'
                      src={PLATFORM_ICONS_URLS[item.platform as keyof typeof PLATFORM_ICONS_URLS]}
                      alt='platform'></img>
                  )}
                  <span className='creator-details__info-container__social_ids__text'>{item.url}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {creatorWatchlistData.length > 0 && (
          <div className='creator-details__watchlists'>
            <span className='creator-details__watchlists__title'>Related watchlists:</span>
            <div className='creator-details__watchlists__grid'>
              {creatorWatchlistData.map((item) => (
                <div className='creator-details__watchlists__container'>
                  <div className='creator-details__watchlists__logo-container'>
                    <div className='creator-details__watchlists__logo-wrapper'>
                      {item.channels?.slice(0, 3)?.map((channel, index) => (
                        <img
                          src={channel?.channel_thumb_url || Avatar}
                          className='creator-details__watchlists__logo'
                          alt={'Logo' + index}
                          onError={(e: any) => {
                            e.target.src = Avatar
                          }}
                        />
                      ))}
                    </div>
                    {item.total_channels > 3 && (
                      <div className='creator-details__watchlists__remaining-count'>{`+${
                        item.total_channels - 3
                      }`}</div>
                    )}
                  </div>
                  <div className='creator-details__watchlists__info'>
                    <span className='creator-details__watchlists__title'>{item.name}</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
                      <circle cx='2' cy='2' r='2' fill='#D0D5DD' />
                    </svg>
                    <span className='creator-details__watchlists__count'>{`${item.total_channels} creators`}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <hr />

        <div className='creator-details__suggested-container'>
          <span className='creator-details__similar-text'>Similar creators</span>
          <SearchInput
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onPressEnter={searchCreators}
            onClear={() => {
              setShowSearchedData(false)
              setSearchInput('')
              setSearchedCreators([])
            }}
            className='creator-details__search__container'
            placeholder={'Search'}
            iconClassName={'creator-details__search__icon'}
            prefix={SearchIcon}
            showIcon={true}
          />
          <CreatorsList
            data={similarCreators}
            subStore={subStore}
            showSearchedData={showSearchedData}
            searchedCreator={searchedCreators || []}
            isLoadingPICreators={false}
            showSelectionCheckBoxes={false}
            isSimilarCreators={true}
            updateSimilarCreatorWatchlistInfo={updateSimilarCreatorWatchlistInfo}
          />
        </div>
      </div>
    </Spin>
  )
})
