import { UtilService } from 'services/Util/Util'
import { Unknown } from './commonTypes'
import { Auth } from 'aws-amplify'
import dayjs, { ManipulateType } from 'dayjs'

export const displayApiError = (e: any) => {
  const response = e.response
  UtilService.openError({
    title: response?.title || '',
    requestId: response?.data?.request_id || '',
    statusCode: response?.status || 400,
    message: response?.data?.err_msg || 'An error occurred',
  })
}

export const skipRoles = (skip: string[]) => {
  const roles = [
    'ADMIN',
    'PENDULUM_ADMIN',
    'USER',
    'VIEWER',
    'SCORECARD_USER',
    'DEPLOYMENT_STRATEGIST',
    'BUILDER',
    'ANALYST',
  ]

  return roles?.filter((role) => !skip?.includes(role))
}

export const convertMobXProxiesToJSObject = (proxies: Record<string, Unknown>): Record<string, Unknown> => {
  if (proxies) return JSON.parse(JSON.stringify(proxies))
  return {}
}

export const containsHTML = (text: string): boolean => {
  const htmlPattern = /<[^>]+>/
  return htmlPattern.test(text)
}

export const extractBodyContent = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const bodyContent = doc.body.innerHTML
  return bodyContent
}

export const checkSessionValidity = async (): Promise<boolean> => {
  try {
    await Auth.currentSession()
    return true
  } catch (error) {
    console.error('Session is invalid or expired:', error)
    return false
  }
}

export const getDateRange = (
  count = 3,
  type = 'year',
): { startDate: string; endDate: string; numberOfDays: number } => {
  const TIME_FORMAT = 'YYYY-MM-DD'

  const endDate = dayjs()
  const startDate = endDate.subtract(count, type as ManipulateType)

  const numberOfDays = endDate.diff(startDate, 'day')

  return {
    startDate: startDate.format(TIME_FORMAT),
    endDate: endDate.format(TIME_FORMAT),
    numberOfDays,
  }
}
