import dayjs from 'dayjs'
import millify from 'millify'
import { debounce } from 'lodash'
import * as echarts from 'echarts'
import { Button, Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef, useState } from 'react'

import { ReactComponent as PostsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/posts.svg'
import { ReactComponent as ImpressionsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/impressions.svg'
import { ReactComponent as AllIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/all.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/sentiment.svg'
import { ReactComponent as PlatformIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/platform.svg'
import { ReactComponent as BellIcon } from 'assets/images/icons/bell.svg'

import { store } from 'store'
import { sourcePlatform } from 'models/models'
import { UtilService } from 'services/Util/Util'

import styles from './MonitorGraph.module.scss'
import { SubStore } from 'types/types'
import { Unknown } from 'version2/utils/commonTypes'

interface Props {
  subStore: SubStore
  dataLoading?: boolean
  fromBrandDashboard?: boolean
}

export const MonitorGraph = observer(({ subStore, dataLoading = false, fromBrandDashboard = false }: Props) => {
  const {
    powerInsightsData,
    snippetsFilter,
    setSnippetsFilter,
    setSnippetsTotal,
    activeItem,
    setSnippets,
    fetchSnippetsInfo,
    fetchPowerInsightsLineChartData,
  } = store[`${subStore}Store`]

  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions'>('posts')
  const [activePivot, setActivePivot] = useState<'all' | 'sentiment' | 'platform'>('all')
  const [disabledChartLines, setDisabledChartLines] = useState<
    Array<Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative'>
  >([])

  const chartRef = useRef<HTMLDivElement | null>(null)

  const postsChartPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    powerInsightsData.lineChartData.posts.platform.data_points.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }

    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [powerInsightsData.lineChartData])

  const impressionsChartPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    powerInsightsData.lineChartData.impressions.platform.data_points.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }

    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [powerInsightsData.lineChartData])

  const platformsToRender = activeMetric === 'posts' ? postsChartPlatforms : impressionsChartPlatforms

  const applyDateRangeFilterWithDebounce = debounce((startDate: string, endDate: string) => {
    const modFilter = { ...snippetsFilter }
    modFilter['days'] = {
      custom: {
        isApplied: true,
        dateRange: [startDate || '', endDate || ''] || [],
      },
      noOfDays: 0,
    }
    setSnippetsFilter(modFilter)
    setSnippets([])
    setSnippetsTotal(0)
    fetchPowerInsightsLineChartData()
    fetchSnippetsInfo(activeItem!)
  }, 1500)

  const applyDateRangeFilter = (startDate: string, endDate: string) => {
    const modFilter = { ...snippetsFilter }
    modFilter['days'] = {
      custom: {
        isApplied: true,
        dateRange: [startDate || '', endDate || ''] || [],
      },
      noOfDays: 0,
    }
    setSnippetsFilter(modFilter)
    setSnippets([])
    setSnippetsTotal(0)
    fetchPowerInsightsLineChartData()
    fetchSnippetsInfo(activeItem!)
  }

  useEffect(() => {
    // brand dashboard manages this call itself
    if (fromBrandDashboard) return
    fetchPowerInsightsLineChartData()
  }, [fromBrandDashboard])

  useEffect(() => {
    setDisabledChartLines([])
  }, [activeMetric, activePivot])

  useEffect(() => {
    if (!chartRef.current) return

    const chartInstance = echarts.init(chartRef.current)

    const commonSeries = {
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 12,
      connectNulls: true,
      emphasis: {
        focus: 'series',
      },
    }

    const series = []

    if (activePivot === 'all') {
      series.push(
        {
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) => item.metric_value),
          name: 'Metric Value',
          lineStyle: { color: '#CCA55A' },
          itemStyle: { color: '#CCA55A' },
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) =>
            item.metric_running_average?.toFixed(2),
          ),
          name: 'Running Average',
          lineStyle: { type: 'dashed', color: '#8598AD' },
          itemStyle: { color: '#8598AD' },
          symbol: 'none',
        },
      )
    } else if (activePivot === 'sentiment') {
      series.push(
        {
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) => item.Positive),
          name: 'Positive',
          lineStyle: { color: '#18B368' },
          itemStyle: { color: '#18B368' },
          hidden: disabledChartLines.includes('Positive'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) => item.Neutral),
          name: 'Neutral',
          lineStyle: { color: '#18A0FB' },
          itemStyle: { color: '#18A0FB' },
          hidden: disabledChartLines.includes('Neutral'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) => item.Negative),
          name: 'Negative',
          lineStyle: { color: '#DE4E4E' },
          itemStyle: { color: '#DE4E4E' },
          hidden: disabledChartLines.includes('Negative'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
      )
    } else if (activePivot === 'platform') {
      platformsToRender.forEach((platform) => {
        series.push({
          ...commonSeries,
          data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map(
            (item) => item[platform as keyof typeof item],
          ),
          name: UtilService.getPlatformBrandName(platform),
          lineStyle: { color: UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform)) },
          itemStyle: { color: UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform)) },
          hidden: disabledChartLines.includes(platform),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        })
      })
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: Unknown) {
          let validData = params.filter((item: Unknown) => item.data !== null && item.data !== undefined)

          if (validData.length > 0) {
            let date = params[0].axisValue
            let tooltipContent =
              `<strong>${date}</strong><br/>` +
              validData.map((item: Unknown) => `${item.marker}${item.seriesName}: ${item.data}`).join('<br/>')
            return tooltipContent
          }

          return null
        },
      },
      legend: {
        data: series.map((s) => s.name),
        // @ts-ignore
        selected: series.reduce((acc, s) => ({ ...acc, [s.name]: !s.hidden }), {}),
        orient: 'horizontal',
        left: 'left',
        bottom: 0,
        icon: 'circle',
      },
      xAxis: {
        type: 'category',
        data: powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map((item) =>
          dayjs(item.date).format('YYYY-MM-DD'),
        ),
        boundaryGap: false,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          interval: 'auto',
          formatter: (value: string, index: number) => {
            const dates = powerInsightsData.lineChartData[activeMetric][activePivot].data_points.map(
              (item) => item.date,
            )
            const startDate = dayjs(dates[0])
            const endDate = dayjs(dates[dates.length - 1])
            const daysDifference = endDate.diff(startDate, 'day')

            if (daysDifference <= 31) {
              return dayjs(value).format('DD MMM YYYY')
            } else {
              return dayjs(value).format('MMM YYYY')
            }
          },
        },
      },
      grid: {
        top: 30,
        bottom: 150,
        left: 40,
        right: 40,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          // @ts-ignore
          formatter: (value) => (Number.isFinite(value) ? millify(value || 0) : '0'),
        },
      },
      series,
      dataZoom: [
        {
          type: 'slider',
          bottom: 80,
          start: 0,
          end: 100,
          left: 100,
          right: 100,
          handleIcon: 'M8.2,13.2 L8.2,22.8 L15.8,22.8 L15.8,13.2 L8.2,13.2 Z',
          handleSize: '100%',
        },
      ],
    }

    chartInstance.setOption(option)

    chartInstance.on('dataZoom', (event: Unknown) => {
      const startIndex = Math.round(
        (event.start / 100) * (powerInsightsData.lineChartData[activeMetric][activePivot].data_points.length - 1),
      )
      const endIndex = Math.round(
        (event.end / 100) * (powerInsightsData.lineChartData[activeMetric][activePivot].data_points.length - 1),
      )

      const startDate = powerInsightsData.lineChartData[activeMetric][activePivot].data_points[startIndex]?.date
      const endDate = powerInsightsData.lineChartData[activeMetric][activePivot].data_points[endIndex]?.date
      applyDateRangeFilterWithDebounce(startDate, endDate)
    })

    chartInstance.on('click', (params) => {
      if (params.componentType === 'series') {
        const clickedDate = params.name
        applyDateRangeFilter(clickedDate, clickedDate)
      }
    })

    const handleResize = () => {
      chartInstance.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chartInstance.dispose()
    }
  }, [activeMetric, activePivot, disabledChartLines, powerInsightsData.lineChartData])

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.description}>Review longitudinal changes in the data to spot important patterns</div>
      <br />
      <Spin spinning={dataLoading} style={{ minHeight: '350px' }}>
        <div className={styles.headerWrapper}>
          <div className={styles.chartFilters}>
            <div className={styles.metric}>
              <span>Metric</span>
              <Button.Group>
                <Button
                  onClick={() => setActiveMetric('posts')}
                  className={classNames(styles.btnFilter, {
                    [styles.active]: activeMetric === 'posts',
                  })}>
                  <PostsIcon /> Posts
                </Button>

                <Button
                  onClick={() => setActiveMetric('impressions')}
                  className={classNames(styles.btnFilter, {
                    [styles.active]: activeMetric === 'impressions',
                  })}>
                  <ImpressionsIcon /> Impressions
                </Button>
              </Button.Group>
            </div>

            <div className={styles.pivot}>
              <span>Pivot</span>
              <Button.Group>
                <Button
                  onClick={() => setActivePivot('all')}
                  className={classNames(styles.btnFilter, {
                    [styles.active]: activePivot === 'all',
                  })}>
                  <AllIcon /> All
                </Button>

                <Button
                  onClick={() => setActivePivot('sentiment')}
                  className={classNames(styles.btnFilter, {
                    [styles.active]: activePivot === 'sentiment',
                  })}>
                  <SentimentIcon /> Sentiment
                </Button>

                <Button
                  onClick={() => setActivePivot('platform')}
                  className={classNames(styles.btnFilter, {
                    [styles.active]: activePivot === 'platform',
                  })}>
                  <PlatformIcon /> Platform
                </Button>
              </Button.Group>
            </div>
          </div>

          <BellIcon className={styles.bellIcon} />
        </div>

        <div ref={chartRef} style={{ width: '100%', height: '450px' }} />
      </Spin>
    </div>
  )
})
