import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import TagManager from 'react-gtm-module'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import CombineDetailsView from '../CombineDetailsView/CombineDetailsView'
import CreateWatchListForm from '../CreateWatchListForm/CreateWatchListForm'
import SuccessModal from 'version2/components/Flags/SuccessModal/SuccessModal'
import { UtilService } from 'services/Util/Util'
import { CancelBreadCrumb } from '../CreateNarrativeDetailsView/CreateNarrativeDetailsView'
import './CreateWatchlistDetailsView.scss'

const CreateWatchlistDetailsView = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { defineWatchlistStore, userStore, breadcrumbStore, tenantsStore } = store
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { formData, addFormData, getSnippetFilterPayload, resetStore, saveWatchList } = defineWatchlistStore
  const { setBreadcrumbData } = breadcrumbStore

  const [showDetailScreen, setShowDetailScreen] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

  const previousPath = location.pathname?.includes('vectors') ? '/vectors/create' : '/influencers/create'

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [])

  useEffect(() => {
    setBreadcrumbData([
      { id: UtilService.generateUuid(), title: <CancelBreadCrumb previousPath={previousPath} /> },
      {
        id: UtilService.generateUuid(),
        title: `Create new ${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}`,
      },
    ])
  }, [])

  const publish = async () => {
    try {
      const response = await saveWatchList(formData)

      if (response) {
        // google analytics publish watchlist
        const allChannelURLs = []
        for (let i = 0; i < formData.channels.length; i++) {
          const channel = formData.channels[i]
          allChannelURLs.push(...channel.channel_urls)
        }
        TagManager.dataLayer({
          dataLayer: {
            event: 'publish_watchlist',
            channels: allChannelURLs,
            entityDescription: formData.description,
            entityName: formData.name,
            entityId: formData,
            user_id: userStore.userId,
            user_name: userStore.userName,
            tenantId: userStore.tenantId,
            roleId: userStore.roleId,
          },
        })
        return setShowSuccessModal(true)
      }
    } catch (e: any) {}
  }

  return (
    <div className='create_detail_view_container'>
      {showDetailScreen ? (
        <>
          <div className='cdvc_header'>
            <div className='cdvc_h_left'>
              <ButtonImage
                className='cdvc_h_l_button'
                onClick={() => {
                  setOpenEditModal(true)
                }}>
                Edit {changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}
              </ButtonImage>
              {/* <div className='cdvc_h_l_info_container'>
                <WarningIcon />
                <p className='cdvc_h_l_ic_text'>
                  Your search results in too many items to be useful. Consider refining your keywords.
                </p>
              </div> */}
            </div>
            <ButtonImage className='cdvc_h_l_button' onClick={publish}>
              Save {changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}
            </ButtonImage>
          </div>
          <CombineDetailsView
            isWatchListCreateMode
            className='cdvc_detail_view_container'
            pageType={'details'}
            page={'monitor'}
            subStore={'vectors'}
          />
          {openEditModal && (
            <div className='cdvc_narrative_form'>
              <CreateWatchListForm
                formData={formData}
                addFormData={addFormData}
                onEditMode={true}
                onSearchClick={async () => {
                  const conditions = await getSnippetFilterPayload()
                  const modifiedConditions: any = {}
                  conditions.conditions.forEach((item: any) => {
                    const key = Object.keys(item)[0]
                    modifiedConditions[key] = item[key]
                  })
                  const state = {
                    name: 'Power Insights',
                    conditions: modifiedConditions,
                    mode: 'watchlist',
                    tabName: location.state?.tabName,
                    comingFrom: location.pathname,
                    pageSize: location.state?.pageSize,
                    pageNumber: location.state?.pageNumber,
                  }

                  navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
                }}
                onBackClick={() => {
                  setOpenEditModal(false)
                }}
              />
            </div>
          )}
        </>
      ) : (
        <CreateWatchListForm
          formData={formData}
          addFormData={addFormData}
          onSearchClick={async () => {
            const conditions = await getSnippetFilterPayload()
            const modifiedConditions: any = {}

            conditions.conditions.forEach((item: any) => {
              const key = Object.keys(item)[0]
              modifiedConditions[key] = item[key]
            })
            const state = {
              name: 'Power Insights',
              conditions: modifiedConditions,
              mode: 'watchlist',
              tabName: location.state?.tabName,
              comingFrom: location.pathname,
              pageSize: location.state?.pageSize,
              pageNumber: location.state?.pageNumber,
            }

            navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
            setShowDetailScreen(true)
          }}
          onBackClick={() => {
            navigate('../', { replace: true })
          }}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          title='Request sent successfully'
          desc='You have successfully submitted your request. Once the request is approved, the selected item will be added to your list.'
          onClose={() => {
            setShowSuccessModal(false)
            const previousPath = location.pathname?.includes('vectors') ? '/vectors' : '/influencers'
            const state = {
              tabName: location.state?.tabName,
              comingFrom: location.pathname,
              pageSize: location.state?.pageSize,
              pageNumber: location.state?.pageNumber,
            }
            navigate(previousPath, { state: JSON.parse(JSON.stringify(state)) })
          }}
        />
      )}
    </div>
  )
})

export default CreateWatchlistDetailsView
