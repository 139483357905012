import { useEffect, useState } from 'react'
import { Button, Modal, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as SubscribeIcon } from 'assets/images/alert-icon.svg'
import { ReactComponent as CollapseIcon } from 'assets/images/icons/dashboard/collapse-icon.svg'
import { ReactComponent as ArrowRight } from 'assets/images/icons/dashboard/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'assets/images/icons/dashboard/arror-left.svg'
import ExecutiveSummarySubscriptionModal from './ExecutiveSummarySubscriptionModal'
import { powerInsightsAPI } from 'api/api'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { MonitorSnippetItem } from 'version2/components/MonitorVersion2/SnippetListVersion2/MonitorSnippetItem/SnippetItem'
import { useEffectOnce } from 'react-use'
import './DailyExecutiveSummary.scss'

type PropsType = {
  onClose: () => void
  showSubscriptionOption?: boolean
  subStore?: 'assets' | 'vectors'
}

const TIME_FORMAT = 'YYYY-MM-DD'
const TODAY = dayjs().format(TIME_FORMAT)

const DailyExecutiveSummary = (props: PropsType) => {
  const [isOpenSubscribeModal, setIsOpenSubscribeModal] = useState(false)
  const [dailyExecutiveSummary, setDailyExecutiveSummary] = useState<{ [key: number]: any[] }>({})
  const [isLoadingExecutiveSummary, setIsLoadingExecutiveSummary] = useState(false)
  const [summaryFetchingError, setSummaryFetchingError] = useState(false)
  const [selectedSummarySectionIndex, setSelectedSummarySectionIndex] = useState<number | undefined>()
  const [summaryVersion, setSummaryVersion] = useState(0)

  const { onClose, showSubscriptionOption = true, subStore = 'assets' } = props

  const {
    getSnippetFilterPayload,
    showSurprise,
    watchlists,
    communities,
    setSnippetsForBrandDailyExecutiveSummary,
    snippetsWithSurprises,
    setSnippets,
  } = store[`${subStore}Store`]

  const fetchDailyExecutiveSummary = async (version: number) => {
    try {
      setIsLoadingExecutiveSummary(true)
      const payload = await getSnippetFilterPayload()
      const startDate = dayjs(TODAY).subtract(1, 'days').format(TIME_FORMAT)
      payload.query.date = `start_date:gte:${startDate},end_date:lte:${TODAY}`

      const summaryVersion = version === 0 ? null : version
      const { data } = await powerInsightsAPI.summary.getSummaryV2(payload, false, summaryVersion)
      if (!data.response_code) {
        setDailyExecutiveSummary({ ...dailyExecutiveSummary, [version]: data.sections })
        setSelectedSummarySectionIndex(0)
        setSnippetsForBrandDailyExecutiveSummary(data.sections[0].snippets)
      }
      if (data.response_code === 'NO_DATA') {
        setDailyExecutiveSummary({ ...dailyExecutiveSummary, [version]: [] })
        setSelectedSummarySectionIndex(0)
        setSnippetsForBrandDailyExecutiveSummary([])
      }
    } catch (error) {
      setSummaryFetchingError(true)
      setSnippets([])
    } finally {
      setIsLoadingExecutiveSummary(false)
    }
  }
  useEffectOnce(() => {
    if (snippetsWithSurprises.length > 0) {
      setSnippets([])
    }
  })

  useEffect(() => {
    fetchDailyExecutiveSummary(0)
    return () => setSnippets([])
  }, [])

  const retryFetchingSummary = () => {
    setIsLoadingExecutiveSummary(true)
    setSummaryFetchingError(false)
    fetchDailyExecutiveSummary(summaryVersion)
  }

  const onSectionSelection = (currentIndex: number, snippets: any[]) => {
    if (selectedSummarySectionIndex === currentIndex) return
    setSelectedSummarySectionIndex(currentIndex)
    setSnippetsForBrandDailyExecutiveSummary(snippets)
  }

  const changeSummaryVersion = (direction: 'increment' | 'decrement') => {
    const nextVersion = direction === 'increment' ? summaryVersion + 1 : summaryVersion - 1

    if (nextVersion < 0 || nextVersion > 5) return

    setSummaryFetchingError(false)
    if (dailyExecutiveSummary[nextVersion]) {
      setSelectedSummarySectionIndex(0)
      setSnippetsForBrandDailyExecutiveSummary(dailyExecutiveSummary[nextVersion][0].snippets)
    } else {
      fetchDailyExecutiveSummary(nextVersion)
    }
    setSummaryVersion(nextVersion)
  }

  const noDataPresent =
    !isLoadingExecutiveSummary &&
    !summaryFetchingError &&
    (!dailyExecutiveSummary[summaryVersion] || dailyExecutiveSummary[summaryVersion]?.length === 0)

  return (
    <Modal open={true} className='daily-summary' footer={null} closable={false} mask={false}>
      <div>
        <div className='header'>
          <h4>
            <SummaryIcon /> Executive Summary
          </h4>
          {showSubscriptionOption && (
            <div className='subscribe-option' onClick={() => setIsOpenSubscribeModal(true)}>
              <SubscribeIcon />
            </div>
          )}
          <div className='version-changer'>
            <Button
              type='primary'
              shape='circle'
              size='small'
              icon={<ArrowLeft color='inherit' />}
              className={classNames('icon-btn', {
                'icon-btn-last': summaryVersion === 5,
              })}
              onClick={() => changeSummaryVersion('increment')}
              disabled={isLoadingExecutiveSummary}
            />
            <h5>{summaryVersion === 0 ? 'Current version' : `Version ${summaryVersion}`}</h5>
            <Button
              type='primary'
              shape='circle'
              size='small'
              icon={<ArrowRight color='inherit' />}
              className={classNames('icon-btn', {
                'icon-btn-last': summaryVersion === 0,
              })}
              onClick={() => changeSummaryVersion('decrement')}
              disabled={isLoadingExecutiveSummary}
            />
          </div>
          <div className='collapse-option' onClick={onClose}>
            <CollapseIcon />
          </div>
          {isOpenSubscribeModal && (
            <ExecutiveSummarySubscriptionModal setIsOpenSubscribeModal={setIsOpenSubscribeModal} />
          )}
        </div>
        <div className='body'>
          <div className='summary-section'>
            <div className='summary-section-header'>
              <h4>
                Key Highlights <span>{dayjs().format('MMM D, YYYY')}</span>
              </h4>
            </div>
            <div className='summary-list'>
              {!isLoadingExecutiveSummary &&
                !summaryFetchingError &&
                dailyExecutiveSummary[summaryVersion]?.map(
                  (section: { subheading: string; summary: string; snippets: any[] }, index) => (
                    <div
                      className={classNames('summary', {
                        'summary-selected': selectedSummarySectionIndex === index,
                      })}
                      key={index}
                      onClick={() => onSectionSelection(index, section.snippets)}>
                      <h4>
                        <span>{`${index + 1}. `}</span>
                        {section.subheading}
                      </h4>
                      <p>{section.summary}</p>
                    </div>
                  ),
                )}
              {isLoadingExecutiveSummary && (
                <>
                  {Array(4)
                    .fill(null)
                    .map(() => (
                      <Skeleton paragraph active className='summary-empty-state' />
                    ))}
                </>
              )}
              {!isLoadingExecutiveSummary && summaryFetchingError && (
                <div className='summary-sections-empty summary-error'>
                  <span className='summary-error-text'>Failed to load the summary. Please try again now or later.</span>
                  <button className='summary-retry-error' onClick={retryFetchingSummary}>
                    Retry
                  </button>
                </div>
              )}
              {noDataPresent && (
                <div className='summary-sections-empty'>
                  <h5>No data to show</h5>
                </div>
              )}
            </div>
          </div>
          <div className='snippets-section'>
            <div className='snippets-section-header'>
              <h4>
                Featured Snippets{' '}
                <span>
                  {snippetsWithSurprises.length > 0 &&
                    `${snippetsWithSurprises.length} ${snippetsWithSurprises.length === 1 ? 'item' : 'items'}`}
                </span>
              </h4>
            </div>
            <div className='snippets-list'>
              {!isLoadingExecutiveSummary &&
                !summaryFetchingError &&
                snippetsWithSurprises.map((item) => (
                  <div key={item.id}>
                    <MonitorSnippetItem
                      ref={null}
                      mode={'asset'}
                      subStore={'assets'}
                      snippet={item}
                      allCommunities={communities}
                      allWatchlist={watchlists}
                      showSurprise={showSurprise}
                      snippetItemClassName='snippet-item-bordered'
                    />
                  </div>
                ))}
              {isLoadingExecutiveSummary && (
                <>
                  {Array(4)
                    .fill(null)
                    .map(() => (
                      <Skeleton paragraph active className='summary-empty-state' />
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default observer(DailyExecutiveSummary)
