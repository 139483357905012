import { Col, Row } from 'antd'
import { useOutletContext } from 'react-router-dom'

import MentionViewCard from '../Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'

import { AssetListObjType } from 'store/asset/types'

import './DashboardMetrics.scss'

const DashboardMetrics = () => {
  const { selectedBrand }: { selectedBrand: AssetListObjType } = useOutletContext()

  const customGraphColors = {
    shadeColorUp: '#eeeff4',
    lineColorUp: '#A84BD3',
    shadeColorDown: '#eeeff4',
    lineColorDown: '#A84BD3',
    theme: 'purple',
  }

  return (
    <div className='dashboard-metrics'>
      <div className='dashboard-metrics__header'>
        <div className='dashboard-metrics__header__title'>Metrics</div>
        <div className='dashboard-metrics__header__description'>
          Measure and track key performance indicators across narratives to understand trends, engagement, and
          impressions over time.
        </div>
      </div>
      <Row className='dashboard-widgets__card__sub-row'>
        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
          <MentionViewCard
            selectedItem={selectedBrand}
            type='Mentions'
            key='Mentions'
            customGraphColors={customGraphColors}
            chartWrapperClassName='cards-chart-wrapper'
          />
        </Col>
        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
          <MentionViewCard
            selectedItem={selectedBrand}
            type='Impressions'
            key='Views'
            measure='impression'
            customGraphColors={customGraphColors}
            chartWrapperClassName='cards-chart-wrapper'
          />
        </Col>
        <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
          <MentionViewCard
            selectedItem={selectedBrand}
            type='Engagement'
            key='Views'
            measure='impression'
            customGraphColors={customGraphColors}
            chartWrapperClassName='cards-chart-wrapper'
          />
        </Col>
      </Row>
    </div>
  )
}

export default DashboardMetrics
