import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as KineticEnergyIcon } from 'assets/images/kinetic-energy-icon.svg'
import { ReactComponent as LegislationIcon } from 'assets/images/legislation-icon.svg'
import { ReactComponent as SocialUnrestIcon } from 'assets/images/social-unrest-icon.svg'
import { ReactComponent as TaxIcon } from 'assets/images/taxation-yellow-icon.svg'
import { ReactComponent as LegalsIcon } from 'assets/images/legal-action-icon.svg'
import { ReactComponent as BoycottIcon } from 'assets/images/boycott-icon.svg'
import { ReactComponent as BrandsIcon } from 'assets/images/brands-icon.svg'
import { ReactComponent as BoardIcon } from 'assets/images/board-icon.svg'
import { ReactComponent as IndustriesIcon } from 'assets/images/industries-icon.svg'
import { ReactComponent as SuppliersIcon } from 'assets/images/suppliers-icon.svg'
import { ReactComponent as ExecutivesIcon } from 'assets/images/executives-icon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-platform-icon.svg'
import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-grey-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-platform-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-platform-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-platform-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-grey-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-grey-icon.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-grey-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-grey-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-icon-current-color.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-icon-current-color.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-icon-current-color.svg'
import { ReactComponent as CSpanIcon } from 'assets/images/c-span-grey-icon.svg'
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg'
import { ReactComponent as BlueSky } from 'assets/images/bluesky.svg'
// import { ReactComponent as ConsiliumIcon } from 'assets/images/consilium-grey-icon.svg'
// import { ReactComponent as WebSearchIcon } from 'assets/images/web-search-grey-icon.svg'
// import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-grey-icon.svg'
// import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'
import ChanIcon from 'assets/images/4-chan-img.png'
import TruthSocialIcon from 'assets/images/truth-social-img.png'
import PatriotsIcon from 'assets/images/patriots-img.png'
import BrighteonIcon from 'assets/images/brighteon.png'

import YoutubeIconURL from 'assets/images/youtube-new-icon.svg'
import BitchuteIconURL from 'assets/images/bitchute-icon.svg'
import RumbleIconURL from 'assets/images/rumble-icon.svg'
import PodcastIconURL from 'assets/images/podcast-icon.svg'
import TikTokIconURL from 'assets/images/tiktok-icon.svg'
import NewsIconURL from 'assets/images/news-icon.svg'
import TwitterIconURL from 'assets/images/twitter-x-new-logo.svg'
import RedditIconURL from 'assets/images/reddit-icon.svg'
import TruthSocialIconURL from 'assets/images/truth-social-grey-icon.svg'
import ChanIconURL from 'assets/images/4chan-icon.svg'
import GabIconURL from 'assets/images/gab-icon.svg'
import PatriotsIconURL from 'assets/images/patriots-grey-icon.svg'
import VkIconURL from 'assets/images/vk-icon-current-color.svg'
import InstagramIconURL from 'assets/images/instagram-logo.svg'
import ThreadsIconURL from 'assets/images/threads-logo.svg'
import TelegramIconURL from 'assets/images/telegram-logo.svg'
import CSpanIconURL from 'assets/images/cspan-black-icon.svg'
import BrighteonIconURL from 'assets/images/brighteon-logo-icon.svg'

import { ISnippet, sourcePlatform } from 'models/models'

export const ROUTES = {
  tag: 'tag',
  community: 'community',
  narrative: 'narrative',
  watchlist: '/watchlist',
  spotlight: '/spotlight',
  bookmark: 'bookmark',
  reportWatchlist: 'watchlist',
  reportNarrative: 'narrative',
  reportBookmark: 'bookmark',
  reportTag: 'tag',
  reports: 'reports',
  reportsShare: 'share',
  reportsFeeds: 'feeds',
  newNarrativeMatchingResults: 'getNumMatchingResults',
  newNarrativePublish: '/narrative/publish',
  newNarrativeSave: '/narrative/save',
  newNarrativeSnippetsSearch: 'snippets/search',
  narrativeCount: 'insights/metrics',
  narrativeGrowthForecast: 'forecast/narratives',
  folder: 'folder',
  getVideoData: 'getVideo',
  evaluation: 'evaluate',

  takeABreak: 'take-a-break',

  channel: 'channel',
  similarChannels: 'similar-channels',

  listUsers: 'user',
  roles: 'getAvailableRoles',
  user: 'user',

  statistics: 'stats',
  beeSwarmChart: 'stats/bee-swarm',
  powerInsightsLineChart: 'stats/linechart',
  powerInsightsTreeMap: 'stats/treemap',
  powerInsightsPeople: 'stats/people',
  powerInsightsPolitician: 'stats/politician',
  powerInsightsCompanies: 'stats/company',
  powerInsightsCountries: 'stats/country',
  powerInsightsSummary: 'stats/summary',
  powerInsightsSummaryV2: 'v2/summarize',
  powerInsightsLanguages: 'stats/language',
  powerInsightsCreators: 'channel/feeds-search',

  twitterSearch: 'proxy/search/history/twitter',
  newsSearch: 'proxy/search/history/news',

  personnel: 'personnel',

  executiveFlags: 'insights/flags',
  executiveMetrics: 'insights/metrics',

  personnelFlags: 'insights/flags',
  personnelMetrics: 'insights/metrics',

  threatTopics: 'scorecards/threat-topics',
  risks: 'scorecards/risks',
  electionIssues: '/scorecards/elections',
  electionRaces: '/scorecards/elections/races',
  scorecardsAttentionFlags: 'custom-insights/scorecard',
  flagsCategories: 'custom-insights/categories',
  flagsList: 'custom-insights',
  flagsStatus: 'custom-insights/status',
  searchPayload: 'custom-insights/search-payload',
  topicsChartData: 'stats/topics',
  platformChartData: 'stats/platform',
  summary: 'summarize',

  landscapeData: '/scorecards/landscape',
  summarize: 'summarize',
  mapsLandscapeData: 'scorecards/landscape/maps',

  snippetFeedback: 'snippets/feedback',

  tenant: '/tenant',
  tenantConfiguration: '/tenant/configuration',
  attentionFlags: '/custom-insights/',

  copilot: 'copilot',
  copilotTranslate: 'copilot/translate',

  feedExport: '/export',
  whiteboardById: (id: string) => `/whiteboard/${id}`,
  whiteboard: '/whiteboard',

  tosAgreementStatus: 'user/verify',
  tosVerifyAgreement: 'user/tos/accept',
}

export const PAGINATION_SINGLE_PAGE = {
  pageSize: 10,
  current: 1,
  total: 0,
  totalMillify: '0',
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 15, 20],
}

export const STATIC_ROLES = {
  admin: 'ADMIN',
  pendulum_admin: 'PENDULUM_ADMIN',
  user: 'USER',
  deployment_strategist: 'DEPLOYMENT_STRATEGIST',
  builder: 'BUILDER',
  viewer: 'VIEWER',
  analyst: 'ANALYST',
}

export const MONITOR_SNIPPET_SORT_LABEL = {
  TOTAL_IMPRESSIONS: 'Total Impressions',
  RISK_SCORE: 'Risk Score',
  UPLOAD_DATE: 'Upload Date',
}

export const MONITOR_SNIPPET_SORT_VALUE = {
  NEW_IMPRESSIONS: 'impression_diff_count',
  TOTAL_IMPRESSIONS: 'impression_count',
  RISK_SCORE: 'risk_score',
  UPLOAD_DATE: 'upload_date',
}

// should be done be done with a util function
export const MONITOR_SNIPPET_SORT_VALUE_BY_LABEL = {
  [MONITOR_SNIPPET_SORT_LABEL.TOTAL_IMPRESSIONS]: MONITOR_SNIPPET_SORT_VALUE.TOTAL_IMPRESSIONS,
  [MONITOR_SNIPPET_SORT_LABEL.RISK_SCORE]: MONITOR_SNIPPET_SORT_VALUE.RISK_SCORE,
  [MONITOR_SNIPPET_SORT_LABEL.UPLOAD_DATE]: MONITOR_SNIPPET_SORT_VALUE.UPLOAD_DATE,
}

export const MONITOR_SNIPPET_MODE_LABEL = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
}

export const MONITOR_SNIPPET_MODE_VALUE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
}

export const MONITOR_SNIPPET_MODE_VALYE_BY_LABEL = {
  [MONITOR_SNIPPET_MODE_LABEL.ASCENDING]: MONITOR_SNIPPET_MODE_VALUE.ASCENDING,
  [MONITOR_SNIPPET_MODE_LABEL.DESCENDING]: MONITOR_SNIPPET_MODE_VALUE.DESCENDING,
}

export const MONITOR_POWER_INSIGHT_SORT_LABEL = {
  METRICS: 'Metrics',
  ALPHABETICALLY: 'Alphabetically',
}

export const MONITOR_POWER_INSIGHT_SORT_VALUE = {
  METRICS: 'metrics',
  ALPHABETICALLY: 'alphabetically',
}

export const MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL = {
  [MONITOR_POWER_INSIGHT_SORT_LABEL.METRICS]: MONITOR_POWER_INSIGHT_SORT_VALUE.METRICS,
  [MONITOR_POWER_INSIGHT_SORT_LABEL.ALPHABETICALLY]: MONITOR_POWER_INSIGHT_SORT_VALUE.ALPHABETICALLY,
}

export const MONITOR_POWER_INSIGHT_MODE_LABEL = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
}

export const MONITOR_POWER_INSIGHT_MODE_VALUE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
}

export const MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL = {
  [MONITOR_POWER_INSIGHT_MODE_LABEL.ASCENDING]: MONITOR_POWER_INSIGHT_MODE_VALUE.ASCENDING,
  [MONITOR_POWER_INSIGHT_MODE_LABEL.DESCENDING]: MONITOR_POWER_INSIGHT_MODE_VALUE.DESCENDING,
}

export const SurpriseSnippet: ISnippet = {
  type: 'surprise' as const,
  id: uuidv4(),
  snippet_text: '',
  title: '',
  views: 0,
  viewsMilify: '0',
  views_diff: 0,
  views_diff_milify: '0',
  date: '',
  channelId: '',
  author: {
    channel_title: '',
    channel_name: '',
    channel_thumb_url: '',
    followers_count: 0,
    view_count: 0,
    communities: [],
    channel_url: '',
  },
  playSourceLink: '',
  openSourceLink: '',
  sourcePlatform: 'Surprise' as const,
  riskScore: 0,
  duration: 0,
  offset: 0,
  sentiment: 'Neutral' as const,
  postUrl: '',
  feedback: 'none' as const,
  translatedSnippet: {
    translated_text: '',
    original_lang: '',
  },
  source: 'description',
}

export const LANGUAGE_METRICS_DIMENSIONS = Object.freeze([
  'toxic_language',
  'obscene_language',
  'hate_language',
  'negative_sentiment',
  'threat_language',
  'risk_score',
])

export const EMPTY_GRAPH_DATA_POINTS = [
  { metric_value: 0 },
  { metric_value: 50 },
  { metric_value: 40 },
  { metric_value: 99 },
  { metric_value: 80 },
  { metric_value: 90 },
  { metric_value: 70 },
  { metric_value: 90 },
  { metric_value: 80 },
  { metric_value: 20 },
]

export const EXECUTIVE_DEFAULT_FILTER = Object.freeze({
  days: {
    custom: {
      isApplied: false,
      dateRange: [],
    },
    noOfDays: 90,
  },
  sort: 'Upload Date',
  sort_mode: 'Descending',
  filter_risk: [0, 100] as [number, number],
  filter_sentiment: [] as string[],
})

export const DASHBOARD_CONST = {
  LISTS: {
    NARRATIVES: {
      HEADING: 'Narratives',
      NEW_SUB_HEADING: {
        1: 'Use narratives to express risk vectors important to you, assess impact & monitor how they progress',
        2: '',
        // 1: 'Cross-platform collection of content that represents a topic of interest.',
        // 2: 'Use narratives to express risk vectors important to you, assess impact & monitor how they progress',
      },
      SUB_HEADING: 'Cross-platform collection of content that represents a topic of interest',

      LIST: [
        {
          title: 'Create Narrative',
          navigationRoute: '/monitor/narratives/narrative-builder',
          disabled: false,
        },
        { title: 'Monitor Narrative', navigationRoute: '/monitor/narratives', disabled: false },
        // { title: 'Monitor 90 days trends', navigationRoute: '/monitor/narratives', disabled: false },
        // { title: 'Analyze long term trends', navigationRoute: '/analyze', disabled: false },
      ],
    },

    WATCHLISTS: {
      HEADING: 'Watchlists',
      NEW_SUB_HEADING: {
        1: 'Monitor channels and creators of interest on any platform to ensure nothing is missed',
        2: '',
      },

      LIST: [
        {
          title: 'Create Watchlist',
          navigationRoute: '/monitor/watchlist/watchlist-builder',
          disabled: false,
        },
        { title: 'Monitor Watchlist', navigationRoute: '/monitor/watchlist', disabled: false },
      ],
    },
    TAGS: {
      HEADING: 'Tags',
      NEW_SUB_HEADING: {
        1: 'Use tags to better focus on what is important to you and receive tailored updates that save you time',
        2: '',
      },
      SUB_HEADING: `Flexible way to organize Pendulum's information that fits your workflow`,
      LIST: [
        { title: 'Apply Tags', navigationRoute: '/monitor/tags/add', disabled: false },
        { title: 'Monitor Tags', navigationRoute: '/monitor/tags', disabled: false },
      ],
    },
    COMMUNITIES: {
      HEADING: 'Communities',
      NEW_SUB_HEADING: {
        1: 'Use communities to add dimensionality to your risk assessment or discover emerging trends',
        2: '',
      },
      SUB_HEADING: `Groups of social media creators who produce thematically similar content as identified by Pendulum's AI`,
      LIST: [
        {
          title: 'Monitor Community',
          navigationRoute: '/monitor/communities',
          disabled: false,
        },
        {
          title: 'Request Community',
          navigationRoute: '',
          disabled: false,
        },
      ],
    },

    EXECUTIVE_INTELLIGENCE: {
      HEADING: 'Executive Intelligence',
      NEW_SUB_HEADING: {
        1: 'Unlock executive insights and discover language risk on profiles.',
        2: '',
      },
      LIST: [
        {
          title: 'Monitor Executives Trends',
          navigationRoute: '/monitor/executive-intelligence',
          disabled: false,
        },
        {
          title: 'Request Executive',
          navigationRoute: '',
          disabled: true,
        },
      ],
    },

    INVESTIGATION: {
      HEADING: 'Investigations',
      SUB_HEADING: 'Multi-media whiteboards to help you organize your data research in structured way',
      NEW_SUB_HEADING: {
        1: 'Organise your data for reporting and dissemination',
        2: '',
      },
      LIST: [
        { title: 'Manage Bookmarks', navigationRoute: '/investigate/bookmarks', disabled: false },
        { title: 'Create Report', navigationRoute: '/monitor/reports', disabled: true },
      ],
    },
    RISKWAND: {
      HEADING: 'RiskWand',
      SUB_HEADING: 'Multi-media whiteboards to help you organize your data research in structured way',
      NEW_SUB_HEADING: {
        1: 'RiskWand automates threat tracking and propels horizon scanning capabilities.',
        2: '',
      },
      LIST: [
        { title: 'RiskWand Chord', navigationRoute: '/anticipatory-intelligence/scoreboard', disabled: false },
        { title: 'RiskWand Scorecard', navigationRoute: '/anticipatory-intelligence/scoreboard', disabled: false },
      ],
    },

    CONTROL_SYSTEM: {
      HEADING: 'Control System',
      NEW_SUB_HEADING: {
        1: 'Control how you want to use Pendulum.',
        2: '',
      },
      LIST: [
        { title: 'Manage Alerts', navigationRoute: '/manage/alerts', disabled: true },
        { title: 'Watchlist', navigationRoute: '', disabled: true },
      ],
    },

    RESOURCES: {
      HEADING: 'Resources',
      NEW_SUB_HEADING: {
        1: 'Learn more how pendulum works.',
        2: '',
      },
      LIST: [
        { title: 'Schedule call with Pendulum Team', navigationRoute: '', disabled: false },
        { title: 'FAQs & Tutorial Videos', navigationRoute: '', disabled: true },
      ],
    },
    ALERTS: {
      HEADING: 'Alerts',
      SUB_HEADING: 'Special purpose notifications that send you the exact social media posts you consider most risky',
      LIST: [{ title: 'Manage Alerts', navigationRoute: '/manage/narratives', disabled: true }],
    },
    SCHEDULE_WELCOME_CALL: {
      HEADING: 'Schedule Welcome Call',
      SUB_HEADING: 'Please email us to schedule a virtual walkthrough and give us the opportunity to show you around',
      LIST: [{ title: 'Schedule Call', navigationRoute: '', disabled: false }],
    },
  },
}

export const SUPPORTED_LANGUAGES: { [x: string]: string } = {
  Afrikaans: 'af',
  Albanian: 'sq',
  Amharic: 'am',
  Arabic: 'ar',
  Armenian: 'hy',
  Azerbaijani: 'az',
  Bengali: 'bn',
  Bosnian: 'bs',
  Bulgarian: 'bg',
  Catalan: 'ca',
  'Chinese(Simplified)': 'zh',
  'Chinese(Traditional)': 'zh-TW',
  Croatian: 'hr',
  Czech: 'cs',
  Danish: 'da',
  Dari: 'fa-AF',
  Dutch: 'nl',
  English: 'en',
  Estonian: 'et',
  'Farsi(Persian)': 'fa',
  Tagalog: 'tl',
  Finnish: 'fi',
  French: 'fr',
  'French(Canada)': 'fr-CA',
  Georgian: 'ka',
  German: 'de',
  Greek: 'el',
  Gujarati: 'gu',
  'Haitian Creole': 'ht',
  Hausa: 'ha',
  Hebrew: 'he',
  Hindi: 'hi',
  Hungarian: 'hu',
  Icelandic: 'is',
  Indonesian: 'id',
  Irish: 'ga',
  Italian: 'it',
  Japanese: 'ja',
  Kannada: 'kn',
  Kazakh: 'kk',
  Korean: 'ko',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Macedonian: 'mk',
  Malay: 'ms',
  Malayalam: 'ml',
  Maltese: 'mt',
  Marathi: 'mr',
  Mongolian: 'mn',
  'Norwegian(Bokmål)': 'no',
  Pashto: 'ps',
  Polish: 'pl',
  'Portuguese(Brazil)': 'pt',
  Portuguese: 'pt-PT',
  Punjabi: 'pa',
  Romanian: 'ro',
  Russian: 'ru',
  Serbian: 'sr',
  Sinhala: 'si',
  Slovak: 'sk',
  Slovenian: 'sl',
  Somali: 'so',
  Spanish: 'es',
  'Spanish(Mexico)': 'es-MX',
  Swahili: 'sw',
  Swedish: 'sv',
  Tamil: 'ta',
  Telugu: 'te',
  Thai: 'th',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Urdu: 'ur',
  Uzbek: 'uz',
  Vietnamese: 'vi',
  Welsh: 'cy',
}

export const SUPPORTED_LANGUAGES_CODE: { [x: string]: string } = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  az: 'Azerbaijani',
  bn: 'Bengali',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese(Simplified)',
  'zh-TW': 'Chinese(Traditional)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  'fa-AF': 'Dari',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  fa: 'Farsi(Persian)',
  tl: 'Tagalog',
  fi: 'Finnish',
  fr: 'French',
  'fr-CA': 'French(Canada)',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'Haitian Creole',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mr: 'Marathi',
  mn: 'Mongolian',
  no: 'Norwegian(Bokmål)',
  ps: 'Pashto',
  pl: 'Polish',
  pt: 'Portuguese(Brazil)',
  'pt-PT': 'Portuguese',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  'es-MX': 'Spanish(Mexico)',
  sw: 'Swahili',
  sv: 'Swedish',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  cy: 'Welsh',
}
export const EXECUTIVE_CATEGORIES = ['Negative', 'Obscene', 'Identity hate', 'Toxic (i)', 'Threat (i)', 'Source', 'All']

export const EXECUTIVE_NARRATIVE_CATEGORIES = [
  'NarrativeAll',
  'NarrativeMainstream',
  'NarrativeRisk',
  'NarrativeNegative',
]

export const RISKWAND_SAMPLE_CONTENT = [
  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Just to give you an idea the 2018 drought was so extreme that, looking back at least the last 500 years, there were no other events similar to the drought of 2018, but this year I think it is really worse than 2018.',

  "Europe's rivers run dry as scientists warn drought could be worst in 500 years | Crops, power plants, barge traffic, industry and fish populations devastated by parched waterways",

  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Today, 40 CEOs of European metal companies warned of the “existential threat” to the industry due to energy shortages and the "extra raw materials needed to shift away from fossil fuels...',

  "If they turn electricity off for four days everyone's freezers will defrost and then we will all sue for loss of food.🤔🤔🤔",

  'Europe faces its worst drought in hundreds of years with dried-up rivers, dead fish and shrivelled crops.',

  "The summer of 2022 was the hottest in Europe's recorded history, with the continent facing record-breaking heatwaves and the worst drought in centuries, the European Commission's satellite monitor said this morning.",

  '"US shale executives sitting on vast oil and natural gas reserves that could be used to alleviate a European energy crunch say they will be unable to step up supplies quickly enough to prevent winter shortages."',

  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Just to give you an idea the 2018 drought was so extreme that, looking back at least the last 500 years, there were no other events similar to the drought of 2018, but this year I think it is really worse than 2018.',

  'Russia is to shut off gas supplies to Ukraine, raising fears of European energy shortages',

  '"We haven\'t analysed fully the event (this year\'s drought), because it is still ongoing, but based on my experience I think that this is perhaps even more extreme than 2018," ',

  'Russia is to shut off gas supplies to Ukraine, raising fears of European energy shortages',
  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Just to give you an idea the 2018 drought was so extreme that, looking back at least the last 500 years, there were no other events similar to the drought of 2018, but this year I think it is really worse than 2018.',

  "Europe's rivers run dry as scientists warn drought could be worst in 500 years | Crops, power plants, barge traffic, industry and fish populations devastated by parched waterways",

  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Today, 40 CEOs of European metal companies warned of the “existential threat” to the industry due to energy shortages and the "extra raw materials needed to shift away from fossil fuels...',

  "If they turn electricity off for four days everyone's freezers will defrost and then we will all sue for loss of food.🤔🤔🤔",

  'Europe faces its worst drought in hundreds of years with dried-up rivers, dead fish and shrivelled crops.',

  "The summer of 2022 was the hottest in Europe's recorded history, with the continent facing record-breaking heatwaves and the worst drought in centuries, the European Commission's satellite monitor said this morning.",

  '"US shale executives sitting on vast oil and natural gas reserves that could be used to alleviate a European energy crunch say they will be unable to step up supplies quickly enough to prevent winter shortages."',

  "Donald Trump will be back in office in August. Trump's legal team have found evidence of Dominion vote errors that he will take to the supreme court to get him re-instated. It's just a matter of time before the military moves to put him in his rightful place.",

  'Just to give you an idea the 2018 drought was so extreme that, looking back at least the last 500 years, there were no other events similar to the drought of 2018, but this year I think it is really worse than 2018.',

  'Russia is to shut off gas supplies to Ukraine, raising fears of European energy shortages',

  '"We haven\'t analyzed fully the event (this year\'s drought), because it is still ongoing, but based on my experience I think that this is perhaps even more extreme than 2018," ',

  'Russia is to shut off gas supplies to Ukraine, raising fears of European energy shortages',
]

export const STORE_LOOKUP_DICTIONARY = Object.freeze({
  tag: 'Tags',
  narrative: 'Narratives',
  watchlist: 'Watchlist',
  community: 'Communities',
  bookmark: 'Bookmarks',
})

export const DEFINE_STORES_LOOKUP_DICTIONARY = Object.freeze({
  narrative: 'Narrative',
  watchlist: 'Watchlist',
})

export const ATTENTION_FLAG_INSIGHT_TYPES = {
  SEARCH_TERM: 'keywords_expression',
  COMMUNITIES: 'communities',
  NARRATIVES: 'narratives',
}

export const CLOSE_RANGE_INFO = [
  {
    label: 'Brand',
    icon: <BrandsIcon />,
    name: 'Brand',
    elementTitle: 'Brand',
  },
  {
    label: 'Executive',
    icon: <ExecutivesIcon />,
    name: 'Executive',
    elementTitle: 'Executive',
  },

  {
    label: 'Board',
    icon: <BoardIcon />,
    name: 'Board',
    elementTitle: 'Board',
  },
  {
    label: 'Industries',
    icon: <IndustriesIcon />,
    name: 'Industry',
    elementTitle: 'Industries',
  },
  {
    label: 'Suppliers',
    icon: <SuppliersIcon />,
    name: 'Supplier',
    elementTitle: 'Suppliers',
  },
]

export const MID_RANGE_INFO = [
  {
    label: 'Social Unrest',
    icon: <SocialUnrestIcon />,
    name: 'social_unrest',
    elementTitle: 'Social Unrest',
  },
  {
    label: 'Kinetic Action',
    icon: <KineticEnergyIcon />,
    name: 'kinetic_action',
    elementTitle: 'Kinetic Action',
  },
  {
    label: 'Legal Action',
    icon: <LegalsIcon />,
    name: 'legal_action',
    elementTitle: 'Legal Action',
  },
  {
    label: 'Legislation Action',
    icon: <LegislationIcon />,
    name: 'Legislation Action',
    elementTitle: 'Legislation Action',
  },
  {
    label: 'Taxation',
    icon: <TaxIcon />,
    name: 'taxation',
    elementTitle: 'Taxation',
  },
  {
    label: 'Boycott',
    icon: <BoycottIcon />,
    name: 'boycott',
    elementTitle: 'Boycott',
  },
]

type APIDocs = {
  FILES: {
    [key: string]: string
  }
}

export const API_DOCS: APIDocs = {
  FILES: {
    'Data Backend API': 'data_backend_api_swagger.yaml',
    'Docs API': 'api_docs_be_swagger.yaml',
    'Search API': 'pendulum_search_apis_swagger.yaml',
    'WebApp Manage API': 'webapp_manage_swagger.yaml',
  },
}

export const PLATFORM_ICONS_GREY = [
  { title: 'YouTube', icon: <YoutubeIcon /> },
  { title: 'BitChute', icon: <BitChuteIcon /> },
  { title: 'Rumble', icon: <RumbleIcon /> },
  { title: 'Podcast', icon: <PodcastIcon /> },
  { title: 'TikTok', icon: <TikTokIcon /> },
  { title: 'News', icon: <NewsIcon /> },
  { title: 'Twitter', icon: <TwitterIcon /> },
  { title: 'Reddit', icon: <RedditIcon /> },
  { title: '4chan', icon: <img src={ChanIcon} alt={`pendulum filter images`} /> },
  { title: 'Gab', icon: <GabIcon /> },
  { title: 'TruthSocial', icon: <img src={TruthSocialIcon} alt={`pendulum filter images`} /> },
  { title: 'Patriots.win', icon: <img src={PatriotsIcon} alt={`pendulum filter images`} /> },
  { title: 'vk', icon: <VkIcon className='vk-icon' /> },
  { title: 'Instagram', icon: <InstagramIcon /> },
  { title: 'Threads', icon: <ThreadsIcon className='threads-icon' /> },
  { title: 'Telegram', icon: <TelegramIcon className='telegram-icon' /> },
  { title: 'C-SPAN', icon: <CSpanIcon />, exclude: ['watchlist'] },
  { title: 'Brighteon', icon: <img src={BrighteonIcon} alt={`pendulum filter images`} /> },
  { title: 'Bluesky', icon: <BlueSky /> },
  { title: 'LinkedIn', icon: <Linkedin /> },
  // { title: 'WebSearch', icon: <WebSearchIcon />, exclude: ['watchlist'] },
  // { title: 'Consilium', icon: <ConsiliumIcon />, exclude: ['watchlist'] },
  // { title: 'Snapchat', icon: <SnapchatIcon className='snapchat-icon' /> },
  // { title: 'Odysee', icon: <OdyseeIcon /> },
]

// eslint-disable-next-line no-unused-vars
export const PLATFORM_ICONS_URLS: Partial<{ [K in Lowercase<sourcePlatform>]: string }> = {
  youtube: YoutubeIconURL,
  bitchute: BitchuteIconURL,
  rumble: RumbleIconURL,
  podcast: PodcastIconURL,
  tiktok: TikTokIconURL,
  news: NewsIconURL,
  twitter: TwitterIconURL,
  reddit: RedditIconURL,
  '4chan': ChanIconURL,
  gab: GabIconURL,
  truthsocial: TruthSocialIconURL,
  'patriots.win': PatriotsIconURL,
  vk: VkIconURL,
  instagram: InstagramIconURL,
  threads: ThreadsIconURL,
  telegram: TelegramIconURL,
  'c-span': CSpanIconURL,
  brighteon: BrighteonIconURL,
}
