import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import TextArea from 'version2/components/common/Inputs/TextArea/TextArea'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as InfoIcon } from 'assets/images/info-tooltip-icon.svg'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/sparkle.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/icons/flag/loader_icon.svg'
import './SearchTermSection.scss'
import { CopilotResponseType } from 'store/flags/types'

type SearchTermSectionProps = {
  booleanSearch: string
  setBooleanSearch: (val: string) => void
  setStep: (val: number) => void
  onClose: () => void
  setOpenContactModal: (state: boolean) => void
}

const SearchTermSection = observer((props: SearchTermSectionProps) => {
  const booleanSearchRef = useRef<any>()
  const copilotTooltipRef = useRef<any>()
  const { flagsStore } = store
  const { fetchBooleanSearchText, booleanSearchLoading } = flagsStore
  const { booleanSearch, setBooleanSearch, setStep, onClose, setOpenContactModal } = props
  const [showCopilotSearch, setShowCopilotSearch] = useState<boolean>(false)
  const [copilotSearch, setCopilotSearch] = useState<string>('')
  const [isBooleanTooltipVisible, setIsBooleanTooltipVisible] = useState(false)
  const [isCopilotTooltipVisible, setIsCopilotTooltipVisible] = useState(false)

  useEffect(() => {
    function handleClickOutsideTooltip(event: any) {
      if (booleanSearchRef.current && !booleanSearchRef.current.contains(event?.target)) {
        setIsBooleanTooltipVisible(false)
      }

      if (copilotTooltipRef.current && !copilotTooltipRef.current.contains(event?.target)) {
        setIsCopilotTooltipVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideTooltip)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTooltip)
    }
  })

  const copilotSearchHandler = () => {
    setBooleanSearch('')
    const requestBody = {
      prompt_text: copilotSearch,
    }
    fetchBooleanSearchText({ data: requestBody }).then((res: CopilotResponseType | 'error') => {
      if (res !== 'error') {
        setBooleanSearch(res.prompt_response)
      }
    })
  }

  const showFeedbackModal = () => {
    setIsBooleanTooltipVisible(false)
    setIsCopilotTooltipVisible(false)
    setOpenContactModal(true)
    onClose()
  }

  return (
    <div className='search_term_section_container'>
      <div className='stsc_copilot_text_container'>
        <div
          className='stsc_ctc_inner_container'
          onClick={() => {
            setCopilotSearch('')
            setShowCopilotSearch((prevState) => !prevState)
          }}>
          <SparkleIcon className='stsc_ctc_ic_spark_icon' />
          <p className='stsc_ctc_ic_text'>{showCopilotSearch && 'Close'} Pendulum Co-Pilot</p>
          <InfoIcon className='stsc_ctc_ic_info_icon' />
        </div>
      </div>
      {showCopilotSearch ? (
        <>
          <div className='stsc_text_area_container'>
            <div className='stsc_tac_heading'>
              <p className='stsc_tac_heading_text'>Pendulum Co-Pilot Search</p>
              <Tooltip
                placement='right'
                overlayClassName='pendulum-themed-tooltip ant-tooltip-zindex'
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ maxHeight: '350px', width: '250px', overflow: 'auto' }}
                open={isCopilotTooltipVisible}
                trigger={['click']}
                title={
                  <div ref={copilotTooltipRef} className='create-narrative__copilot-tooltip'>
                    <p>
                      Describe the topic you are looking for like
                      <br />
                      you would when talking to a very junior
                      <br />
                      analyst who is helping you. Feel free to be
                      <br />
                      as descriptive as you like. You can also call
                      <br />
                      out things that you may not want to include
                      <br />
                      in the search results. Some examples
                      <br />
                      below:
                    </p>

                    <ul>
                      <li>
                        Discussions relating to China and
                        <br />
                        Taiwan relationships and any impacts
                        <br />
                        that can have on geopolitical and
                        <br />
                        economic stability
                      </li>
                      <li>
                        Conversations about various Qanon
                        <br />
                        topics and conspiracy theories
                      </li>
                      <li>
                        Conversations discussing various forms
                        <br />
                        of human rights violations related with
                        <br />
                        the Kingdom of Saudi Arabia
                      </li>
                    </ul>
                  </div>
                }>
                <InfoIcon
                  className='stsc_tac_ht_info_icon'
                  height={12}
                  width={12}
                  onClick={() => {
                    setIsCopilotTooltipVisible(true)
                  }}
                />
              </Tooltip>
            </div>
            <TextArea
              className='stsc_tac_ht_textarea'
              placeholder='Describe the topic you are looking for as you would to a junior analyst. E.g. Conversations around China and Taiwan political tensions'
              value={copilotSearch}
              onChange={(e) => setCopilotSearch(e.target.value)}
            />
          </div>
          <ButtonImage
            icon={LoaderIcon}
            className='stsc_button'
            loading={booleanSearchLoading}
            onClick={copilotSearchHandler}
            disabled={!copilotSearch || booleanSearchLoading}>
            Convert to boolean
          </ButtonImage>
        </>
      ) : null}
      <div className='stsc_text_area_container'>
        <div className='stsc_tac_heading'>
          <p className='stsc_tac_heading_text'>Boolean Keyword Search</p>
          <Tooltip
            placement='right'
            overlayClassName='pendulum-themed-tooltip ant-tooltip-zindex'
            overlayStyle={{ width: '370px' }}
            overlayInnerStyle={{ height: 'auto', width: '370px', overflow: 'auto', maxHeight: '600px' }}
            open={isBooleanTooltipVisible}
            trigger={['click']}
            destroyTooltipOnHide
            title={
              <>
                <ul ref={booleanSearchRef} className='create-narrative__boolean-search-tooltip'>
                  <li>
                    <p>
                      Pendulum uses boolean operators to allow you to define what you are looking for. If you have not
                      used boolean operators before, you can watch this short
                      <a href='https://www.youtube.com/watch?v=bCAULDuMcso' target='_blank' rel='noreferrer'>
                        {' '}
                        video
                      </a>
                      . Other useful resources are
                      <a href='https://www.youtube.com/watch?v=Ed7EswsnEbM' target='_blank' rel='noreferrer'>
                        {' '}
                        boolean modifiers
                      </a>{' '}
                      and{' '}
                      <a href='https://www.youtube.com/watch?v=BoujP8KoK7E' target='_blank' rel='noreferrer'>
                        keyword selection
                      </a>
                      .
                    </p>
                    <p>
                      Boolean searches are very useful for a quick access to the data but can be very challenging if the
                      topic you are evaluating is very complex and nuanced. If you need any assistance{' '}
                      <button className='here-button' onClick={showFeedbackModal}>
                        click here
                      </button>{' '}
                      and tell us how we can help.
                    </p>
                    <p>
                      The data in this screen is limited to the last 90 days and is intended to give you examples of
                      what your search results will look like. You can also edit right away as you see things to remove
                      or add. Once you click Publish, your narrative will run against all of Pendulum's data and will
                      return all matching results.
                    </p>
                    <p>
                      Pendulum uses the following operators and it is important that they are all UPPERCASE when
                      included.
                    </p>
                    <span>AND: search for the presence of all terms in your search.</span>
                    <br />
                    <span>OR: search for the presence of either term.</span>
                    <br />
                    <span>NOT: remove items that contain a specific term.</span>
                    <br />
                    <span>
                      NEAR/N: search for terms that are within N words of each other. Value of N can be between 1 and 6.
                    </span>
                  </li>
                  <br />
                  <span>Some search examples:</span>
                  <ul className='create-narrative__boolean-search-example'>
                    <li>
                      <span> Hans Niemann and Magnus Carlsen recent scandal:</span>
                    </li>
                    <ul>
                      <li>
                        <p>
                          ((("Hans Demon" OR "Hans Niemann" OR ((Hans OR Niemann OR Neiman OR Nieman) AND Chess)) OR
                          ("Magnus Carlsen" OR ((Magnus OR Carlsen OR Carlson OR Carlton) AND Chess))) AND (Cheat* OR
                          Scandal OR Withdr* OR Drama OR Device OR Vibr*))
                        </p>
                      </li>
                    </ul>
                    <li>
                      <span>Narrowing down to the Elite 8 men's basketball game in 2022 march madness tournament:</span>
                    </li>
                    <ul>
                      <li>
                        <p>(Arkansas OR Razorbacks) AND (Duke OR “Blue Devils”) AND “march madness” NOT “1994 final”</p>
                      </li>
                    </ul>
                  </ul>
                </ul>
              </>
            }>
            <InfoIcon
              className='stsc_tac_ht_info_icon'
              height={12}
              width={12}
              onClick={() => {
                setIsBooleanTooltipVisible(true)
              }}
            />
          </Tooltip>
        </div>
        <TextArea
          className='stsc_tac_ht_textarea'
          placeholder='("China" OR "Chinese" OR "PRC") AND ("Taiwan" OR "Taiwanese" OR "ROC") AND ("political tension*" OR "sovereignty dispute*")'
          value={booleanSearch}
          onChange={(e) => setBooleanSearch(e.target.value)}
        />
      </div>
      {booleanSearch ? (
        <ButtonImage
          className='stsc_test_button'
          loading={booleanSearchLoading}
          onClick={(e) => {
            e.stopPropagation()
            setStep(4)
          }}>
          Test the rule
        </ButtonImage>
      ) : null}
    </div>
  )
})

export default SearchTermSection
