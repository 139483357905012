import ReactMarkdown from 'react-markdown'
import React, { useState, useRef, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import ActionButtons from '../ActionButtons/ActionButtons'
import { contentObj } from '../DetailViewRightSection/DetailViewRightSection'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import DetailViewEmptyData from '../DetailViewEmptyData/DetailViewEmptyData'
import DetailViewCollectionAdded from '../DetailViewCollectionAdded/DetailViewCollectionAdded'
import styles from './DetailViewSectionDetail.module.scss'
import ReportModal from 'version2/components/common/Modal/ReportModal'
import SuccessBodyModal from 'version2/components/common/Modal/SuccessBodyModal/SuccessBodyModal'
import { ReactComponent as EdittIcon } from 'assets/images/icons/editreport.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/deletesection.svg'
import { ReactComponent as SearchingBar } from 'assets/images/icons/report/searching_bars.svg'
import successGif from 'assets/gifs/reportLoader.gif'
import deleteGif from 'assets/gifs/sectiondeleted.gif'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { collectionObj, EntitiesObj, sectionObj, ReportFieldParams, ReportFeedsData } from 'store/report/valueTypes'
import { store } from 'store'
import dayjs from 'dayjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import usePermissions from 'version2/hooks/usePermissions'
import { containsHTML, extractBodyContent } from 'version2/utils/helper'

type DetailViewSectionDetailProps = {
  currentIndex?: number
  id?: string
  title?: string
  summary?: string
  summary_prompt?: string
  entities?: EntitiesObj[]
  status?: string
  content_arr?: contentObj[]
  sectionData?: sectionObj
  editable?: boolean
  editableId?: string
  setEditableId?: ((val: string) => void) | undefined
  selectedTextChunkId?: string
  setSelectedTextChunkId?: ((val: string) => void) | undefined
  textChunkValue?: string
  setTextChunkValue?: ((val: string) => void) | undefined
  startDate?: string
  endDate?: string
  feeds_payload?: ReportFeedsData
  reportDetailsPanel?: boolean
}

const DATE_FORMAT = 'YYYY-MM-DD'
const entitiesTypes = { watchlists: 'WATCHLIST', narratives: 'NARRATIVE', bookmarks: 'BOOKMARK', assets: 'ASSET' }

const DetailViewSectionDetail = observer((props: DetailViewSectionDetailProps) => {
  const navigate = useNavigate()
  const {
    currentIndex,
    id,
    title,
    summary,
    summary_prompt,
    entities,
    status,
    // content_arr,
    sectionData,
    editable,
    editableId,
    setEditableId,
    selectedTextChunkId,
    setSelectedTextChunkId,
    textChunkValue,
    setTextChunkValue,
    startDate,
    endDate,
    feeds_payload,
  } = props
  let textRef = useRef<any>(null)
  const { reportMainViewStore, monitorStore1 } = store
  const {
    fetchReportFeedsHandler,
    updateReportHandler,
    getOrDeleteReportHandler,
    isLoading,
    isReportFeedsLoading,
    fetchAllTrendsList,
    setFeedsPayload,
    setFeedsFilters,
    setCurrPage,
    setPrevPage,
    setSearchInputFeeds,
    setFeedsSearch,
  } = reportMainViewStore
  const { defaultPlatforms } = monitorStore1

  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get('instance_id')
  const [isModalVisible, setIsModalVisible] = useState(false) //the edit modal
  const [isSuccessModalVisible, setIsSuccesModalVisible] = useState(false) //the success gif that we get after editing the report
  const [isDeleteSectionModalVisible, setIsDeleteSectionModalVisible] = useState(false) //the delete modal
  const [isDeleteSuccessVisible, setIsDeleteSuccessVisible] = useState(false) //the success gif that we get after deleting
  const [uniqueKey, setUniqueKey] = useState<string>(UtilService.generateUuid())

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target?.innerHTML
    setTextChunkValue?.(val)
  }

  const updateItemHandler = (id: string, value: string) => {
    setEditableId?.(id)
    setTextChunkValue?.(value)
    textRef.current = document.getElementById(id)
    setTimeout(() => {
      textRef.current.focus()

      const textNode = textRef.current.lastChild

      const range = document.createRange()
      range.selectNodeContents(textNode)
      range.collapse(false)

      const sel = window.getSelection()
      sel?.removeAllRanges()
      sel?.addRange(range)
    }, 0)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const closeCreateAndOpenSuccessModal = (data: any) => {
    updateReportHandler(reportId!, data).then((res) => {
      if (res === 'error') {
        return
      }
      if ('id' in res && editable) {
        navigate(`/reports/details/${res.id}?instance_id=${res.instance_id}`, { replace: true })
      } else if ('id' in res) {
        navigate(`/reports/${res.id}?instance_id=${res.instance_id}`, { replace: true })
      }
      setIsSuccesModalVisible(true)
      setIsModalVisible(false)
      setTimeout(closeSuccesModal, 5000)
    })
  }

  const closeSuccesModal = () => {
    setIsSuccesModalVisible(false)
  }
  const closeDeleteSectionModal = () => {
    setIsDeleteSectionModalVisible(false)
  }
  const closeDeleteSuccess = () => {
    setIsDeleteSuccessVisible(false)
  }
  const closeDeleteAndOpenSuccessModal = () => {
    setIsDeleteSuccessVisible(true)
    setIsDeleteSectionModalVisible(false)
  }

  const collections: collectionObj = useMemo(() => {
    let makeData = {
      summary: summary_prompt!,
      watchlists: [] as EntitiesObj[],
      narratives: [] as EntitiesObj[],
      bookmarks: [] as EntitiesObj[],
      assets: [] as EntitiesObj[],
    }
    if (entities?.length) {
      for (const entity of entities) {
        if (entity.type === entitiesTypes.watchlists) {
          makeData.watchlists.push(entity)
        } else if (entity.type === entitiesTypes.narratives) {
          makeData.narratives.push(entity)
        } else if (entity.type === entitiesTypes.bookmarks) {
          makeData.bookmarks.push(entity)
        } else if (entity.type === entitiesTypes.assets) {
          makeData.assets.push(entity)
        }
      }
    }
    return makeData
  }, [id])

  //......................fetch report fields api...................

  const fetchReportFeedsMethod = () => {
    setFeedsPayload(feeds_payload!)
    let qParam = `start_date:gte:${startDate ? dayjs(startDate).format(DATE_FORMAT) : ''},end_date:lte:${
      endDate ? dayjs(endDate).format(DATE_FORMAT) : ''
    },risk_score:gte:0,risk_score:lte:100`

    if (defaultPlatforms.length) {
      qParam = `${qParam},platform:in:${defaultPlatforms}`
    }

    const params: ReportFieldParams = {
      q: qParam,
      per_page: 50,
      page: 1,
      sort: 'upload_date:desc',
    }

    return fetchReportFeedsHandler(params, feeds_payload!)
  }

  const fetchTrendsData = () => {
    fetchAllTrendsList(
      dayjs(startDate).format(DATE_FORMAT),
      dayjs(endDate).format(DATE_FORMAT),
      feeds_payload!,
      { min: 0, max: 100 },
      defaultPlatforms,
    )
  }

  const summaryTextKey = `${id}_${currentIndex}`
  useEffect(() => {
    if (currentIndex === 0) {
      setSelectedTextChunkId?.(summaryTextKey)
      fetchReportFeedsMethod()
      fetchTrendsData()
    }
  }, [currentIndex])

  const updateSummaryTextHandler = () => {
    if (!isLoading) {
      const requestBody = {
        action: 'EDIT_SUMMARY',
        section_id: id,
        summary_text: textChunkValue,
      }
      getOrDeleteReportHandler('post', reportId!, instanceId!, requestBody).then((res) => {
        if (res !== 'error') {
          setEditableId?.('')
          getOrDeleteReportHandler('get', reportId!, instanceId!)
        }
      })
    }
  }

  return (
    <div id={id} className={styles.detail_view_section_detail_container}>
      <div className={styles.dvsdc_header}>
        <p className={styles.dvsdc_header_text}>{title}</p>
        {!canReviewAndReceive && (
          <ActionButtons
            hideShareIcon={true}
            hideExtraIcons={true}
            className={styles.dvsdc_buttons}
            deleteHandler={() => {
              setIsDeleteSectionModalVisible(true)
            }}
            editHandler={() => setIsModalVisible(true)}
          />
        )}
      </div>
      <div
        className={classNames(styles.dvsdc_section_desc_container, {
          [styles.dvsdc_section_desc_container_editable]: editable,
        })}>
        {status === 'QUEUED' ? (
          <DetailViewEmptyData
            icon={SearchingBar}
            title='Summary generation in progress'
            desc='Please wait for a moment; loading usually takes 60 seconds.'
          />
        ) : (
          <>
            {summary ? (
              <div
                key={summaryTextKey}
                className={classNames(styles.sdc_chunk, {
                  [styles.sdc_chunk_selected]: selectedTextChunkId === summaryTextKey,
                  [styles.sdc_chunk_active_edit]: editableId === summaryTextKey,
                })}
                onClick={(e) => {
                  e.stopPropagation()
                  if (editable && !editableId && !isReportFeedsLoading && selectedTextChunkId !== summaryTextKey) {
                    setSelectedTextChunkId?.(summaryTextKey)
                    fetchReportFeedsMethod()
                    if (defaultPlatforms.length) {
                      setFeedsFilters({ risk_score: [], platforms: defaultPlatforms })
                    } else {
                      setFeedsFilters({ risk_score: [], platforms: defaultPlatforms })
                    }
                    fetchTrendsData()
                    setCurrPage(1)
                    setPrevPage(0)
                    setSearchInputFeeds('')
                    setFeedsSearch('')
                  }
                }}>
                <p
                  key={uniqueKey}
                  id={summaryTextKey}
                  className={classNames(styles.sdc_chunk_text, `pdf-summary-${id}`)}
                  contentEditable={editableId === summaryTextKey}
                  suppressContentEditableWarning
                  onInput={handleChange}>
                  {containsHTML(summary) ? (
                    <span dangerouslySetInnerHTML={{ __html: extractBodyContent(summary || '') }} />
                  ) : (
                    <ReactMarkdown>{summary || ''}</ReactMarkdown>
                  )}
                </p>
                {editable && (
                  <>
                    <div
                      className={styles.sdc_chunk_edit_icon_container}
                      onClick={(event) => {
                        event.stopPropagation()
                        updateItemHandler(summaryTextKey, summary)
                      }}>
                      <EditIcon />
                    </div>
                    <div className={styles.sdc_chunk_buttons_container}>
                      <div>
                        <div className={styles.sdc_chunk_buttons_inner_container}>
                          <ButtonImage
                            className={styles.sdc_c_bc_cancel_button}
                            onClick={(event) => {
                              event.stopPropagation()
                              // textRef.current.textContent = summary
                              setUniqueKey(UtilService.generateUuid())
                              setEditableId?.('')
                            }}>
                            Cancel
                          </ButtonImage>
                          <ButtonImage
                            disabled={textChunkValue === summary}
                            className={styles.sdc_c_bc_save_button}
                            onClick={(event) => {
                              event.stopPropagation()
                              updateSummaryTextHandler()
                            }}>
                            Save
                          </ButtonImage>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <DetailViewEmptyData />
            )}
          </>
        )}
        {/* {content_arr && content_arr?.length ? (
          content_arr.map((content) => (
            <div
              key={content.id}
              className={classNames(styles.sdc_chunk, {
                [styles.sdc_chunk_selected]: selectedTextChunkId === content.id,
                [styles.sdc_chunk_active_edit]: editableId === content.id,
              })}
              onClick={() => {
                if (editable && !editableId) {
                  setSelectedTextChunkId?.(content.id)
                }
              }}>
              <p
                id={content.id}
                className={styles.sdc_chunk_text}
                contentEditable={editableId === content.id}
                suppressContentEditableWarning
                onInput={handleChange}>
                {content.desc}
              </p>
              {editable && (
                <>
                  <div
                    className={styles.sdc_chunk_edit_icon_container}
                    onClick={(event) => {
                      event.stopPropagation()
                      updateItemHandler(content.id, content.desc)
                    }}>
                    <EditIcon />
                  </div>
                  <div className={styles.sdc_chunk_buttons_container}>
                    <div>
                      <div className={styles.sdc_chunk_buttons_inner_container}>
                        <ButtonImage
                          className={styles.sdc_c_bc_cancel_button}
                          onClick={(event) => {
                            event.stopPropagation()
                            setEditableId?.('')
                          }}>
                          Cancel
                        </ButtonImage>
                        <ButtonImage
                          disabled={textChunkValue === content.desc}
                          className={styles.sdc_c_bc_save_button}
                          onClick={(event) => {
                            event.stopPropagation()
                          }}>
                          Save
                        </ButtonImage>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <DetailViewEmptyData />
        )} */}
      </div>
      <DetailViewCollectionAdded collection={collections} editable={editable} />

      {/* ...........the edit section button in detail ................... */}
      {isModalVisible && (
        <ReportModal
          reportId={reportId}
          editMode={true}
          isSection={true}
          sectionData={sectionData}
          isModalVisible
          closeModal={closeModal}
          closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
          icon={<EdittIcon />}
          title='Edit Section'
          description=''
          footerButtonText='Update'
        />
      )}
      {isSuccessModalVisible && (
        <SuccessBodyModal
          visibilityController={isSuccessModalVisible}
          gif={successGif}
          heading='Report is updating ...'
          subheading='it can take up to 2 minutes for updating a report'
        />
      )}

      {isDeleteSectionModalVisible && (
        <ReportModal
          reportId={reportId}
          editMode={true}
          isDelete={true}
          isSection={true}
          sectionData={sectionData}
          isModalVisible
          closeModal={closeDeleteSectionModal}
          closeDeleteAndOpenSuccessModal={closeDeleteAndOpenSuccessModal}
          icon={<DeleteIcon />}
          title='Are you sure to delete the section?'
          description='You can also edit the details of the section.'
          footerButtonText='Delete'
        />
      )}
      {isDeleteSuccessVisible && (
        <SuccessBodyModal
          isDelete={true}
          visibilityController={isDeleteSuccessVisible}
          gif={deleteGif}
          heading='Section Deleted'
          subheading=''
          closeDeleteSuccess={closeDeleteSuccess}
          buttonText='Done'
        />
      )}
    </div>
  )
})

export default DetailViewSectionDetail
