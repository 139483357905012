import CustomModal from 'version2/components/common/CustomModal/CustomModal'
import { observer } from 'mobx-react-lite'
import { ReactComponent as BellIcon } from 'assets/images/alert-icon.svg'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { Radio, Select, Space } from 'antd'
import { useState } from 'react'
import './DailyExecutiveSummary.scss'

type PropsType = {
  setIsOpenSubscribeModal: (status: boolean) => void
}

const subscriptionOptions = [
  { label: <div>Daily Updates</div>, value: 'daily' },
  { label: <div>Weekly Updates</div>, value: 'weekly' },
]

const ExecutiveSummarySubscriptionModal = (props: PropsType) => {
  const { setIsOpenSubscribeModal } = props
  const [subscriptionType, setSubscriptionType] = useState(subscriptionOptions[0].value)

  return (
    <CustomModal
      title='Subscribe for Executive summary'
      firstButtonText='Cancel'
      secondButtonText='Confirm'
      onClose={() => setIsOpenSubscribeModal(false)}
      //   onCancel={() => onViewDetails()}
      //   onConfirm={() => setOpenExecutiveSummaryModal(false)}
      icon={null}
      bodyClassName='executive-summary-body'>
      <div>
        <div className='subscription-status'>
          <div className='bell-icon'>
            <BellIcon />
          </div>
          <div className='status'>
            You are subscribed for <span>weekly</span> updates and receive updates each <span>Monday</span>.
          </div>
          <ButtonImage onClick={() => {}} className='fhc_sc_button'>
            Unsubscribe
          </ButtonImage>
        </div>
        <div className='subscription-options'>
          <Radio.Group onChange={(event) => setSubscriptionType(event?.target.value)} value={subscriptionType}>
            <Space direction='vertical' size={16}>
              <Radio value='daily'>
                <div className='label-radio'>
                  <h4>Daily updates</h4>
                  <p>You’ll receive daily updates for your Executive summary</p>
                </div>
              </Radio>
              <Radio value='weekly'>
                <div className='label-radio'>
                  <h4>Weekly updates</h4>
                  <p>You’ll receive updates for your Executive summary every week</p>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
          {subscriptionType === 'weekly' && (
            <div className='weekly-selection'>
              <h4>Select when you want to receive your updates</h4>
              <Select onChange={() => {}} value={'0'}>
                <Select.Option value='0'>Every Monday</Select.Option>
                <Select.Option value='1'>Every Tuesday</Select.Option>
                <Select.Option value='2'>Every Wednesday</Select.Option>
                <Select.Option value='3'>Every Thursday</Select.Option>
                <Select.Option value='4'>Every Friday</Select.Option>
                <Select.Option value='5'>Every Saturday</Select.Option>
                <Select.Option value='6'>Every Sunday</Select.Option>
              </Select>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  )
}
export default observer(ExecutiveSummarySubscriptionModal)
