import React, { useState, useRef, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import useClickAway from 'hooks/useClickAway'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-platform-icon.svg'
import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-grey-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-platform-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-platform-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-platform-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-grey-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-grey-icon.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-grey-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-grey-icon.svg'
import chanImage from 'assets/images/4-chan-img.png'
import truthSocialImage from 'assets/images/truth-social-img.png'
import patriotsImage from 'assets/images/patriots-img.png'
import birghteon from 'assets/images/brighteon.png'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-icon-current-color.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-icon-current-color.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-icon-current-color.svg'
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg'
import { ReactComponent as PositiveSentimentIcon } from 'assets/images/positive-sentiment-icon.svg'
import { ReactComponent as NegativeIconSentimentIcon } from 'assets/images/negative-sentiments-icon.svg'
import { ReactComponent as NeutralIconSentimentIcon } from 'assets/images/neutral-sentiment-icon.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-grey-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'

import RangeSlider from '../RangeSlider/RangeSlider'
import './FilterDropdown.scss'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReportFeedsFilters, ReportFieldParams } from 'store/report/valueTypes'
import dayjs from 'dayjs'

const PLATFORM_ICONS = [
  { id: '0', title: 'YouTube', icon: YoutubeIcon },
  { id: '1', title: 'BitChute', icon: BitChuteIcon },
  { id: '2', title: 'Rumble', icon: RumbleIcon },
  { id: '3', title: 'Podcast', icon: PodcastIcon },
  { id: '4', title: 'TikTok', icon: TikTokIcon },
  { id: '5', title: 'News', icon: NewsIcon },
  { id: '6', title: 'Twitter', icon: TwitterIcon },
  { id: '7', title: 'Reddit', icon: RedditIcon },
  { id: '8', title: '4chan', imageUrl: chanImage },
  { id: '9', title: 'Gab', icon: GabIcon },
  { id: '10', title: 'TruthSocial', imageUrl: truthSocialImage },
  { id: '11', title: 'Patriots.win', imageUrl: patriotsImage },
  { id: '12', title: 'Instagram', icon: InstagramIcon },
  { id: '13', title: 'Threads', icon: ThreadsIcon },
  { id: '14', title: 'Telegram', icon: TelegramIcon },
  { id: '14', title: 'Birghteon', imageUrl: birghteon },
  { id: '15', title: 'Snapchat', icon: SnapchatIcon },
  { id: '16', title: 'Odysee', icon: OdyseeIcon },
]

const SENTIMENT_ICONS = [
  { id: '0', title: 'Positive', icon: PositiveSentimentIcon },
  { id: '1', title: 'Neutral', icon: NeutralIconSentimentIcon },
  { id: '2', title: 'Negative', icon: NegativeIconSentimentIcon },
]

type FilterDropdownFiltersProps = {
  className?: string
  title: string
  data: { id: string; title: string; icon?: React.ElementType; imageUrl?: string }[]
  selectedValues: string[]
  onSelect: (val: string) => void
}

function FilterDropdownFilters(props: FilterDropdownFiltersProps) {
  const { data, title, className, selectedValues, onSelect } = props
  return (
    <div className={classNames('fdf_container', className)}>
      <p className='fdf_c_heading'>{title}</p>
      <div className='fdf_c_list_container'>
        {data.map((platform) => (
          <ButtonImage
            key={platform.title}
            className={classNames('fdf_cl_filter_chunk', {
              fdf_cl_filter_chunk_telegram: platform.title.toLowerCase() === 'Telegram'.toLowerCase(),
              fdf_cl_filter_chunk_threads: platform.title.toLowerCase() === 'Threads'.toLowerCase(),
              fdf_cl_filter_chunk_selected: selectedValues.includes(platform.title.toLowerCase()),
            })}
            icon={platform.icon}
            imageUrl={platform.imageUrl}
            onClick={() => {
              onSelect(platform.title.toLowerCase())
            }}>
            {platform.title}
          </ButtonImage>
        ))}
      </div>
    </div>
  )
}
const DATE_FORMAT = 'YYYY-MM-DD'
const FilterDropdown = observer(() => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<string[]>([])
  const [selectedSentiment, setSelectedSentiment] = useState<string[]>([])
  const [sliderValues, setSliderValues] = useState<{ min: number; max: number }>({ min: 0, max: 100 })
  const [prevFeedsFilter, setPrevFeedsFiler] = useState<ReportFeedsFilters>({
    risk_score: [],
  })
  const { reportMainViewStore, monitorStore1 } = store
  const { defaultPlatforms } = monitorStore1
  const {
    fetchReportFeedsHandler,
    fetchAllTrendsList,
    isReportFeedsLoading,
    reportDetailsEntities,
    feedsPayload,
    feedsFilters,
    setFeedsFilters,
    setCurrPage,
    setPrevPage,
    feedSearch,
  } = reportMainViewStore

  useEffect(() => {
    setFeedsFilters({ ...feedsFilters, platforms: selectedPlatform.join(',') })
  }, [selectedPlatform])

  useEffect(() => {
    setFeedsFilters({ ...feedsFilters, sentiments: selectedSentiment.join(',') })
  }, [selectedSentiment])

  useEffect(() => {
    setFeedsFilters({ ...feedsFilters, risk_score: [sliderValues.min, sliderValues.max] })
  }, [sliderValues])

  const getFeedsAndLineChart = () => {
    setCurrPage(1)
    setPrevPage(0)
    if (!isReportFeedsLoading) {
      let qParam = `start_date:gte:${dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT)},end_date:lte:${dayjs(
        reportDetailsEntities.end_date,
      ).format(DATE_FORMAT)}`
      if (selectedPlatform.length) {
        qParam = `${qParam},platform:in:${selectedPlatform.join(',')}`
      }
      if (selectedSentiment.length) {
        qParam = `${qParam},sentiment:in:${selectedSentiment.join(',')}`
      }
      qParam = `${qParam},risk_score:gte:${sliderValues.min},risk_score:lte:${sliderValues.max}`

      const params: ReportFieldParams = {
        q: qParam,
        per_page: 50,
        page: 1,
        sort: 'upload_date:desc',
      }

      let requestPayload = JSON.parse(JSON.stringify(feedsPayload))
      if (feedSearch) {
        requestPayload.conditions = [
          ...JSON.parse(JSON.stringify(requestPayload.conditions)),
          {
            keywords_expression: feedSearch,
          },
        ]
      }

      fetchReportFeedsHandler(params, requestPayload!)
      fetchAllTrendsList(
        dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT),
        dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT),
        requestPayload,
        sliderValues,
        selectedPlatform,
        selectedSentiment,
      )
    }
  }

  useEffect(() => {
    if (defaultPlatforms.length) {
      setSelectedPlatform(defaultPlatforms.split(','))
    } else {
      setSelectedPlatform([])
    }
    setSelectedSentiment([])
    setSliderValues({ min: 0, max: 100 })
  }, [feedsPayload, defaultPlatforms])

  const onClickOutside = () => {
    if (isOpen) {
      setIsOpen(false)
      if (hasFilterChanged) {
        getFeedsAndLineChart()
      }
    }
  }

  useClickAway(dropdownRef, onClickOutside)

  const platformAndSentimentHandler = (platform: string, callback: any) => {
    callback((prevState: string[]) =>
      prevState.includes(platform) ? prevState.filter((state) => state !== platform) : [...prevState, platform],
    )
  }

  const addClass = useMemo(() => {
    if (
      ('platforms' in feedsFilters && feedsFilters.platforms) ||
      ('sentiments' in feedsFilters && feedsFilters.sentiments) ||
      feedsFilters.risk_score[0] !== 0 ||
      feedsFilters.risk_score[1] !== 100
    ) {
      return 'fdc_icon_container_filter'
    }
  }, [feedsFilters])

  let hasFilterChanged = false
  useEffect(() => {
    // condition for the first time we set values of filter
    if (
      // the feedsFilters will be empty and prevFeedsFilter will be undefined
      ('platforms' in feedsFilters && feedsFilters.platforms === '' && !('platforms' in prevFeedsFilter)) ||
      ('sentiments' in feedsFilters && feedsFilters.sentiments === '' && !('sentiments' in prevFeedsFilter))
    ) {
      hasFilterChanged = false
    } else {
      // condition after the first time
      hasFilterChanged =
        feedsFilters.platforms !== prevFeedsFilter.platforms ||
        feedsFilters.sentiments !== prevFeedsFilter.sentiments ||
        JSON.stringify(feedsFilters.risk_score) !== JSON.stringify(prevFeedsFilter.risk_score)
    }
  }, [feedsFilters, prevFeedsFilter])
  return (
    <div ref={dropdownRef} className='filter_dropdown_container'>
      <div
        className={classNames('fdc_icon_container', { fdc_icon_container_show_tooltip: !isOpen }, `${addClass}`)}
        onClick={(e) => {
          e.stopPropagation()
          if (isOpen) {
            if (hasFilterChanged) {
              getFeedsAndLineChart()
            }
          } else {
            setPrevFeedsFiler(JSON.parse(JSON.stringify(feedsFilters)))
          }
          setIsOpen((prevState) => !prevState)
        }}>
        <FilterIcon className='fdc_icon' />
      </div>
      {isOpen && (
        <div className='fdc_content_container'>
          <FilterDropdownFilters
            title='Platform'
            data={PLATFORM_ICONS}
            selectedValues={selectedPlatform}
            onSelect={(platform) => platformAndSentimentHandler(platform, setSelectedPlatform)}
          />
          <FilterDropdownFilters
            title='Sentiment'
            data={SENTIMENT_ICONS}
            className='fdc_cc_sentiment_filters'
            selectedValues={selectedSentiment}
            onSelect={(sentiment) => platformAndSentimentHandler(sentiment, setSelectedSentiment)}
          />
          <div className='fdc_cc_range_filter_container'>
            <p className='fdc_cc_rfc_heading'>Risk Score (%)</p>
            <RangeSlider value={sliderValues} onChange={setSliderValues} />
          </div>
        </div>
      )}
    </div>
  )
})

export default FilterDropdown
